import * as profileEvents from "../../store/events/profile";
import Box from "@mui/material/Box";
import Button from "../../components/Button/Button";
import DefaultTemplate from "../templates/DefaultTemplate";
import ErrorMessages from "../../components/ErrorMessages/ErrorMessages";
import FormActionContainer from "../../components/Form/FormActionContainer";
import FormTextField from "../../components/Form/FormTextField";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import React from "react";
import TaskHeader from "../../components/Task/TaskHeader";
import Typography from "@mui/material/Typography";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { useTheme } from "@mui/material";
import withProfileAndAccess from "../../utils/withProfileAndAccess";
import {
	Form,
	isObject,
	isStringOfLength
} from "@pheaa/channels-component-library";
import {
	HTML_ATTR_PATTERN_USER_ID,
	TEXT_DATA_UNAVAILABLE,
	getCommonProfileDataListStyles,
	getErrorMessageForCode
} from "../../config/common";

const getUsernameCriteriaStyles = theme => ({
	background: theme.palette.neutral.light,
	fontSize: "1.2rem !important",
	fontWeight: 400,
	padding: 2,

	" p": {
		marginBottom: 1
	},

	" ul": {
		listStyleType: "disc",
		marginLeft: 3,
		marginTop: 0.25
	}
});

const ManageUserIdView = props => {

	let userId = null;

	const {
		accountProfile,
		accountProfileErrorsUpdate,
		accountProfileIsUpdating,
		updateAccountProfile
	} = props;

	const { personRefId } = useParams();
	const theme = useTheme();

	const handleSubmit = (e, formData) => {
		updateAccountProfile(personRefId, { userId: formData.userId });
	};

	if (isObject(accountProfile) && isStringOfLength(accountProfile.userId)) {
		({ userId } = accountProfile);
	} else {
		userId = TEXT_DATA_UNAVAILABLE;
	}

	return (
		<DefaultTemplate isTask={true}>
			<TaskHeader
				closeTaskDestination={`/profiles/${personRefId}`}
				title="Edit User Id"
			/>
			<Form id="defaultForm" onSubmit={handleSubmit}>
				<Typography align="center" gutterBottom={true}>
					Enter new username in field below.
				</Typography>
				<ErrorMessages
					errorMessages={accountProfileErrorsUpdate.map(error => {
						return { ...error, message: getErrorMessageForCode(error.code) };
					})}
				/>
				<List sx={getCommonProfileDataListStyles(theme)}>
					<ListItem>
						<ListItemText primary="Username:" />
						<ListItemText primary={userId} />
					</ListItem>
				</List>
				<FormTextField
					name="userId"
					pattern={HTML_ATTR_PATTERN_USER_ID}
					required={true}
					title="New Username"
				/>
				<Box sx={getUsernameCriteriaStyles(theme)}>
					<p>Must meet all of the following:</p>
					<ul>
						<li>Between 6 and 12 characters (inclusive).</li>
						<li>May only contain letters (A-Z, a-z), numbers (0-9), and underscores ( _ ).</li>
						<li>Must not be in use by another online account.</li>
						<li>Must not contain a one-to-one (string) match to any of the following:
							<ul>
								<li>Consumer's account number</li>
								<li>Consumer's SSN</li>
							</ul>
						</li>
					</ul>
				</Box>
				<FormActionContainer>
					<Button
						disabled={accountProfileIsUpdating}
						processing={accountProfileIsUpdating}
						type="submit"
						variant="contained"
					>
						Save
					</Button>
				</FormActionContainer>
			</Form>
		</DefaultTemplate >
	);
};

const mapStateToProps = state => {
	return {
		accountProfile: state.profile.accountProfile,
		accountProfileErrorsUpdate: state.profile.accountProfileErrorsUpdate,
		accountProfileIsUpdating: state.profile.accountProfileIsUpdating
	};
};

const mapDispatchToProps = dispatch => {
	return {
		updateAccountProfile: (personRefId, data) => dispatch(profileEvents.updateAccountProfile(personRefId, data))
	};
};

export default withProfileAndAccess(connect(mapStateToProps, mapDispatchToProps)(ManageUserIdView), ["updateUsername"]);