import Box from "@mui/material/Box";
import Button from "../Button/Button";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import React from "react";
import { Typography } from "@mui/material";
import { isFunction, isStringOfLength } from "@pheaa/channels-component-library";

const ErrorLoading = props => {

	let primaryActionElem = null;
	let secondaryActionElem = null;

	const {
		alertTitle = "Error",
		alertDescription = "An unexpected error occurred.",
		primaryActionCallback,
		primaryActionLabel,
		maxWidth = "sm",
		secondaryActionCallback,
		secondaryActionLabel = "Secondary"
	} = props;

	if (isStringOfLength(primaryActionLabel) && isFunction(primaryActionCallback)) {
		primaryActionElem = (
			<Button
				onClick={primaryActionCallback}
				variant="outlined"
				sx={{ marginRight: "1rem" }}
			>
				{primaryActionLabel}
			</Button>
		);
	}

	if (isStringOfLength(secondaryActionLabel) && isFunction(secondaryActionCallback)) {
		secondaryActionElem = (
			<Button
				onClick={secondaryActionCallback}
				variant="outlined"
				sx={{ marginRight: "1rem" }}
			>
				{secondaryActionLabel}
			</Button>
		);
	}

	return (
		<Box component="section" sx={{ display: "flex", margin: "0 auto", maxWidth, padding: "0 2rem" }}>
			<CancelRoundedIcon color="error" sx={{ height: "6rem", width: "6rem" }} />
			<Box sx={{ padding: "0 0 0 1rem" }}>
				<Typography component="h1" variant="h2">{alertTitle}</Typography>
				<Typography>{alertDescription}</Typography>
				<Box mt={1.25} sx={{ display: "flex", flexDirection: "row" }}>
					{primaryActionElem}
					{secondaryActionElem}
				</Box>
			</Box>
		</Box>
	);
};

export default ErrorLoading;