import * as profileEvents from "../../store/events/profile";
import Button from "../../components/Button/Button";
import DefaultTemplate from "../templates/DefaultTemplate";
import ErrorMessages from "../../components/ErrorMessages/ErrorMessages";
import FormActionContainer from "../../components/Form/FormActionContainer";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import React from "react";
import TaskHeader from "../../components/Task/TaskHeader";
import Typography from "@mui/material/Typography";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { useTheme } from "@mui/material";
import withProfileAndAccess from "../../utils/withProfileAndAccess";
import {
	Form,
	isArray,
	isArrayOfLength,
	isObject,
} from "@pheaa/channels-component-library";
import {
	TEXT_DATA_UNAVAILABLE,
	getCommonProfileDataListStyles,
	getErrorMessageForCode
} from "../../config/common";

const RemoveRecoveryEmailView = props => {

	let defaultRemovalValue = null;
	let recoveryEmailListElem = null;

	const {
		accountProfile,
		accountProfileErrorsDeleteRecoveryMethod,
		accountProfileIsDeletingRecoveryMethod,
		deleteRecoveryMethodByContactId
	} = props;

	const { personRefId } = useParams();
	const theme = useTheme();

	const handleSubmit = (e, formData) => {
		deleteRecoveryMethodByContactId(personRefId, formData.contactId);
	};

	if (isObject(accountProfile) && isArray(accountProfile.recovery)) {

		if (isArrayOfLength(accountProfile.recovery)) {
			defaultRemovalValue = accountProfile.recovery[0].id;

			recoveryEmailListElem = accountProfile.recovery.map((recoveryMethod, i) => (
				<ListItem key={i}>
					<ListItemText sx={{ flex: "0 0 auto" }}>Recovery Email:</ListItemText>
					<ListItemText sx={{ flex: "1 1 auto", textAlign: "left !important" }}>{recoveryMethod.email}</ListItemText>
					<FormControlLabel name="contactId" value={recoveryMethod.id} control={<Radio />} label="Remove" />
				</ListItem>
			));
		} else {
			recoveryEmailListElem = (
				<ListItem>
					<ListItemText primary="There are no recovery email addresses." />
				</ListItem>
			);
		}
	} else {
		recoveryEmailListElem = (
			<ListItem>
				<ListItemText primary={`Recovery email addresses ${TEXT_DATA_UNAVAILABLE}.`} />
			</ListItem>
		);
	}

	return (
		<DefaultTemplate isTask={true}>
			<TaskHeader
				closeTaskDestination={`/profiles/${personRefId}`}
				title="Remove a Recovery Email"
			/>
			<Form id="defaultFormWide" onSubmit={handleSubmit}>
				<Typography align="center" gutterBottom={true}>
					Select which email to remove.
				</Typography>
				<ErrorMessages
					errorMessages={accountProfileErrorsDeleteRecoveryMethod.map(error => {
						return { ...error, message: getErrorMessageForCode(error.code) };
					})}
				/>
				<FormControl sx={{ display: "flex" }}>
					<RadioGroup defaultValue={defaultRemovalValue} sx={{ label: { margin: 0 } }}>
						<List sx={getCommonProfileDataListStyles(theme)}>
							{recoveryEmailListElem}
						</List>
					</RadioGroup>
				</FormControl>
				<FormActionContainer>
					<Button
						disabled={accountProfileIsDeletingRecoveryMethod}
						processing={accountProfileIsDeletingRecoveryMethod}
						type="submit"
						variant="contained"
					>
						Submit
					</Button>
				</FormActionContainer>
			</Form>
		</DefaultTemplate >
	);
};

const mapStateToProps = state => {
	return {
		accountProfile: state.profile.accountProfile,
		accountProfileErrorsDeleteRecoveryMethod: state.profile.accountProfileErrorsDeleteRecoveryMethod,
		accountProfileIsDeletingRecoveryMethod: state.profile.accountProfileIsDeletingRecoveryMethod
	};
};

const mapDispatchToProps = dispatch => {
	return {
		deleteRecoveryMethodByContactId: (personRefId, contactId) => dispatch(profileEvents.deleteRecoveryMethodByContactId(personRefId, contactId))
	};
};

export default withProfileAndAccess(connect(mapStateToProps, mapDispatchToProps)(RemoveRecoveryEmailView), ["deleteRecoveryMethod"]);