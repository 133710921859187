import React from "react";

const PendingAccessVerification = props => {
	return (
		<div className="cmp-loading--access">
			<div className="cmp-loading--access__loading">
				<div className="cmp-loading--access__loading-icon">
					<div></div>
					<div></div>
					<div></div>
					<div></div>
				</div>
				<span>Checking Your Access Privileges...</span>
			</div>
		</div>
	);
};

export default PendingAccessVerification;