import * as authEvents from "../../store/events/auth";
import ErrorLoading from "../ErrorMessages/ErrorLoading";
import React from "react";
import { connect } from "react-redux";
import {
	API_NSP_AUTH,
	API_NSP_OPERATION_LOGIN,
	getApiUrl
} from "../../config/api";

const AccessDenied = props => {

	const {
		verifyAccess
	} = props;

	return (
		<div className="cmp-loading--access">
			<ErrorLoading
				alertDescription="Something went wrong confirming your access privileges."
				alertTitle="Oops..."
				primaryActionCallback={verifyAccess}
				primaryActionLabel="Retry"
				secondaryActionCallback={() => {
					const url = getApiUrl(API_NSP_AUTH, API_NSP_OPERATION_LOGIN);
					window.location = url;
				}}
				secondaryActionLabel="Return to Sign In"
			/>
		</div>
	);
};

const mapStateToProps = state => {
	return {};
};

const mapDispatchToProps = dispatch => {
	return {
		verifyAccess: data => dispatch(authEvents.verifyAccess(data))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AccessDenied);