import variables from "../styles/clients/_all/_themes.scss";

export const getThemeForClientId = clientId => {
	return {
		breakpoints: {
			values: {
				xs: parseInt(variables["breakpoint-xs"], 10),
				sm: parseInt(variables["breakpoint-sm"], 10),
				md: parseInt(variables["breakpoint-md"], 10),
				lg: parseInt(variables["breakpoint-lg"], 10),
				xl: parseInt(variables["breakpoint-xl"], 10)
			}
		},
		components: {
			MuiButton: {
				styleOverrides: {
					root: {},
					outlined: {
						borderColor: variables["app-palette-primary-main"],
						color: variables["app-palette-primary-main"],

						"&:hover": {
							borderColor: variables["app-palette-primary-main"],
						}
					}
				}
			},
			MuiSnackbar: {
				styleOverrides: {
					root: {
						top: "100px !important"
					}
				}
			},
			MuiTableCell: {
				styleOverrides: {
					head: {
						backgroundColor: variables["app-palette-primary-main"],
						color: "white",
						fontFamily: variables["primary-font"],
						fontSize: "1.8rem",
						fontWeight: 400,
						minHeight: "8rem",
						padding: "3.6rem 16px 1.2rem"
					},
					root: {
						fontFamily: variables["primary-font-light"],
						fontWeight: 300
					}
				}
			}
		},
		palette: {
			contrastThreshold: 4.5,
			link: {
				main: variables[`${clientId}-palette-link-main`]
			},
			neutral: {
				light: variables[`${clientId}-palette-neutral-light`],
				main: variables[`${clientId}-palette-neutral-main`],
				dark: variables[`${clientId}-palette-neutral-dark`]
			},
			primary: {
				main: variables[`${clientId}-palette-primary-main`]
			},
			secondary: {
				main: variables[`${clientId}-palette-secondary-main`]
			},
			error: {
				main: variables[`${clientId}-palette-error-main`]
			},
			header: {
				light: variables["app-palette-primary-light"],
				main: variables["app-palette-primary-main"]
			},
			info: {
				main: variables[`${clientId}-palette-info-main`]
			},
			text: {
				primary: variables[`${clientId}-palette-text-primary`],
				secondary: variables[`${clientId}-palette-text-secondary`]
			}
		},
		typography: {
			body1: {
				fontSize: 14,
				fontWeight: 300
			},
			button: {
				fontFamily: "'Open Sans', sans-serif",
				fontWeight: 700,
				textTransform: "uppercase"
			},
			fontFamily: "'Open Sans', sans-serif",
			fontSize: 14,
			fontWeight: 400,
			h1: {
				fontSize: 30
			},
			h2: {
				fontSize: 32
			},
			h3: {
				fontSize: 24,
				fontWeight: 300
			},
			h4: {
				fontSize: 18,
				fontWeight: 700
			},
			h5: {
				fontSize: 14,
				fontWeight: 700
			},
			h6: {
				fontSize: 14,
				fontWeight: 300
			},
			htmlFontSize: 10,
			small: {
				fontSize: 12,
				fontWeight: 300
			}
		}
	};
};