import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import LockIcon from "@mui/icons-material/Lock";
import Popover from "@mui/material/Popover";
import React from "react";
import SvgIcon from "@mui/material/SvgIcon";
import { ps } from "../Application/Application";
import { useTheme } from "@mui/material";
import {
	PS_EVENT_SESSION_END,
	PS_EVENT_SESSION_PROFILE
} from "../../config/common";

const ProfileIcon = props => {
	return (
		<SvgIcon {...props} height="26px" viewBox="0 0 26 26" width="26px">
			<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g>
					<circle fill="#6E7278" cx="13" cy="13" r="13"></circle>
					<path d="M6.51819254,22.2962784 C10.1026238,21.6660222 10,20.5545889 9.99963052,19.8581709 L9.99963052,19.4388594 C9.55759929,18.6956125 8.59618136,17.6305784 8.24344044,16.3855325 L8.28764356,16.3855325 C7.19450032,16.3855325 6.96685424,14.1824284 6.96641221,13.8507599 C6.96597018,13.5190913 6.93635409,12.2035873 8.04010607,12.204017 C7.47961047,10.0236829 7.46192922,7.85065249 8.41141231,7.00558509 L8.4056659,7 L0,15.1727082 C0.546932359,18.2532464 2.22800989,21.035281 4.72973418,23 C5.26980255,22.6484778 5.87862152,22.4089201 6.51819254,22.2962784 L6.51819254,22.2962784 Z" fill="#000064" fillRule="nonzero" opacity="0.15"></path>
					<path d="M15.7609374,19.3354329 C16.7262863,18.4926582 17.4209282,17.3876932 17.7587949,16.1574462 L17.8262445,16.1601201 C18.9373644,16.1601201 19.2202031,13.8747862 19.2202031,13.5307383 C19.2202031,13.1866903 19.2620219,11.8220857 18.1374121,11.8220857 C20.5543565,5.31547982 13.8553216,2.77343774 10.3795707,4.55250048 C6.90381984,6.33156323 7.20697773,8.93110268 7.93588319,11.8220857 C6.81172301,11.8220857 6.85668941,13.1893643 6.85668941,13.5307383 C6.85668941,13.8721123 7.11569592,16.1601201 8.22726551,16.1601201 L8.12833942,16.1601201 C8.48807068,17.4525281 9.46564037,18.5564229 9.91530445,19.3274111 L9.91530445,19.7628189 C9.91530445,20.4852304 10.0200762,21.6381475 6.3737502,22.2919277 C5.70634771,22.4083971 5.29897153,22.6355736 4.72973418,23 C9.68759802,26.9693853 16.3737742,26.9644444 21.3356611,23 C20.7177948,22.6365529 20.202981,22.4160166 19.4953976,22.2923734 C15.8589642,21.6029405 15.7613871,20.4838934 15.7609374,19.7614819 L15.7609374,19.3354329 Z" fill="#FFFFFF" fillRule="nonzero"></path>
					<path d="M10.0398215,19.5 L10.0398215,19.9118914 C10.0398215,20.6102973 10.1421655,21.7249031 6.58033183,22.3569582 C5.92839433,22.4695573 5.30604704,22.7090794 4.75,23.061396 C6.18548668,24.2011856 7.84934997,25.0320419 9.63352035,25.5 L15.75,19.5 C14.9683005,20.0634616 14.0226623,20.3655503 13.0525995,20.3616975 C11.9900708,20.3298508 10.9532794,20.0333136 10.0398215,19.5 L10.0398215,19.5 Z" fill="#000064" fillRule="nonzero" opacity="0.15"></path>
				</g>
			</g>
		</SvgIcon>
	);
};

const ProfilePopover = props => {

	const [anchorEl, setAnchorEl] = React.useState(null);
	const theme = useTheme();

	const iconStyles = {
		color: theme.palette.primary.contrastText,
		minWidth: 36
	};

	const textStyles = {
		"span": {
			fontWeight: 600
		}
	};

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const id = open ? "simple-popover" : undefined;

	return (
		<div>
			<IconButton
				aria-describedby={id}
				onClick={handleClick}
			>
				<ProfileIcon />
			</IconButton>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "center",
				}}
			>
				<Box sx={{
					backgroundColor: theme.palette.primary.main,
					color: theme.palette.primary.contrastText,
				}}
				>
					<List sx={{ minWidth: 200 }}>
						<ListItem disablePadding>
							<ListItemButton onClick={() => {
								ps.publish(PS_EVENT_SESSION_PROFILE);
							}}>
								<ListItemIcon sx={iconStyles}>
									<AccountCircleIcon />
								</ListItemIcon>
								<ListItemText
									primary="Account"
									sx={textStyles}
								/>
							</ListItemButton>
						</ListItem>
						<ListItem disablePadding>
							<ListItemButton onClick={() => {
								ps.publish(PS_EVENT_SESSION_END);
							}}>
								<ListItemIcon sx={iconStyles}>
									<LockIcon />
								</ListItemIcon>
								<ListItemText primary="Sign Out" sx={textStyles} />
							</ListItemButton>
						</ListItem>
					</List>
				</Box>
			</Popover>
		</div>
	);
};

export default ProfilePopover;