import * as searchActions from "../../store/actions/search";
import * as searchEvents from "../../store/events/search";
import Box from "@mui/material/Box";
import Button from "../Button/Button";
import Card from "../Card/Card";
import Checkbox from "@mui/material/Checkbox";
import ErrorMessages from "../ErrorMessages/ErrorMessages";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormTextField from "../Form/FormTextField";
import Grid from "@mui/material/Grid";
import React from "react";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import { connect } from "react-redux";
import getStringWithWildcards from "../../utils/getStringWithWildcards";
import { useTheme } from "@mui/material";
import { Form, format, isStringOfLength } from "@pheaa/channels-component-library";
import {
	HTML_ATTR_PATTERN_DOB,
	HTML_ATTR_PATTERN_EMAIL,
	HTML_ATTR_PATTERN_NAME_FIRST,
	HTML_ATTR_PATTERN_NAME_LAST,
	HTML_ATTR_PATTERN_SSN_ACCOUNT_NO,
	HTML_ATTR_PATTERN_USER_ID,
	MAX_LENGTH_NAME_FIRST,
	MAX_LENGTH_NAME_LAST,
	getErrorMessageForCode
} from "../../config/common";

const TabPanel = props => {
	const { children, value, index, ...other } = props;

	return (
		<div
			aria-labelledby={`search-tab-${index}`}
			hidden={value !== index}
			id={`search-tabpanel-${index}`}
			role="tabpanel"
			{...other}
		>
			{value === index && (<Box sx={{ py: 2 }}>{children}</Box>)}
		</div>
	);
};

const Search = props => {

	const {
		interfaceErrors,
		isSearchingForAccounts,
		searchCategoryIndex,
		searchForUserAccounts,
		searchCriteria,
		setDataSearch,
		systemErrors
	} = props;

	const theme = useTheme();

	const handleSearch = (e, formData) => {
		const requestData = {};

		if (isStringOfLength(formData.identifier)) {
			let cleanIdentifier = formData.identifier.replace(/\D/g, "");
			cleanIdentifier.length === 9 && (requestData.ssn = cleanIdentifier);
			cleanIdentifier.length === 10 && (requestData.accountNumber = cleanIdentifier);
		}

		if (isStringOfLength(formData.userId)) {
			requestData.userId = formData.userId;
		}

		if (isStringOfLength(formData.firstName) || isStringOfLength(formData.lastName)) {
			requestData.name = {};

			if (isStringOfLength(formData.firstName)) {
				requestData.name.first = formData.firstNamePartial ? getStringWithWildcards(formData.firstName) : formData.firstName;
			}

			if (isStringOfLength(formData.lastName)) {
				requestData.name.last = formData.lastNamePartial ? getStringWithWildcards(formData.lastName) : formData.lastName;
			}
		}

		if (isStringOfLength(formData.birthDate)) {
			requestData.birthDate = format.asDate(formData.birthDate, { outputPattern: "yyyy-mm-dd" });
		}

		if (isStringOfLength(formData.email)) {
			requestData.email = formData.email;
		}

		searchForUserAccounts(requestData);
	};

	const handlePartialChange = (e, updatedValue) => {
		setDataSearch({ searchCriteria: { [e.target.name]: updatedValue } });
	};

	const handleTabChange = (e, targetIndex) => {
		setDataSearch({
			interfaceErrors: [],
			searchCategoryIndex: targetIndex,
			systemErrors: []
		});
	};

	return (
		<React.Fragment>
			<Typography mb={4} mt={3} paragraph={true}>
				Select one of the four search criteria groups.
			</Typography>
			<Card>
				<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
					<Tabs
						aria-label="Search Categories"
						onChange={handleTabChange}
						value={searchCategoryIndex}
					>
						{
							["SSN/Account #", "Username", "Personal Info", "Email"].map((category, i) => {
								return (
									<Tab
										aria-controls={`search-tabpanel-${i}`}
										id={`search-tab-${i}`}
										key={i}
										label={category}
									/>
								);
							})
						}
					</Tabs>
				</Box>
				<Form onSubmit={handleSearch}>
					<Box>
						<TabPanel value={searchCategoryIndex} index={0}>
							<ErrorMessages
								errorMessages={[...interfaceErrors, ...systemErrors].map((errorObj, i) => {
									return { ...errorObj, message: getErrorMessageForCode(errorObj.code) };
								})}
							/>
							<Grid container spacing={2}>
								<Grid item xs={6}>
									<FormTextField
										name="identifier"
										onChange={e => { setDataSearch({ searchCriteria: { identifier: e.target.value } }); }}
										pattern={HTML_ATTR_PATTERN_SSN_ACCOUNT_NO}
										required={true}
										title="Social Security Number or Account #"
										value={searchCriteria.identifier}
									/>
								</Grid>
							</Grid>
						</TabPanel>
						<TabPanel value={searchCategoryIndex} index={1}>
							<ErrorMessages
								errorMessages={[...interfaceErrors, ...systemErrors].map((errorObj, i) => {
									return { ...errorObj, message: getErrorMessageForCode(errorObj.code) };
								})}
							/>
							<Grid container spacing={2}>
								<Grid item xs={6}>
									<FormTextField
										name="userId"
										onChange={e => { setDataSearch({ searchCriteria: { userId: e.target.value } }); }}
										pattern={HTML_ATTR_PATTERN_USER_ID}
										required={true}
										title="Username"
										value={searchCriteria.userId}
									/>
								</Grid>
							</Grid>
						</TabPanel>
						<TabPanel value={searchCategoryIndex} index={2}>
							<ErrorMessages
								errorMessages={[...interfaceErrors, ...systemErrors].map((errorObj, i) => {
									return { ...errorObj, message: getErrorMessageForCode(errorObj.code) };
								})}
							/>
							<Grid container spacing={2}>
								<Grid item xs={6}>
									<FormTextField
										inputStyle="cmp-form-text--animated-label cmp-form-text--no-bottom-margin"
										name="lastName"
										onChange={e => { setDataSearch({ searchCriteria: { lastName: e.target.value } }); }}
										pattern={HTML_ATTR_PATTERN_NAME_LAST}
										title="Last Name"
										value={searchCriteria.lastName}
									/>
									<FormControlLabel
										control={
											<Checkbox
												checked={searchCriteria.lastNamePartial}
												disabled={isStringOfLength(searchCriteria.lastName) && (searchCriteria.lastName.length >= MAX_LENGTH_NAME_LAST - 1)}
												onChange={handlePartialChange}
											/>
										}
										label="Partial Search"
										name="lastNamePartial"
									/>
								</Grid>
								<Grid item xs={6}>
									<FormTextField
										inputStyle="cmp-form-text--animated-label cmp-form-text--no-bottom-margin"
										name="firstName"
										onChange={e => { setDataSearch({ searchCriteria: { firstName: e.target.value } }); }}
										pattern={HTML_ATTR_PATTERN_NAME_FIRST}
										title="First Name"
										value={searchCriteria.firstName}
									/>
									<FormControlLabel
										control={
											<Checkbox
												checked={searchCriteria.firstNamePartial}
												disabled={isStringOfLength(searchCriteria.firstName) && (searchCriteria.firstName.length >= MAX_LENGTH_NAME_FIRST - 1)}
												onChange={handlePartialChange}
											/>
										}
										label="Partial Search"
										name="firstNamePartial"
									/>
								</Grid>
								<Grid item xs={6}>
									<FormTextField
										helpMessage="MM/DD/YYYY"
										inputStyle="cmp-form-text--animated-label cmp-form-text--no-bottom-margin"
										name="birthDate"
										onChange={e => { setDataSearch({ searchCriteria: { birthDate: e.target.value } }); }}
										pattern={HTML_ATTR_PATTERN_DOB}
										title="Date of Birth"
										value={searchCriteria.birthDate}
									/>
								</Grid>
							</Grid>
						</TabPanel>
						<TabPanel value={searchCategoryIndex} index={3}>
							<ErrorMessages
								errorMessages={[...interfaceErrors, ...systemErrors].map((errorObj, i) => {
									return { ...errorObj, message: getErrorMessageForCode(errorObj.code) };
								})}
							/>
							<Grid container spacing={2}>
								<Grid item xs={6}>
									<FormTextField
										name="email"
										onChange={e => { setDataSearch({ searchCriteria: { email: e.target.value } }); }}
										pattern={HTML_ATTR_PATTERN_EMAIL}
										required={true}
										title="Email"
										value={searchCriteria.email}
									/>
								</Grid>
							</Grid>
						</TabPanel>
					</Box>
					<Box
						align="end"
						sx={{
							borderTop: `1px solid ${theme.palette.divider}`,
							marginTop: 2,
							paddingTop: 2.5
						}}
					>
						<Button
							disabled={isSearchingForAccounts}
							type="submit"
							variant="contained"
						>
							{isSearchingForAccounts ? "Searching..." : "Search"}
						</Button>
					</Box>
				</Form>
			</Card>
		</React.Fragment>
	);
};

const mapStateToProps = state => {
	return {
		interfaceErrors: state.search.interfaceErrors,
		isSearchingForAccounts: state.search.isSearchingForAccounts,
		searchCategoryIndex: state.search.searchCategoryIndex,
		searchCriteria: state.search.searchCriteria,
		systemErrors: state.search.systemErrors
	};
};

const mapDispatchToProps = dispatch => {
	return {
		searchForUserAccounts: data => dispatch(searchEvents.searchForUserAccounts(data)),
		setDataSearch: data => dispatch(searchActions.setData(data))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Search);