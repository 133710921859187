export const BLOCK_EVENT_BLOCK = "block";
export const BLOCK_EVENT_UNBLOCK = "unblock";
export const BLOCK_SCOPE_ACCOUNT = "account";
export const BLOCK_SCOPE_PAYMENT = "payment";

export const BUSINESS_EVENT_TYPE_WEB_APP = "web-app";
export const BUSINESS_EVENT_TYPE_WEB_FULL = "web-full";
export const BUSINESS_EVENT_TYPE_WEB_MOBILE = "web-mobile";

export const CONFIG_FILENAME = "config.json";

export const CONFIRMATION_EVENT_ID_UPDATE_COMPLETE = "confirm:updateComplete";

export const currentDate = (() => {
	let _currentDate = new Date();

	return {
		getDate: () => {
			return _currentDate.getUTCDate();
		},
		getMonth: () => {
			return _currentDate.getUTCMonth();
		},
		getYear: () => {
			return _currentDate.getUTCFullYear();
		},
		setCurrentDate: date => {
			_currentDate = date;
			return _currentDate;
		}
	};
})();

export const getCommonProfileDataListStyles = theme => ({
	borderTop: `0.1rem solid ${theme.palette.neutral.main}`,
	marginTop: 3,
	padding: 0,
	".MuiListItem-root": {
		borderBottom: `0.1rem solid ${theme.palette.neutral.main}`,

		".MuiListItemText-root": {

			"&:last-of-type": {
				paddingLeft: 2,
				textAlign: "right",

				"span": {
					fontWeight: theme.typography.fontWeightBold,
				}
			}
		}
	}
});

export const HTML_ATTR_ID_SEARCH_RESULTS = "searchResults";
// eslint-disable-next-line no-useless-escape
export const HTML_ATTR_PATTERN_DOB = "(?:(?:0?[1-9]|1[012])[\\\/.,\\-](?:0?[1-9]|1\\d|2[0-8])|(?:0?[13456789]|1[012])[\\\/.,\\-](?:29|30)|(?:0?[13578]|1[02])[\\\/.,\\-]31)[\\\/.,\\-](?:19|[2-9]\\d)\\d{2}|0?2[\\\/.,\\-]29[\\\/.,\\-](?:(?:19|[2-9]\\d)(?:0[48]|[2468][048]|[13579][26])|(?:(?:[2468][048]|[3579][26])00))";
// eslint-disable-next-line no-useless-escape
export const HTML_ATTR_PATTERN_EMAIL = "[a-zA-Z0-9!#$%&'*+\\\/=?^_`\\{\\}~\\-]+(?:\.[a-zA-Z0-9!#$%&'*+\\\/=?^_`\\{\\}~\\-]+)*@[a-zA-Z0-9]+(?:[\\-\.]{1}[a-z0-9]+)*\\.[a-zA-Z]{2,18}";
// eslint-disable-next-line no-useless-escape
export const HTML_ATTR_PATTERN_NAME_FIRST = "[a-zA-Z'\\-\\s]{1,13}";
// eslint-disable-next-line no-useless-escape
export const HTML_ATTR_PATTERN_NAME_LAST = "[a-zA-Z'\\-\\s]{1,35}";
// eslint-disable-next-line no-useless-escape
export const HTML_ATTR_PATTERN_SSN = "([0-9]{9}|[0-9]{3}-[0-9]{2}-[0-9]{4})";
// eslint-disable-next-line no-useless-escape
export const HTML_ATTR_PATTERN_SSN_ACCOUNT_NO = "([0-9]{9}|[0-9]{3}-[0-9]{2}-[0-9]{4}|[0-9]{10})";
// eslint-disable-next-line no-useless-escape
export const HTML_ATTR_PATTERN_USER_ID = "[a-zA-Z0-9_]{6,12}";

export const INTERFACE_ERROR_DNU = "data-not-updatable";
export const INTERFACE_ERROR_NSC = "no-search-criteria";
export const INTERFACE_ERROR_SSN = "ssn-matches-profile";
export const INTERFACE_ERROR_MESSAGE_DNU = "This data is not updatable.";
export const INTERFACE_ERROR_MESSAGE_NSC = "Please provide search criteria.";
export const INTERFACE_ERROR_MESSAGE_SSN = "The SSN entered must be different than the current number.";

export const INTERFACE_ERRORS = {
	[INTERFACE_ERROR_DNU]: { message: INTERFACE_ERROR_MESSAGE_DNU },
	[INTERFACE_ERROR_NSC]: { message: INTERFACE_ERROR_MESSAGE_NSC },
	[INTERFACE_ERROR_SSN]: { message: INTERFACE_ERROR_MESSAGE_SSN }
};

export const MAX_LENGTH_NAME_FIRST = 13;
export const MAX_LENGTH_NAME_LAST = 35;

export const PROFILE_STATUS_ACTIVE = "active";
export const PROFILE_STATUS_BLOCKED = "blocked";

export const PS_EVENT_ACCESS_DENIED = "access:denied";
export const PS_EVENT_ACCESS_GRANTED = "access:granted";
export const PS_EVENT_LOGOUT_INVALID_TOKEN = "logout:invalidToken";
export const PS_EVENT_SESSION_END = "session:end";
export const PS_EVENT_SESSION_EXPIRED = "session:expired";
export const PS_EVENT_SESSION_EXTEND = "session:extend";
export const PS_EVENT_SESSION_PROFILE = "session:profile";

export const RECENT_UPDATE_KEY_CONTACT_EMAIL = "contactEmail";
export const RECENT_UPDATE_KEY_DOB = "birthDate";
export const RECENT_UPDATE_KEY_NAME = "name";
export const RECENT_UPDATE_KEY_SSN = "ssn";
export const RECENT_UPDATE_KEY_USER_ID = "userId";
export const RECENT_UPDATE_THRESHOLD = 60000;

export const SYSTEM_ERROR_400 = 400;
export const SYSTEM_ERROR_401 = 401;
export const SYSTEM_ERROR_403 = 403;
export const SYSTEM_ERROR_404 = 404;
export const SYSTEM_ERROR_409 = 409;
export const SYSTEM_ERROR_500 = 500;
export const SYSTEM_ERROR_503 = 503;
export const SYSTEM_ERROR_CNX = "sysErrConnection";
export const SYSTEM_ERROR_INV = "sysErrInvalid";
export const SYSTEM_ERROR_LTK = "sysErrLegacyTransferKey";
export const SYSTEM_ERROR_RSP = "sysErrInvalidResponse";
export const SYSTEM_ERROR_MESSAGE_503 = "We're sorry, but the service is unavailable at this time. We apologize for any inconvenience. Please try again later.";
export const SYSTEM_ERROR_MESSAGE_CNX = "We're sorry, but a connection could not be established. We apologize for any inconvenience and thank you for your patience.";
export const SYSTEM_ERROR_MESSAGE_DEFAULT = "We're sorry but something went wrong. We apologize for any inconvenience. Please try again later.";
export const SYSTEM_ERROR_MESSAGE_INV = "We're sorry, but the request was invalid. We apologize for any inconvenience. Please try again later.";
export const SYSTEM_ERROR_MESSAGE_MNT = "The site is currently down for maintenance. We apologize for any inconvenience and thank you for your patience.";
export const SYSTEM_ERROR_MESSAGE_RSP = "An invalid response was received. We apologize for any inconvenience and thank you for your patience.";
export const SYSTEM_ERROR_MESSAGE_LTK = "We're sorry, but an unexpected error occurred while getting your transfer token. We apologize for any inconvenience and thank you for your patience.";

export const SYSTEM_ERRORS = {
	[SYSTEM_ERROR_400]: { message: SYSTEM_ERROR_MESSAGE_DEFAULT },
	[SYSTEM_ERROR_401]: { message: SYSTEM_ERROR_MESSAGE_DEFAULT },
	[SYSTEM_ERROR_403]: { message: SYSTEM_ERROR_MESSAGE_DEFAULT },
	[SYSTEM_ERROR_404]: { message: SYSTEM_ERROR_MESSAGE_DEFAULT },
	[SYSTEM_ERROR_409]: { message: SYSTEM_ERROR_MESSAGE_MNT },
	[SYSTEM_ERROR_500]: { message: SYSTEM_ERROR_MESSAGE_DEFAULT },
	[SYSTEM_ERROR_503]: { message: SYSTEM_ERROR_MESSAGE_503 },
	[SYSTEM_ERROR_CNX]: { message: SYSTEM_ERROR_MESSAGE_CNX },
	[SYSTEM_ERROR_INV]: { message: SYSTEM_ERROR_MESSAGE_INV },
	[SYSTEM_ERROR_LTK]: { message: SYSTEM_ERROR_MESSAGE_LTK },
	[SYSTEM_ERROR_RSP]: { message: SYSTEM_ERROR_MESSAGE_RSP }
};

export const TEXT_DATA_UNAVAILABLE = "Unavailable";

export const getErrorMessageForCode = (code, customErrors = {}) => {
	return { ...SYSTEM_ERRORS, ...INTERFACE_ERRORS, ...customErrors }[code].message;
};