import * as profileEvents from "../../store/events/profile";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import React from "react";
import Skeleton from "@mui/material/Skeleton";
import SyncIcon from "@mui/icons-material/Sync";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { useTheme } from "@mui/material";
import { format, isArrayOfLength, isNumber, isObject, isStringOfLength } from "@pheaa/channels-component-library";

const getAccountOverviewStyles = theme => ({
	backgroundColor: theme.palette.neutral.light,
	display: "flex",
	margin: "1.4rem 0",
	maxWidth: "100% !important",
	paddingBottom: "2rem",
	paddingTop: "2rem"
});

const getAccountOverviewItemStyles = theme => ({
	display: "flex",
	flex: "1",
	flexDirection: "column"
});

const getAccountOverviewItemValueStyles = theme => ({
	color: theme.palette.common.black,
	fontSize: "2.4rem",
	fontWeight: 400
});

const ProfileDetailsOverview = props => {

	let returnElem = null;

	let accountNumberElem = <Skeleton sx={{ transform: "scale(1, 1)" }} width={140} />;
	let dateOfBirthElem = <Skeleton sx={{ transform: "scale(1, 1)" }} width={140} />;
	let socialSecurityNumberElem = <Skeleton sx={{ transform: "scale(1, 1)" }} width={140} />;

	const {
		accountProfile,
		accountProfileErrors,
		accountProfileIsUpdatingAccountNumber,
		roles,
		syncAccountNumber
	} = props;

	const { personRefId } = useParams();
	const theme = useTheme();

	const handleClickSync = e => {
		syncAccountNumber(personRefId, { ssn: accountProfile.ssn });
	};

	if (!isArrayOfLength(accountProfileErrors)) {
		let isAllowedToSyncAccountNumber = false;

		if (isObject(accountProfile)) {

			if (isStringOfLength(accountProfile.accountNumber) || isNumber(accountProfile.accountNumber)) {
				accountNumberElem = accountProfile.accountNumber;
			}

			if (isStringOfLength(accountProfile.ssn) || isNumber(accountProfile.ssn)) {
				socialSecurityNumberElem = format.asSocial(accountProfile.ssn);
				// If SSN is defined and user roles include "identifier-resolver", they may sync account number;
				isAllowedToSyncAccountNumber = roles.includes("identifier-resolver");
			}

			if (isObject(accountProfile.birth) && isStringOfLength(accountProfile.birth.occurrenceDate)) {
				dateOfBirthElem = format.asDate(accountProfile.birth.occurrenceDate);
			}
		}

		returnElem = (
			<Box>
				<Container sx={getAccountOverviewStyles(theme)}>
					<Box sx={getAccountOverviewItemStyles(theme)}>
						<Box sx={getAccountOverviewItemValueStyles(theme)}>
							{accountNumberElem}
							{isAllowedToSyncAccountNumber && (
								<IconButton
									color="primary"
									disabled={accountProfileIsUpdatingAccountNumber}
									onClick={handleClickSync}
									sx={{ padding: `0 ${theme.spacing(1)}` }}
								>
									<SyncIcon />
								</IconButton>
							)}
						</Box>
						<Box component="span">Account Number</Box>
					</Box>
					<Box sx={getAccountOverviewItemStyles(theme)}>
						<Box sx={getAccountOverviewItemValueStyles(theme)}>
							{socialSecurityNumberElem}
						</Box>
						<Box component="span">Social Security Number</Box>
					</Box>
					<Box sx={getAccountOverviewItemStyles(theme)}>
						<Box sx={getAccountOverviewItemValueStyles(theme)}>
							{dateOfBirthElem}
						</Box>
						<Box component="span">Date of Birth</Box>
					</Box>
				</Container>
			</Box>
		);
	}

	return returnElem;
};

const mapStateToProps = state => {
	return {
		accountProfile: state.profile.accountProfile,
		accountProfileErrors: state.profile.accountProfileErrors,
		accountProfileIsUpdatingAccountNumber: state.profile.accountProfileIsUpdatingAccountNumber,
		roles: state.auth.roles
	};
};

const mapDispatchToProps = dispatch => {
	return {
		syncAccountNumber: (personRefId, data) => dispatch(profileEvents.syncAccountNumber(personRefId, data))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileDetailsOverview);