import { Button as ImportedButton } from "@mui/material";
import React from "react";
import classNames from "classnames";

const withEnhancements = WrappedComponent => props => {

	const {
		align,
		children,
		processing,
		size,
		variant,
		...rest
	} = props;

	const cmpClassName = classNames({
		"cmp-button": true,
		"cmp-button--alignCenter": align === "center"
	});

	return (
		<WrappedComponent
			{...rest}
			children={processing ? "Processing..." : children}
			className={cmpClassName}
			disableElevation
			size={size}
			variant={variant}
		/>
	);
};

const Button = withEnhancements(ImportedButton);

export default Button;