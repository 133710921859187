import { TOKEN_HOSTNAME_ENV } from "./tokens";

export const DEV = "dev";
export const QA = "qa";
export const PROD = "prod";

export const getIsLocalEnv = ({ hostname } = window.location) => {
	return hostname === "localhost";
};

export const getEnvFromHostname = () => {
	let { hostname } = window.location;
	let env = PROD;

	if (
		hostname === "d287s231btevj9.cloudfront.net" ||
		hostname === "admin.dev.partners.aessuccess.org" ||
		hostname === "localhost"
	) {
		env = DEV;
	} else if (
		hostname === "d3szy201e00ctr.cloudfront.net" ||
		hostname === "admin.qa.partners.aessuccess.org"
	) {
		env = QA;
	}

	return env.toLowerCase();
};

export const replaceEnvTokenInUrl = url => {
	// Set replacement string to empty string;
	let envReplacement = ".";
	// If non-prod environment;
	if (currentEnv !== PROD) {
		// Set replacement string to current env;
		envReplacement = `.${currentEnv}.`;
	}
	// Return URL, replacing token with replacement string;
	return url.replace(TOKEN_HOSTNAME_ENV, envReplacement);
};

export const currentEnv = getEnvFromHostname();
export const isLocalEnv = getIsLocalEnv();