import * as profileEvents from "../../store/events/profile";
import Button from "../../components/Button/Button";
import DefaultTemplate from "../templates/DefaultTemplate";
import ErrorMessages from "../../components/ErrorMessages/ErrorMessages";
import FormActionContainer from "../../components/Form/FormActionContainer";
import FormTextField from "../../components/Form/FormTextField";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import React from "react";
import TaskHeader from "../../components/Task/TaskHeader";
import Typography from "@mui/material/Typography";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { useTheme } from "@mui/material";
import withProfileAndAccess from "../../utils/withProfileAndAccess";
import {
	Form,
	isArrayOfLength,
	isObject
} from "@pheaa/channels-component-library";
import {
	HTML_ATTR_PATTERN_EMAIL,
	TEXT_DATA_UNAVAILABLE,
	getCommonProfileDataListStyles,
	getErrorMessageForCode
} from "../../config/common";

const ManageContactEmailView = props => {

	let contactEmail = null;

	const {
		accountProfile,
		accountProfileErrorsUpdateContactEmail,
		accountProfileIsUpdatingContactEmail,
		updateAccountProfileContactEmail
	} = props;

	const { personRefId } = useParams();
	const theme = useTheme();

	const handleSubmit = (e, formData) => {
		updateAccountProfileContactEmail(personRefId, { email: formData.email });
	};

	if (isObject(accountProfile) && isArrayOfLength(accountProfile.emails)) {
		contactEmail = accountProfile.emails.find(email => email.id === "contact");
	}

	if (isObject(contactEmail)) {
		contactEmail = contactEmail.email;
	} else {
		contactEmail = TEXT_DATA_UNAVAILABLE;
	}

	return (
		<DefaultTemplate isTask={true}>
			<TaskHeader
				closeTaskDestination={`/profiles/${personRefId}`}
				title="Edit Contact Email"
			/>
			<Form id="defaultForm" onSubmit={handleSubmit}>
				<Typography align="center" gutterBottom={true}>
					Enter new contact email address in field below.
				</Typography>
				<ErrorMessages
					errorMessages={accountProfileErrorsUpdateContactEmail.map(error => {
						return { ...error, message: getErrorMessageForCode(error.code) };
					})}
				/>
				<List sx={getCommonProfileDataListStyles(theme)}>
					<ListItem>
						<ListItemText primary="Current Contact Email:" />
						<ListItemText primary={contactEmail} />
					</ListItem>
				</List>
				<FormTextField
					name="email"
					pattern={HTML_ATTR_PATTERN_EMAIL}
					required={true}
					title="New Contact Email"
				/>
				<FormActionContainer>
					<Button
						disabled={accountProfileIsUpdatingContactEmail}
						processing={accountProfileIsUpdatingContactEmail}
						type="submit"
						variant="contained"
					>
						Save
					</Button>
				</FormActionContainer>
			</Form>
		</DefaultTemplate>
	);
};

const mapStateToProps = state => {
	return {
		accountProfile: state.profile.accountProfile,
		accountProfileErrorsUpdateContactEmail: state.profile.accountProfileErrorsUpdateContactEmail,
		accountProfileIsUpdatingContactEmail: state.profile.accountProfileIsUpdatingContactEmail
	};
};

const mapDispatchToProps = dispatch => {
	return {
		updateAccountProfileContactEmail: (personRefId, data) => dispatch(profileEvents.updateAccountProfileContactEmail(personRefId, data))
	};
};

export default withProfileAndAccess(connect(mapStateToProps, mapDispatchToProps)(ManageContactEmailView), ["updateContactEmail"]);