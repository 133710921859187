import {
	FormTextField as ImportedFormTextField,
	generateId,
	htmlInputValidation,
	isStringOfLength
} from "@pheaa/channels-component-library";
import React, { useEffect, useState } from "react";

const withEnhancements = WrappedComponent => props => {

	const {
		additionalDetails,
		additionalDetailsOnFocus,
		constraintMessage,
		helpMessage,
		hideTitle,
		includeConfirmation,
		inputStyle = "cmp-form-text--animated-label",
		requiredMessage,
		title,
		...rest
	} = props;

	const [id, setId] = useState();

	useEffect(() => {
		setId(isStringOfLength(props.id) ? props.id : generateId());
	}, [props.id]);

	let conditionalProps = {
		onValidate: inputElem => {
			let message = "";

			if (inputElem.validity.valueMissing) {
				message = isStringOfLength(requiredMessage) ? requiredMessage : `Invalid ${title}`;
			}

			if (inputElem.validity.patternMismatch) {
				message = isStringOfLength(constraintMessage) ? constraintMessage : `Invalid ${title}`;
			}

			if (includeConfirmation) {
				let inputElemConfirmation = document.getElementById(`${id}_confirm`);

				if (inputElemConfirmation.value.trim().length > 0) {
					htmlInputValidation.getElementIsValid(inputElemConfirmation, inputElem);
				}
			}

			return { message, valid: message.length === 0 };
		}
	};

	let confirmationElem = null;

	if (isStringOfLength(inputStyle)) {
		conditionalProps.className = inputStyle;
	}

	if (isStringOfLength(helpMessage)) {
		conditionalProps.supportingContext = helpMessage;
	}

	if (hideTitle) {
		conditionalProps.titleHidden = true;
	}

	if (isStringOfLength(additionalDetails)) {
		conditionalProps.additionalDetails = {
			details: additionalDetails,
			onFocus: additionalDetailsOnFocus
		};
	}

	if (includeConfirmation) {
		confirmationElem = (
			<WrappedComponent
				{...rest}
				{...conditionalProps}
				additionalDetails={null}
				id={`${id}_confirm`}
				label={`Confirm ${title}`}
				name={`${props.name}_confirm`}
				onValidate={inputElemConfirmation => {
					let inputElem = document.getElementById(id);
					let matches = htmlInputValidation.compareForValidity(inputElemConfirmation, inputElem);
					let message = matches ? "" : htmlInputValidation.getValidationMessageForElement(inputElemConfirmation);
					return { message, valid: message.length === 0 };
				}}
				trimOnBlur={true}
			/>
		);
	}

	return (
		<>
			<WrappedComponent
				{...rest}
				{...conditionalProps}
				id={id}
				label={title}
				trimOnBlur={true}
			/>
			{confirmationElem}
		</>
	);
};

const FormTextField = withEnhancements(ImportedFormTextField);

export default FormTextField;