import * as profileEvents from "../../store/events/profile";
import Button from "../../components/Button/Button";
import DefaultTemplate from "../templates/DefaultTemplate";
import ErrorMessages from "../../components/ErrorMessages/ErrorMessages";
import FormActionContainer from "../../components/Form/FormActionContainer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import React from "react";
import TaskHeader from "../../components/Task/TaskHeader";
import Typography from "@mui/material/Typography";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { useTheme } from "@mui/material";
import withProfileAndAccess from "../../utils/withProfileAndAccess";
import {
	Form,
	format
} from "@pheaa/channels-component-library";
import {
	getCommonProfileDataListStyles,
	getErrorMessageForCode
} from "../../config/common";

const DeleteOnlineAccountView = props => {

	const {
		accountProfile,
		accountProfileErrorsDelete,
		accountProfileIsDeleting,
		deleteOnlineAccount
	} = props;

	const { personRefId } = useParams();
	const theme = useTheme();

	const handleSubmit = (e, formData) => {
		deleteOnlineAccount(personRefId);
	};

	return (
		<DefaultTemplate isTask={true}>
			<TaskHeader
				closeTaskDestination={`/profiles/${personRefId}`}
				title="Delete Online Account"
			/>
			<Form id="defaultForm" onSubmit={handleSubmit}>
				<Typography align="center" gutterBottom={true}>
					Are you sure you want to delete the following online account?
				</Typography>
				<ErrorMessages
					errorMessages={accountProfileErrorsDelete.map(error => {
						return { ...error, message: getErrorMessageForCode(error.code) };
					})}
				/>
				<List sx={getCommonProfileDataListStyles(theme)}>
					<ListItem>
						<ListItemText primary="Name:" />
						<ListItemText primary={format.asProperName(accountProfile.name)} />
					</ListItem>
					<ListItem>
						<ListItemText primary="Account Number:" />
						<ListItemText primary={accountProfile.accountNumber} />
					</ListItem>
				</List>
				<FormActionContainer>
					<Button
						disabled={accountProfileIsDeleting}
						processing={accountProfileIsDeleting}
						type="submit"
						variant="contained"
					>
						Delete Online Account
					</Button>
				</FormActionContainer>
			</Form>
		</DefaultTemplate>
	);
};

const mapStateToProps = state => {
	return {
		accountProfile: state.profile.accountProfile,
		accountProfileErrorsDelete: state.profile.accountProfileErrorsDelete,
		accountProfileIsDeleting: state.profile.accountProfileIsDeleting
	};
};

const mapDispatchToProps = dispatch => {
	return {
		deleteOnlineAccount: data => dispatch(profileEvents.deleteOnlineAccount(data))
	};
};

export default withProfileAndAccess(connect(mapStateToProps, mapDispatchToProps)(DeleteOnlineAccountView), ["deleteWebAccount"]);