import * as profileEvents from "../../store/events/profile";
import Button from "../../components/Button/Button";
import DefaultTemplate from "../templates/DefaultTemplate";
import ErrorMessages from "../../components/ErrorMessages/ErrorMessages";
import FormActionContainer from "../../components/Form/FormActionContainer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import React from "react";
import TaskHeader from "../../components/Task/TaskHeader";
import Typography from "@mui/material/Typography";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { useTheme } from "@mui/material";
import withProfileAndAccess from "../../utils/withProfileAndAccess";
import {
	Form,
	format,
	isObject,
	isStringOfLength
} from "@pheaa/channels-component-library";
import {
	TEXT_DATA_UNAVAILABLE,
	getCommonProfileDataListStyles,
	getErrorMessageForCode
} from "../../config/common";

const ManageSSNConfirmView = props => {

	let nameElem = false;

	const {
		accountProfile,
		accountProfileErrorsUpdateSSN,
		accountProfileIsUpdatingSSN,
		accountProfileNewSSN,
		cleanUpSSNChange,
		updateSocialSecurityNumber
	} = props;

	const { personRefId } = useParams();
	const theme = useTheme();

	const handleSubmit = (e, formData) => {
		updateSocialSecurityNumber(personRefId, accountProfileNewSSN);
	};

	if (isObject(accountProfile) && isObject(accountProfile.name)) {
		nameElem = format.asProperName(accountProfile.name);
		!isStringOfLength(nameElem) && (nameElem = TEXT_DATA_UNAVAILABLE);
	} else {
		nameElem = TEXT_DATA_UNAVAILABLE;
	}

	return (
		<DefaultTemplate isTask={true}>
			<TaskHeader
				closeCallback={() => { cleanUpSSNChange(); }}
				closeTaskDestination={`/profiles/${personRefId}`}
				title="Edit Social Security Number"
			/>
			<Form id="defaultForm" onSubmit={handleSubmit}>
				<Typography align="center" gutterBottom={true}>
					Are you sure you want to edit the following Social Security Number?
				</Typography>
				<ErrorMessages
					errorMessages={accountProfileErrorsUpdateSSN.map(error => {
						return { ...error, message: getErrorMessageForCode(error.code) };
					})}
				/>
				<List sx={getCommonProfileDataListStyles(theme)}>
					<ListItem>
						<ListItemText primary="Name:" />
						<ListItemText primary={nameElem} />
					</ListItem>
					<ListItem>
						<ListItemText primary="Social Security Number:" />
						<ListItemText primary={format.asSocial(accountProfileNewSSN)} />
					</ListItem>
				</List>
				<FormActionContainer>
					<Button
						disabled={accountProfileIsUpdatingSSN}
						processing={accountProfileIsUpdatingSSN}
						type="submit"
						variant="contained"
					>
						Save
					</Button>
				</FormActionContainer>
			</Form>
		</DefaultTemplate >
	);
};

const mapStateToProps = state => {
	return {
		accountProfile: state.profile.accountProfile,
		accountProfileErrorsUpdateSSN: state.profile.accountProfileErrorsUpdateSSN,
		accountProfileIsUpdatingSSN: state.profile.accountProfileIsUpdatingSSN,
		accountProfileNewSSN: state.profile.accountProfileNewSSN
	};
};

const mapDispatchToProps = dispatch => {
	return {
		cleanUpSSNChange: () => dispatch(profileEvents.cleanUpSSNChange()),
		updateSocialSecurityNumber: (personRefId, ssn) => dispatch(profileEvents.updateSocialSecurityNumber(personRefId, ssn))
	};
};

export default withProfileAndAccess(connect(mapStateToProps, mapDispatchToProps)(ManageSSNConfirmView), ["updateSocialSecurityNumber"]);