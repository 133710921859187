import { arrayMergeOverwrite } from "./common";
import { createReducer } from "@reduxjs/toolkit";
import merge from "deepmerge";
import { clearAccountInteractionId, resetData, setData } from "../actions/profile";

const initialState = {
	accountBlockHistory: null,
	accountBlockHistoryErrors: [],
	accountBlockHistoryIsLoading: false,
	accountHasActiveWebProfile: false,
	accountInteractionId: null,
	accountInteractions: null,
	accountInteractionsErrors: [],
	accountInteractionsIsLoading: false,
	accountProfile: null,
	accountProfileConflict: null,
	accountProfileErrors: [],
	accountProfileErrorsConflict: [],
	accountProfileErrorsDelete: [],
	accountProfileErrorsDeleteRecoveryMethod: [],
	accountProfileErrorsDeleteTempLock: [],
	accountProfileErrorsUpdate: [],
	accountProfileErrorsUpdateAccountNumber: [],
	accountProfileErrorsUpdateBlocks: [],
	accountProfileErrorsUpdateContactEmail: [],
	accountProfileErrorsUpdateRecoveryNotification: [],
	accountProfileErrorsUpdateSSN: [],
	accountProfileErrorsValidPersonIdentifier: [],
	accountProfileIsCheckingConflict: false,
	accountProfileIsDeleting: false,
	accountProfileIsDeletingRecoveryMethod: false,
	accountProfileIsDeletingTempLock: false,
	accountProfileIsLoading: false,
	accountProfileIsUpdating: false,
	accountProfileIsUpdatingAccountNumber: false,
	accountProfileIsUpdatingBlocks: false,
	accountProfileIsUpdatingContactEmail: false,
	accountProfileIsUpdatingRecoveryNotification: false,
	accountProfileIsUpdatingSSN: false,
	accountProfileIsValidatingPersonIdentifier: false,
	accountProfileNewSSN: null,
	accountProfileValidPersonIdentifier: null,
	confirmationEvent: null,
	recentUpdates: []
};

const profileReducer = createReducer(initialState, (builder) => {
	builder
		.addCase(clearAccountInteractionId, (state, action) => {
			return merge(state, { accountInteractionId: initialState.accountInteractionId });
		})
		.addCase(resetData, (state, action) => {
			return { ...initialState, accountInteractionId: state.accountInteractionId, ...action.payload };
		})
		.addCase(setData, (state, action) => {
			return merge(state, action.payload, { arrayMerge: arrayMergeOverwrite });
		});
});

export default profileReducer;