import { arrayMergeOverwrite } from "./common";
import { createReducer } from "@reduxjs/toolkit";
import { defaultPrivilegeMap } from "../../utils/privileges";
import merge from "deepmerge";
import { resetData, setData } from "../actions/auth";

const initialState = {
	authAccessExpiresAt: null,
	interfaceErrors: [],
	isAuthenticated: false,
	isPendingAccessVerification: false,
	isPendingSessionEnd: false,
	isPendingSessionExtension: false,
	name: null,
	privileges: defaultPrivilegeMap,
	roles: [],
	systemErrors: [],
	tokenLastVerifiedAt: null,
	userId: null
};

const authReducer = createReducer(initialState, (builder) => {
	builder
		.addCase(resetData, (state, action) => {
			return { ...initialState, ...action.payload };
		})
		.addCase(setData, (state, action) => {
			return merge(state, action.payload, { arrayMerge: arrayMergeOverwrite });
		});
});

export default authReducer;