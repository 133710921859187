import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

const Card = styled(Box)(({ theme }) => ({
	backgroundColor: theme.palette.common.white,
	border: `1px solid ${theme.palette.neutral.light}`,
	borderRadius: 8,
	boxShadow: theme.shadows[3],
	color: theme.palette.text.primary,
	marginBottom: 20,
	marginTop: 20,
	padding: 24
}));

export default Card;