import { PS_EVENT_SESSION_EXTEND } from "../config/common";
import { ps } from "../components/Application/Application";
import store from "../store";
import {
	TOKEN_CONTACT_ID,
	TOKEN_PRI,
	TOKEN_SCOPE
} from "../config/tokens";
import {
	isFunction,
	isStringOfLength,
	sendRequest,
	tokenReplacement
} from "@pheaa/channels-component-library";

const commonApiRequest = (endpoint, { contactId = null, extendSessionOnSuccess = false, headers = {}, includeAII = false, personRefId = null, scope = null, successCallback, ...rest } = {}) => {

	let commonSuccessCallback;
	const currentState = store.getState();
	const { accountInteractionId } = currentState.profile;

	if (includeAII && isStringOfLength(accountInteractionId)) {
		headers["account-interaction-id"] = accountInteractionId;
	}

	if (isStringOfLength(contactId) && endpoint.indexOf(TOKEN_CONTACT_ID) >= 0) {
		endpoint = tokenReplacement(endpoint, TOKEN_CONTACT_ID, contactId);
	}

	if (isStringOfLength(personRefId) && endpoint.indexOf(TOKEN_PRI) >= 0) {
		endpoint = tokenReplacement(endpoint, TOKEN_PRI, personRefId);
	}

	if (isStringOfLength(scope) && endpoint.indexOf(TOKEN_SCOPE) >= 0) {
		endpoint = tokenReplacement(endpoint, TOKEN_SCOPE, scope);
	}

	if (isFunction(successCallback)) {
		commonSuccessCallback = (callbackData) => {
			successCallback(callbackData);
			if (extendSessionOnSuccess) {
				ps.publish(PS_EVENT_SESSION_EXTEND);
			}
		};
	}

	return sendRequest(endpoint, { ...rest, headers, successCallback: commonSuccessCallback });
};

export default commonApiRequest;