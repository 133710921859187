import DefaultTemplate from "../templates/DefaultTemplate";
import PageTitle from "../../components/PageTitle/PageTitle";
import React from "react";
import Search from "../../components/Search/Search";
import SearchResults from "../../components/Search/SearchResults";

const SearchView = ({ title, ...props }) => {
	return (
		<DefaultTemplate>
			<PageTitle title={title} />
			<Search />
			<SearchResults />
		</DefaultTemplate>
	);
};

export default SearchView;