import { FormSelect as ImportedFormSelect } from "@pheaa/channels-component-library";

const withEnhancements = WrappedComponent => props => {
	return (
		<WrappedComponent {...props} />
	);
};

const FormSelect = withEnhancements(ImportedFormSelect);

export default FormSelect;