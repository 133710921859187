import { ERROR_MESSAGE_TYPE_DEFAULT } from "../../components/ErrorMessages/ErrorMessages";
import { IAM_SESSION_DURATION } from "../../config/auth";
import commonApiRequest from "../../utils/commonApiRequest";
import { createPrivilegesMap } from "../../utils/privileges";
import { ps } from "../../components/Application/Application";
import { setData } from "../actions/auth";
import store from "../index";
import {
	API_NSP_AUTH,
	API_NSP_OPERATION_ACCESS,
	API_NSP_OPERATION_REFRESH,
	API_NSP_PROFILE_ADMIN,
	getApiUrl
} from "../../config/api";
import {
	PS_EVENT_ACCESS_DENIED,
	PS_EVENT_ACCESS_GRANTED,
	SYSTEM_ERROR_CNX,
	SYSTEM_ERROR_INV
} from "../../config/common";
import {
	isNumber,
	isObject,
	isStringOfLength,
} from "@pheaa/channels-component-library";

const handleAuthErrors = ({ body, headers, status }) => {
	if (isNumber(status)) {
		store.dispatch(setData({ systemErrors: [{ code: status, type: ERROR_MESSAGE_TYPE_DEFAULT }] }));
	} else {
		store.dispatch(setData({ systemErrors: [{ code: SYSTEM_ERROR_INV, type: ERROR_MESSAGE_TYPE_DEFAULT }] }));
		throw new Error("Expected HTTP Error Response Code Not Received.");
	}
};

const handleAuthFailures = (error, additionalData = {}) => {
	if (isObject(error) && isStringOfLength(error.message) && error.message.toLowerCase() === "failed to fetch") {
		store.dispatch(setData({ systemErrors: [{ code: SYSTEM_ERROR_CNX, type: ERROR_MESSAGE_TYPE_DEFAULT }], ...additionalData }));
	} else {
		store.dispatch(setData({ systemErrors: [{ code: SYSTEM_ERROR_INV, type: ERROR_MESSAGE_TYPE_DEFAULT }], ...additionalData }));
	}
};

export const extendSession = () => (dispatch, getState) => {
	const { authAccessExpiresAt } = getState().auth;
	const url = getApiUrl(API_NSP_AUTH, API_NSP_OPERATION_REFRESH);
	const now = new Date();

	commonApiRequest(url, {
		alwaysCallback: () => { dispatch(setData({ isPendingSessionExtension: false })); },
		beforeCallback: () => { dispatch(setData({ systemErrors: [], isPendingSessionExtension: true })); },
		errorCallback: ({ body, headers, status }) => {
			try {
				handleAuthErrors({ body, headers, status });
			} catch (e) {
				console.error(e);
			}
		},
		failCallback: error => {
			let tokenExpiry = now;
			tokenExpiry.setSeconds(tokenExpiry.getSeconds() + IAM_SESSION_DURATION);
			handleAuthFailures(error, !isNumber(authAccessExpiresAt) ? { authAccessExpiresAt: tokenExpiry.getTime() } : null);
		},
		headers: { "Content-Type": "application/json" },
		successCallback: ({ body }) => {
			let tokenExpiry = now;
			tokenExpiry.setSeconds(tokenExpiry.getSeconds() + body.expires_in);
			dispatch(setData({ authAccessExpiresAt: tokenExpiry.getTime() }));
		}
	});
};

export const verifyAccess = () => (dispatch, getState) => {
	const url = getApiUrl(API_NSP_PROFILE_ADMIN, API_NSP_OPERATION_ACCESS);

	const handleAccessDenied = () => {
		const publishEventData = {};
		window.location.pathname !== "/" && (publishEventData.nextEvent = window.location.pathname);
		dispatch(setData({
			isAuthenticated: false,
			isPendingAccessVerification: false
		}));
		ps.publish(PS_EVENT_ACCESS_DENIED, publishEventData);
	};

	commonApiRequest(url, {
		beforeCallback: () => {
			dispatch(setData({ isPendingAccessVerification: true }));
		},
		errorCallback: () => {
			handleAccessDenied();
		},
		extendSessionOnSuccess: true,
		failCallback: () => {
			handleAccessDenied();
		},
		// TODO: Confirm the following 'roles' header is disabled in non-local environments;
		// headers: { roles: "$PACPAVW,$PACPALM,$PACPAST,$PACPAID,$PACPAPY" },
		successCallback: ({ body }) => {
			dispatch(setData({
				isAuthenticated: true,
				isPendingAccessVerification: false,
				name: body.name,
				privileges: createPrivilegesMap(body.privileges, body.roles),
				roles: body.roles,
				userId: body.userId
			}));
			ps.publish(PS_EVENT_ACCESS_GRANTED);
		}
	});
};