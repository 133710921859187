import { generateId, isArray } from "@pheaa/channels-component-library";

export const publisherSubscriber = () => {

	const _id = generateId("pub-sub");

	const _subscribers = {};

	const publish = (eventName, eventData) => {
		console.groupCollapsed("Event Published", eventName);
		if (!isArray(_subscribers[eventName])) {
			console.groupEnd();
			return;
		}
		_subscribers[eventName].forEach(({ callback, id }) => {
			callback(eventData);
		});
		console.groupEnd();
	};

	const subscribe = (eventName, callback) => {
		if (!isArray(_subscribers[eventName])) {
			_subscribers[eventName] = [];
		}
		const supscriptionId = generateId("subscription");
		_subscribers[eventName].push({ callback, id: supscriptionId });
		return supscriptionId;
	};

	const unsubscribe = (eventName, subscriptionId) => {
		if (!isArray(_subscribers[eventName])) {
			return;
		}
		const subscriptionIndex = _subscribers[eventName].findIndex(({ id }) => id === subscriptionId);

		if (subscriptionIndex > -1) {
			_subscribers[eventName].splice(subscriptionIndex, 1);
		}
	};

	return { id: _id, publish, subscribe, unsubscribe };
};