import Container from "@mui/material/Container";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import React from "react";
import {
	TEMPLATE_ID_DEFAULT,
	TEMPLATE_ID_TASK
} from "../../config/templates";

const DefaultTemplate = props => {

	const {
		children,
		isTask = false
	} = props;

	return (
		<React.Fragment>
			{!isTask && <Header />}
			<main data-template-id={isTask ? TEMPLATE_ID_TASK : TEMPLATE_ID_DEFAULT} id="main">
				<Container
					disableGutters={isTask}
					maxWidth={isTask ? "md" : "xl"}
					sx={{ marginLeft: isTask ? "auto" : 0, marginRight: isTask ? "auto" : 0, paddingBottom: 3, paddingTop: 3 }}
				>
					{children}
				</Container>
			</main>
			{!isTask && <Footer />}
		</React.Fragment>
	);
};

export default DefaultTemplate;