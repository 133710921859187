import * as profileEvents from "../../store/events/profile";
import Button from "../../components/Button/Button";
import DefaultTemplate from "../templates/DefaultTemplate";
import ErrorMessages from "../../components/ErrorMessages/ErrorMessages";
import FormActionContainer from "../../components/Form/FormActionContainer";
import FormSelect from "../../components/Form/FormSelect";
import FormTextArea from "../../components/Form/FormTextArea";
import React from "react";
import TaskHeader from "../../components/Task/TaskHeader";
import Typography from "@mui/material/Typography";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import withProfileAndAccess from "../../utils/withProfileAndAccess";
import {
	BLOCK_EVENT_BLOCK,
	BLOCK_SCOPE_ACCOUNT,
	getErrorMessageForCode
} from "../../config/common";
import {
	Form,
	format,
	isObject,
	isStringOfLength
} from "@pheaa/channels-component-library";

const ApplyWebAccessBlockView = props => {

	let formattedName = null;

	const {
		accountProfile,
		accountProfileErrorsUpdateBlocks,
		accountProfileIsUpdatingBlocks,
		createBlockEvent
	} = props;

	const { personRefId } = useParams();

	const handleSubmit = (e, formData) => {
		createBlockEvent(personRefId, BLOCK_SCOPE_ACCOUNT, {
			event: BLOCK_EVENT_BLOCK,
			reasonCode: formData.reasonCode,
			comments: formData.comments
		});
	};

	if (isObject(accountProfile) && isObject(accountProfile.name)) {
		formattedName = format.asProperName(accountProfile.name);
		if (isStringOfLength(formattedName)) {
			formattedName = (
				<React.Fragment>
					&nbsp;for <br /><b>{formattedName}</b>
				</React.Fragment>
			);
		}
	}

	return (
		<DefaultTemplate isTask={true}>
			<TaskHeader
				closeTaskDestination={`/profiles/${personRefId}`}
				title="Change Web Access"
			/>
			<Form id="defaultForm" onSubmit={handleSubmit}>
				<Typography align="center" gutterBottom={true}>
					Blocking all web access will keep user from being able to create an account, access an account, or take any action online.
				</Typography>
				<ErrorMessages
					errorMessages={accountProfileErrorsUpdateBlocks.map(error => {
						return { ...error, message: getErrorMessageForCode(error.code) };
					})}
				/>
				<FormSelect
					label="Reason for Blocking Account"
					name="reasonCode"
					options={[{
						label: "Consumer requested block",
						value: "01"
					}, {
						label: "Unauthorized access",
						value: "02"
					}]}
					required={true}
				/>
				<FormTextArea
					autoComplete="off"
					hasProgressMeter={true}
					label="Notes"
					maxLength={500}
					name="comments"
					required={true}
					rows={2}
				/>
				<Typography align="center">
					Are you sure you want to <b>Block Web Access</b>{formattedName}?
				</Typography>
				<FormActionContainer>
					<Button
						disabled={accountProfileIsUpdatingBlocks}
						processing={accountProfileIsUpdatingBlocks}
						type="submit"
						variant="contained"
					>
						Submit
					</Button>
				</FormActionContainer>
			</Form>
		</DefaultTemplate >
	);
};

const mapStateToProps = state => {
	return {
		accountProfile: state.profile.accountProfile,
		accountProfileErrorsUpdateBlocks: state.profile.accountProfileErrorsUpdateBlocks,
		accountProfileIsUpdatingBlocks: state.profile.accountProfileIsUpdatingBlocks
	};
};

const mapDispatchToProps = dispatch => {
	return {
		createBlockEvent: (personRefId, scope, data) => dispatch(profileEvents.createBlockEvent(personRefId, scope, data))
	};
};

export default withProfileAndAccess(connect(mapStateToProps, mapDispatchToProps)(ApplyWebAccessBlockView), ["updateWebAccessBlock"]);