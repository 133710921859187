import * as profileEvents from "../../store/events/profile";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Button from "../../components/Button/Button";
import DefaultTemplate from "../templates/DefaultTemplate";
import { Form } from "@pheaa/channels-component-library";
import FormActionContainer from "../../components/Form/FormActionContainer";
import React from "react";
import TaskHeader from "../../components/Task/TaskHeader";
import { connect } from "react-redux";
import withProfileAndAccess from "../../utils/withProfileAndAccess";
import { useNavigate, useParams } from "react-router-dom";

const ManageSSNRecordNotFoundView = props => {

	const { cleanUpSSNChange } = props;

	const navigate = useNavigate();
	const { personRefId } = useParams();

	const handleSubmit = (e, formData) => {
		navigate(`/profiles/${personRefId}/manage-ssn`);
	};

	return (
		<DefaultTemplate isTask={true}>
			<TaskHeader
				closeCallback={() => { cleanUpSSNChange(); }}
				closeTaskDestination={`/profiles/${personRefId}`}
				title="Edit Social Security Number"
			/>
			<Form id="defaultForm" onSubmit={handleSubmit}>
				<Alert severity="warning">
					<AlertTitle>Match Not Found</AlertTitle>
					The Social Security Number you entered does not match a core system account.
				</Alert>
				<FormActionContainer>
					<Button
						type="submit"
						variant="contained"
					>
						Return
					</Button>
				</FormActionContainer>
			</Form>
		</DefaultTemplate >
	);
};

const mapStateToProps = state => {
	return {};
};

const mapDispatchToProps = dispatch => {
	return {
		cleanUpSSNChange: () => dispatch(profileEvents.cleanUpSSNChange()),
	};
};

export default withProfileAndAccess(connect(mapStateToProps, mapDispatchToProps)(ManageSSNRecordNotFoundView), ["updateSocialSecurityNumber"]);