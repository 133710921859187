import * as profileEvents from "../../store/events/profile";
import Button from "../../components/Button/Button";
import DefaultTemplate from "../templates/DefaultTemplate";
import ErrorMessages from "../../components/ErrorMessages/ErrorMessages";
import FormActionContainer from "../../components/Form/FormActionContainer";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import React from "react";
import TaskHeader from "../../components/Task/TaskHeader";
import Typography from "@mui/material/Typography";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { useTheme } from "@mui/material";
import withProfileAndAccess from "../../utils/withProfileAndAccess";
import {
	Form,
	format,
	isArrayOfLength,
	isObject,
	isStringOfLength
} from "@pheaa/channels-component-library";
import {
	TEXT_DATA_UNAVAILABLE,
	getCommonProfileDataListStyles,
	getErrorMessageForCode
} from "../../config/common";

const AccountRecoveryView = props => {

	let dateOfBirthElem = null;
	let emailElem = null;
	let userIdElem = null;

	const {
		accountProfile,
		accountProfileErrorsUpdateRecoveryNotification,
		accountProfileIsUpdatingRecoveryNotification,
		createRecoveryNotification
	} = props;

	const { personRefId } = useParams();
	const theme = useTheme();

	const handleSubmit = (e, formData) => {
		createRecoveryNotification(personRefId, { ...formData });
	};

	if (isObject(accountProfile)) {

		if (isStringOfLength(accountProfile.userId)) {
			userIdElem = accountProfile.userId;
		} else {
			userIdElem = TEXT_DATA_UNAVAILABLE;
		}

		if (isObject(accountProfile.birth)) {
			dateOfBirthElem = format.asDate(accountProfile.birth.occurrenceDate);
			!isStringOfLength(dateOfBirthElem) && (dateOfBirthElem = TEXT_DATA_UNAVAILABLE);
		} else {
			dateOfBirthElem = TEXT_DATA_UNAVAILABLE;
		}

		if (isArrayOfLength(accountProfile.emails)) {
			let contactEmailObj = accountProfile.emails.find(email => email.id === "contact");

			if (isObject(contactEmailObj) && contactEmailObj.isValid) {
				emailElem = (
					<React.Fragment>
						{contactEmailObj.email}
						<input name="channel" type="hidden" value="email" />
						<input name="target" type="hidden" value={contactEmailObj.email} />
					</React.Fragment>
				);
			}
		}
	}

	return (
		<DefaultTemplate isTask={true}>
			<TaskHeader
				closeTaskDestination={`/profiles/${personRefId}`}
				title="Account Recovery"
			/>
			<Form id="defaultForm" onSubmit={handleSubmit}>
				<Typography align="center" component="h2" gutterBottom={true} variant="h3">
					Before Recovery
				</Typography>
				<Typography align="center" gutterBottom={true}>
					Verify the user knows their Username and DOB before sending recovery email.
				</Typography>
				<ErrorMessages
					errorMessages={accountProfileErrorsUpdateRecoveryNotification.map(error => {
						return { ...error, message: getErrorMessageForCode(error.code) };
					})}
				/>
				<List sx={getCommonProfileDataListStyles(theme)}>
					<ListItem>
						<ListItemText primary="Username:" />
						<ListItemText primary={userIdElem} />
					</ListItem>
					<ListItem>
						<ListItemText primary="DOB:" />
						<ListItemText primary={dateOfBirthElem} />
					</ListItem>
					<ListItem>
						<ListItemText primary="Recovery Requested:" />
						<ListItemText>
							<FormControl>
								<RadioGroup defaultValue="reset-password" sx={{ label: { margin: 0 } }}>
									<FormControlLabel name="type" value="recover-username" control={<Radio />} label="Username" />
									<FormControlLabel name="type" value="reset-password" control={<Radio />} label="Password" />
								</RadioGroup>
							</FormControl>
						</ListItemText>
					</ListItem>
					<ListItem>
						<ListItemText primary="Send Recovery To:" />
						<ListItemText primary={emailElem} />
					</ListItem>
				</List>
				<FormActionContainer>
					<Button
						disabled={accountProfileIsUpdatingRecoveryNotification}
						processing={accountProfileIsUpdatingRecoveryNotification}
						type="submit"
						variant="contained"
					>
						Send Recovery Email
					</Button>
				</FormActionContainer>
			</Form>
		</DefaultTemplate>
	);
};

const mapStateToProps = state => {
	return {
		accountProfile: state.profile.accountProfile,
		accountProfileErrorsUpdateRecoveryNotification: state.profile.accountProfileErrorsUpdateRecoveryNotification,
		accountProfileIsUpdatingRecoveryNotification: state.profile.accountProfileIsUpdatingRecoveryNotification
	};
};

const mapDispatchToProps = dispatch => {
	return {
		createRecoveryNotification: (personRefId, data) => dispatch(profileEvents.createRecoveryNotification(personRefId, data))
	};
};

export default withProfileAndAccess(connect(mapStateToProps, mapDispatchToProps)(AccountRecoveryView), ["updateAccountRecovery"]);