import * as ENV from "./environment";

export const API_NSP_AUTH = "auth";
export const API_NSP_ERROR_REPORTING = "error-reporting";
export const API_NSP_PROFILE_ADMIN = "profile-admin";
export const API_NSP_ACCOUNT_INTERACTION = "account-interaction";

export const API_NSP_OPERATION_ACCESS = "access";
export const API_NSP_OPERATION_SEARCH = "search";
export const API_NSP_OPERATION_VALIDATE = "validate";
export const API_NSP_OPERATION_IDENTIFIERS = "identifiers";
export const API_NSP_OPERATION_IDENTIFIERS_SYNC_ACCT = "identifiers.sync-account-number";
export const API_NSP_OPERATION_INCIDENTS = "incidents";
export const API_NSP_OPERATION_INCIDENTS_ANONYMOUS = "anonymous-incidents";
export const API_NSP_OPERATION_BLOCKS = "blocks";
export const API_NSP_OPERATION_BLOCKS_SCOPE = "blocks.scope";
export const API_NSP_OPERATION_PROFILE = "profile";
export const API_NSP_OPERATION_PROFILE_EMAILS = "profile.emails";
export const API_NSP_OPERATION_PROFILE_EMAILS_CONTACT = "profile.emails.contact";
export const API_NSP_OPERATION_PROFILE_NOTIFICATION = "profile.notification";
export const API_NSP_OPERATION_SECURITY_LOCK = "security.lock";
export const API_NSP_OPERATION_SECURITY_CONTACT_ID = "security.contact.id";
export const API_NSP_OPERATION_EVENTS = "events";
export const API_NSP_OPERATION_LOGIN = "login";
export const API_NSP_OPERATION_LOGOUT = "logout";
export const API_NSP_OPERATION_REFRESH = "refresh";


const api = {
	[`${API_NSP_PROFILE_ADMIN}.${API_NSP_OPERATION_ACCESS}`]: {
		default: {
			url: "https://admin.api.{{env}}.partners.aessuccess.org/profile-admin/v1/access"
		}
	},
	[`${API_NSP_PROFILE_ADMIN}.${API_NSP_OPERATION_SEARCH}`]: {
		default: {
			url: "https://admin.api.{{env}}.partners.aessuccess.org/profile-admin/v1/search"
		}
	},
	[`${API_NSP_PROFILE_ADMIN}.${API_NSP_OPERATION_VALIDATE}`]: {
		default: {
			url: "https://admin.api.{{env}}.partners.aessuccess.org/profile-admin/v1/persons/identifiers/validate"
		}
	},
	[`${API_NSP_PROFILE_ADMIN}.${API_NSP_OPERATION_IDENTIFIERS}`]: {
		default: {
			url: "https://admin.api.{{env}}.partners.aessuccess.org/profile-admin/v1/persons/{{personRefId}}/identifiers"
		}
	},
	[`${API_NSP_PROFILE_ADMIN}.${API_NSP_OPERATION_IDENTIFIERS_SYNC_ACCT}`]: {
		default: {
			url: "https://admin.api.{{env}}.partners.aessuccess.org/profile-admin/v1/persons/{{personRefId}}/identifiers/sync-account-number"
		}
	},
	[`${API_NSP_PROFILE_ADMIN}.${API_NSP_OPERATION_BLOCKS}`]: {
		default: {
			url: "https://admin.api.{{env}}.partners.aessuccess.org/profile-admin/v1/persons/{{personRefId}}/blocks"
		}
	},
	[`${API_NSP_PROFILE_ADMIN}.${API_NSP_OPERATION_BLOCKS_SCOPE}`]: {
		default: {
			url: "https://admin.api.{{env}}.partners.aessuccess.org/profile-admin/v1/persons/{{personRefId}}/blocks/{{scope}}"
		}
	},
	[`${API_NSP_PROFILE_ADMIN}.${API_NSP_OPERATION_PROFILE}`]: {
		default: {
			url: "https://admin.api.{{env}}.partners.aessuccess.org/profile-admin/v1/persons/{{personRefId}}/profile"
		}
	},
	[`${API_NSP_PROFILE_ADMIN}.${API_NSP_OPERATION_PROFILE_EMAILS}`]: {
		default: {
			url: "https://admin.api.{{env}}.partners.aessuccess.org/profile-admin/v1/persons/{{personRefId}}/profile/emails"
		}
	},
	[`${API_NSP_PROFILE_ADMIN}.${API_NSP_OPERATION_PROFILE_EMAILS_CONTACT}`]: {
		default: {
			url: "https://admin.api.{{env}}.partners.aessuccess.org/profile-admin/v1/persons/{{personRefId}}/profile/emails/contact"
		}
	},
	[`${API_NSP_PROFILE_ADMIN}.${API_NSP_OPERATION_PROFILE_NOTIFICATION}`]: {
		default: {
			url: "https://admin.api.{{env}}.partners.aessuccess.org/profile-admin/v1/persons/{{personRefId}}/profile/notification"
		}
	},
	[`${API_NSP_PROFILE_ADMIN}.${API_NSP_OPERATION_SECURITY_LOCK}`]: {
		default: {
			url: "https://admin.api.{{env}}.partners.aessuccess.org/profile-admin/v1/persons/{{personRefId}}/security/lock"
		}
	},
	[`${API_NSP_PROFILE_ADMIN}.${API_NSP_OPERATION_SECURITY_CONTACT_ID}`]: {
		default: {
			url: "https://admin.api.{{env}}.partners.aessuccess.org/profile-admin/v1/persons/{{personRefId}}/security/contacts/{{contactId}}"
		}
	},
	[`${API_NSP_ACCOUNT_INTERACTION}.${API_NSP_OPERATION_EVENTS}`]: {
		default: {
			url: "https://admin.api.{{env}}.partners.aessuccess.org/account-interaction/v1/persons/{{personRefId}}/events"
		}
	},
	[`${API_NSP_AUTH}.${API_NSP_OPERATION_LOGIN}`]: {
		default: {
			url: "https://am.{{env}}.partners.aessuccess.org/partnersB2Bsso/login.htm"
		}
	},
	[`${API_NSP_AUTH}.${API_NSP_OPERATION_LOGOUT}`]: {
		default: {
			url: "https://am.{{env}}.partners.aessuccess.org/partnersB2Bsso/logout.htm"
		}
	},
	[`${API_NSP_AUTH}.${API_NSP_OPERATION_PROFILE}`]: {
		default: {
			url: "https://am.{{env}}.partners.aessuccess.org/partnersB2Bsso/userProfile.shtml?resourceID=CPMAINT"
		}
	},
	[`${API_NSP_AUTH}.${API_NSP_OPERATION_REFRESH}`]: {
		default: {
			url: "https://am.{{env}}.partners.aessuccess.org/partnersB2Bsso/oauth2/refreshToken.api"
		}
	},
	[`${API_NSP_ERROR_REPORTING}.${API_NSP_OPERATION_INCIDENTS_ANONYMOUS}`]: {
		default: {
			url: "https://api.{{env}}.aessuccess.org/error-reporting/v1/anonymous-incidents"
		}
	}
};

export const getApiConfigForNspAndOperation = (nsp, nspOperation) => {
	try {
		const config = api[`${nsp.toLowerCase()}.${nspOperation.toLowerCase()}`];
		return config.hasOwnProperty(ENV.currentEnv) ? config[ENV.currentEnv] : config.default;
	} catch (e) {
		console.warn("Cannot retrieve API configuration:", e);
		return { url: false, method: false };
	}
};

export const getApiUrl = (nsp, nspOperation) => {
	try {
		const config = getApiConfigForNspAndOperation(nsp, nspOperation);
		return ENV.replaceEnvTokenInUrl(config.url);
	} catch (e) {
		console.warn("Cannot retrieve API URL:", e);
		return "";
	}
};