import * as profileEvents from "../../store/events/profile";
import Box from "@mui/material/Box";
import ErrorLoading from "../ErrorMessages/ErrorLoading";
import IconButton from "@mui/material/IconButton";
import React from "react";
import RefreshIcon from "@mui/icons-material/Refresh";
import Skeleton from "@mui/material/Skeleton";
import { Container as StyledContainer } from "../../components/Container/Container";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import {
	BUSINESS_EVENT_TYPE_WEB_APP,
	BUSINESS_EVENT_TYPE_WEB_FULL,
	BUSINESS_EVENT_TYPE_WEB_MOBILE
} from "../../config/common";
import {
	format,
	isArray,
	isArrayOfLength,
	isStringOfLength
} from "@pheaa/channels-component-library";

const commonSkeletonStyles = { transform: "scale(1, 1)" };
const currentDate = new Date();

const rowCategoryStyles = {
	backgroundColor: "#f4f5f8",
	fontSize: "1.2rem",
	fontWeight: "bold",
	position: "sticky",
	textAlign: "center",
	textTransform: "uppercase"
};

const DEFAULT_ROWS_PER_PAGE_OPTIONS = [10, 25, 50, 100];
const DEFAULT_ROWS_PER_PAGE = DEFAULT_ROWS_PER_PAGE_OPTIONS[1];

const ProfileAccountInteractionList = props => {

	let returnElem = null;

	const {
		accountInteractions,
		accountInteractionsErrors,
		accountInteractionsIsLoading,
		getAccountInteractions
	} = props;

	const { personRefId } = useParams();
	const [page, setPage] = React.useState(0);
	const [visibleAccountInteractions, setVisibleAccountInteractions] = React.useState(null);
	const [accountInteractionsPerPage, setAccountInteractionsPerPage] = React.useState(DEFAULT_ROWS_PER_PAGE);

	React.useEffect(() => {
		if (isArray(accountInteractions)) {
			const updatedAccountInteractions = accountInteractions.slice(
				0 * accountInteractionsPerPage,
				0 * accountInteractionsPerPage + accountInteractionsPerPage,
			);

			setVisibleAccountInteractions(updatedAccountInteractions);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [accountInteractions]);

	const handleChangePage = React.useCallback(
		(event, newPage) => {
			setPage(newPage);

			const updatedAccountInteractions = accountInteractions.slice(
				newPage * accountInteractionsPerPage,
				newPage * accountInteractionsPerPage + accountInteractionsPerPage,
			);

			setVisibleAccountInteractions(updatedAccountInteractions);
		},
		[accountInteractions, accountInteractionsPerPage],
	);

	const handleChangeRowsPerPage = React.useCallback(
		(event) => {
			const updatedRowsPerPage = parseInt(event.target.value, 10);
			setAccountInteractionsPerPage(updatedRowsPerPage);

			setPage(0);

			const updatedAccountInteractions = accountInteractions.slice(
				0 * updatedRowsPerPage,
				0 * updatedRowsPerPage + updatedRowsPerPage,
			);

			setVisibleAccountInteractions(updatedAccountInteractions);
		},
		[accountInteractions]
	);

	let aiList = {};
	let iterationYYYYMM = format.asDate(new Date(), { outputPattern: "yyyy/mm" });

	if (isArrayOfLength(accountInteractionsErrors)) {
		returnElem = (
			<TableRow>
				<TableCell colSpan={4}>
					<Box py={3}>
						<ErrorLoading
							alertDescription="There was an error fetching Account Interaction data."
							alertTitle="Loading Error"
							maxWidth="xs"
							primaryActionCallback={() => {
								getAccountInteractions(personRefId);
							}}
							primaryActionLabel="Retry"
						/>
					</Box>
				</TableCell>
			</TableRow>
		);
	} else {
		if (isArray(visibleAccountInteractions)) {

			if (isArrayOfLength(visibleAccountInteractions)) {

				visibleAccountInteractions.forEach(ai => {
					iterationYYYYMM = format.asDate(ai.createdOn, { outputPattern: "yyyy/mm" });
					!isArray(aiList[iterationYYYYMM]) && (aiList[iterationYYYYMM] = []);
					aiList[iterationYYYYMM].push(ai);
				});
			} else {
				aiList = {
					[iterationYYYYMM]: []
				};
			}
		} else {
			// Still loading;
			aiList = {
				[iterationYYYYMM]: (
					<TableRow>
						<TableCell><Skeleton sx={commonSkeletonStyles} width={80} /></TableCell>
						<TableCell><Skeleton sx={commonSkeletonStyles} width={80} /></TableCell>
						<TableCell><Skeleton sx={commonSkeletonStyles} width={50} /></TableCell>
						<TableCell><Skeleton sx={commonSkeletonStyles} width={200} /></TableCell>
					</TableRow>
				)
			};
		}

		returnElem = Object.keys(aiList).map((yyyyMM, i) => {

			let currentYear = currentDate.getFullYear().toString();
			let iterationYear = format.asDate(`${yyyyMM}/01`, { outputPattern: "yyyy" });

			return (
				<React.Fragment key={i}>
					<TableRow key={i}>
						<TableCell sx={rowCategoryStyles} width={currentYear !== iterationYear ? 135 : 120}>
							{format.asDate(`${yyyyMM}/01`, { outputPattern: "mm" })}
							{currentYear !== iterationYear && (`, ${iterationYear}`)}
						</TableCell>
						<TableCell colSpan={3} sx={rowCategoryStyles}></TableCell>
					</TableRow>
					{
						!isArrayOfLength(aiList[yyyyMM]) && (
							<TableRow>
								<TableCell colSpan={4}>There are no events returned for this account.</TableCell>
							</TableRow>
						)
					}
					{
						isArrayOfLength(aiList[yyyyMM]) && aiList[yyyyMM].map((ai, j) => {
							const createdOnMMDDYYYY = format.asDate(ai.createdOn, { outputPattern: "mm dd, yyyy" });
							const createdOnHHMMSS = new Date(ai.createdOn).toLocaleTimeString();
							let channelDesc = null;

							if (isStringOfLength(ai.channel)) {
								switch (ai.channel.toLowerCase()) {
									case BUSINESS_EVENT_TYPE_WEB_APP:
										channelDesc = "Mobile";
										break;
									case BUSINESS_EVENT_TYPE_WEB_FULL:
									case BUSINESS_EVENT_TYPE_WEB_MOBILE:
										channelDesc = "Portal";
										break;
									default:
										break;
								}
							}

							return (
								<TableRow key={`${i}-${j}`}>
									<TableCell aria-label={createdOnMMDDYYYY} width={120}>
										<Box sx={{ textAlign: "center" }} title={`${createdOnMMDDYYYY} ${createdOnHHMMSS}`}>
											<Typography
												component="div"
												sx={{ fontSize: "3.2rem", fontWeight: 300 }}
											>
												{format.asDate(ai.createdOn, { outputPattern: "dd" })}
											</Typography>
											<Typography
												component="div"
												sx={{ fontSize: "1.2rem" }}
											>
												{createdOnHHMMSS}
											</Typography>
										</Box>
									</TableCell>
									<TableCell width={100}>
										<Typography
											component="div"
											sx={{ fontSize: "1.2rem", fontWeight: 400 }}
										>
											{isStringOfLength(ai.agentId) ? ai.agentId : "Consumer"}
										</Typography>
										<Typography
											component="div"
											sx={{ color: "#3b4557", fontSize: "1.2rem", fontWeight: 300, textTransform: "uppercase" }}
										>
											{!ai.agentId ? channelDesc : null}
										</Typography>
									</TableCell>
									<TableCell width={100}>
										<Typography component="div" sx={{ fontSize: "1.2rem", fontWeight: 400 }}>
											{ai.code}
										</Typography>
									</TableCell>
									<TableCell>
										<Typography component="div" sx={{ fontSize: "1.2rem", fontWeight: 400 }}>
											{ai.notation}
										</Typography>
									</TableCell>
								</TableRow>
							);
						})
					}
				</React.Fragment>
			);

		});
	}

	return (
		<Box>
			<Box sx={{ alignItems: "flex-end", display: "flex", justifyContent: "space-between" }}>
				<Typography component="h2" mb={1} mt={4} variant="h4">
					Online Account History
					<IconButton
						color="primary"
						disabled={accountInteractionsIsLoading}
						onClick={() => {
							getAccountInteractions(personRefId);
						}}
						variant="outlined"
					>
						<RefreshIcon /> <span className="a11y-hidden">Reload Online Account History</span>
					</IconButton>
				</Typography>
				<TablePagination
					rowsPerPageOptions={DEFAULT_ROWS_PER_PAGE_OPTIONS}
					component="div"
					count={isArray(accountInteractions) ? accountInteractions.length : 0}
					rowsPerPage={accountInteractionsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
					sx={{ marginTop: 0 }}
				/>
			</Box>
			<TableContainer component={StyledContainer}>
				<Table sx={{ minWidth: 650 }} size="small" aria-label="Search Results">
					<TableHead>
						<TableRow>
							<TableCell>Date/Time</TableCell>
							<TableCell>User</TableCell>
							<TableCell>Event</TableCell>
							<TableCell>Details</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{returnElem}
					</TableBody>
				</Table>
			</TableContainer>
			<TablePagination
				rowsPerPageOptions={DEFAULT_ROWS_PER_PAGE_OPTIONS}
				component="div"
				count={isArray(accountInteractions) ? accountInteractions.length : 0}
				rowsPerPage={accountInteractionsPerPage}
				page={page}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
			/>
		</Box>
	);
};

const mapStateToProps = state => {
	return {
		accountInteractions: state.profile.accountInteractions,
		accountInteractionsErrors: state.profile.accountInteractionsErrors,
		accountInteractionsIsLoading: state.profile.accountInteractionsIsLoading
	};
};

const mapDispatchToProps = dispatch => {
	return {
		getAccountInteractions: data => dispatch(profileEvents.getAccountInteractions(data))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileAccountInteractionList);