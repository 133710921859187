import { FormTextArea as ImportedFormTextArea } from "@pheaa/channels-component-library";

const withEnhancements = WrappedComponent => props => {
	return (
		<WrappedComponent {...props} />
	);
};

const FormTextArea = withEnhancements(ImportedFormTextArea);

export default FormTextArea;