import React from "react";
import { connect } from "react-redux";
import { isObject } from "@pheaa/channels-component-library";
import { useNavigate, useParams } from "react-router-dom";

const mapStateToProps = state => {
	return {
		accountProfile: state.profile.accountProfile,
		privileges: state.auth.privileges
	};
};

const ProfileAndAccessRequired = connect(mapStateToProps)(props => {

	let returnElem = null;

	const {
		accountProfile,
		children,
		privileges,
		requiredPrivileges
	} = props;

	const navigate = useNavigate();
	const { personRefId } = useParams();

	const checkRequiredPrivileges = () => {
		const check = requiredPrivileges.map(privilege => privileges[privilege]);
		return !check.includes(false);
	};

	if (isObject(accountProfile) && checkRequiredPrivileges()) {
		returnElem = children;
	} else {
		navigate(`/profiles/${personRefId}`);
	}

	return returnElem;
});

const withProfileAndAccess = (WrappedComponent, requiredPrivileges = []) => props => {
	return (
		<ProfileAndAccessRequired requiredPrivileges={requiredPrivileges}>
			<WrappedComponent {...props} />
		</ProfileAndAccessRequired>
	);
};

export default withProfileAndAccess;