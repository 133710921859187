import AccessDenied from "../AccessDenied/AccessDenied";
import AccessDeniedView from "../../views/AccessDeniedView";
import AccountRecoveryView from "../../views/profile/AccountRecoveryView";
import ApplyPaymentBlockView from "../../views/profile/ApplyPaymentBlockView";
import ApplyWebAccessBlockView from "../../views/profile/ApplyWebAccessBlockView";
import BlockHistoryView from "../../views/profile/BlockHistoryView";
import DeleteOnlineAccountView from "../../views/profile/DeleteOnlineAccountView";
import ManageContactEmailView from "../../views/profile/ManageContactEmailView";
import ManageDateOfBirthView from "../../views/profile/ManageDateOfBirthView";
import ManageLegalNameView from "../../views/profile/ManageLegalNameView";
import ManageSSNConfirmView from "../../views/profile/ManageSSNConfirmView";
import ManageSSNConflictView from "../../views/profile/ManageSSNConflictView";
import ManageSSNIdentifyView from "../../views/profile/ManageSSNIdentifyView";
import ManageSSNRecordNotFoundView from "../../views/profile/ManageSSNRecordNotFoundView";
import ManageUserIdView from "../../views/profile/ManageUserIdView";
import { NoContentView } from "../../views/error";
import PendingAccessVerification from "../Loading/PendingAccessVerification";
import ProfileView from "../../views/profile/ProfileView";
import React from "react";
import RemovePaymentBlockView from "../../views/profile/RemovePaymentBlockView";
import RemoveRecoveryEmailView from "../../views/profile/RemoveRecoveryEmailView";
import RemoveTemporaryLockView from "../../views/profile/RemoveTemporaryLockView";
import RemoveWebAccessBlockView from "../../views/profile/RemoveWebAccessBlockView";
import SearchView from "../../views/search/SearchView";
import { connect } from "react-redux";
import { isStringOfLength } from "@pheaa/channels-component-library";
import withDocumentTitle from "../../utils/withDocumentTitle";
import { withRouteListener } from "../../utils/withRouteListener";
import withSessionManager from "../../utils/withSessionManager";
import {
	Outlet,
	Route,
	Routes,
	useLocation,
} from "react-router-dom";

// Remember to update /lib/consumer-profile-maintenance-routes.js with any changes to route paths;
const routeMap = [{
	route: "access-denied",
	title: "Access Denied",
	view: AccessDeniedView
}, {
	route: "",
	routeAlias: "content/consumer-profile-maintenance/aes/search.html",
	title: "Search",
	view: SearchView
}, {
	route: "profiles",
	title: "Search",
	view: SearchView
}, {
	route: "profiles/:personRefId",
	routeAlias: "content/consumer-profile-maintenance/aes/profile.html",
	title: "Profile",
	view: ProfileView
}, {
	route: "profiles/:personRefId/block-history",
	title: "Block History",
	view: BlockHistoryView
}, {
	route: "profiles/:personRefId/delete-online-account",
	title: "Delete Online Account",
	view: DeleteOnlineAccountView
}, {
	route: "profiles/:personRefId/account-recovery",
	title: "Account Recovery",
	view: AccountRecoveryView
}, {
	route: "profiles/:personRefId/apply-web-access-block",
	title: "Change Web Access",
	view: ApplyWebAccessBlockView
}, {
	route: "profiles/:personRefId/remove-web-access-block",
	title: "Change Web Access",
	view: RemoveWebAccessBlockView
}, {
	route: "profiles/:personRefId/apply-payment-block",
	title: "Change Payment Access (Web/IVR)",
	view: ApplyPaymentBlockView
}, {
	route: "profiles/:personRefId/remove-payment-block",
	title: "Change Payment Access (Web/IVR)",
	view: RemovePaymentBlockView
}, {
	route: "profiles/:personRefId/manage-lock",
	title: "Remove Temporary Lock",
	view: RemoveTemporaryLockView
}, {
	route: "profiles/:personRefId/manage-contact-email",
	title: "Edit Contact Email",
	view: ManageContactEmailView
}, {
	route: "profiles/:personRefId/manage-user-id",
	title: "Edit User ID",
	view: ManageUserIdView
}, {
	route: "profiles/:personRefId/manage-date-of-birth",
	title: "Edit Date of Birth",
	view: ManageDateOfBirthView
}, {
	route: "profiles/:personRefId/manage-legal-name",
	title: "Edit Name",
	view: ManageLegalNameView
}, {
	route: "profiles/:personRefId/manage-ssn",
	title: "Edit Social Security Number",
	view: ManageSSNIdentifyView
}, {
	route: "profiles/:personRefId/manage-ssn/conflict",
	title: "Edit Social Security Number",
	view: ManageSSNConflictView
}, {
	route: "profiles/:personRefId/manage-ssn/record-not-found",
	title: "Edit Social Security Number",
	view: ManageSSNRecordNotFoundView
}, {
	route: "profiles/:personRefId/manage-ssn/confirm",
	title: "Edit Social Security Number",
	view: ManageSSNConfirmView
}, {
	route: "profiles/:personRefId/manage-recovery-emails",
	title: "Remove a Recovery Email",
	view: RemoveRecoveryEmailView
}, {
	route: "*",
	title: "Page Not Found",
	view: NoContentView
}];

const RouteManager = props => {

	let returnElem = null;

	const {
		isAuthenticated,
		isPendingAccessVerification
	} = props;

	const location = useLocation();

	React.useEffect(() => {
		window.scrollTo(0, 0);
	}, [location]);

	const mapRoutes = () => {
		return routeMap.map(routeData => {
			const routes = [routeData.route];
			const ViewElement = withSessionManager(withRouteListener(withDocumentTitle(routeData.view, routeData.title)));
			isStringOfLength(routeData.routeAlias) && routes.push(routeData.routeAlias);
			return routes.map(route => (
				<Route
					element={<ViewElement />}
					key={route}
					path={route}
				/>
			));
		});
	};

	if (isPendingAccessVerification) {
		returnElem = (
			<Route element={<PendingAccessVerification />} path="*" />
		);
	} else if (isAuthenticated) {
		returnElem = mapRoutes();
	} else {
		returnElem = (
			<Route element={<AccessDenied />} path="*" />
		);
	}

	return (
		<Routes>
			<Route path="/*" element={<Outlet />}>
				{returnElem}
			</Route>
		</Routes>
	);
};

const mapStateToProps = state => {
	return {
		isAuthenticated: state.auth.isAuthenticated,
		isPendingAccessVerification: state.auth.isPendingAccessVerification
	};
};

export default connect(mapStateToProps)(RouteManager);