import { arrayMergeOverwrite } from "./common";
import { createReducer } from "@reduxjs/toolkit";
import merge from "deepmerge";
import { resetData, setData } from "../actions/search";

const initialState = {
	interfaceErrors: [],
	searchCategoryIndex: 0,
	searchResults: null,
	searchCriteria: {
		firstNamePartial: false,
		lastNamePartial: false
	},
	systemErrors: []
};

const searchReducer = createReducer(initialState, (builder) => {
	builder
		.addCase(resetData, (state, action) => {
			return { ...initialState, ...action.payload };
		})
		.addCase(setData, (state, action) => {
			return merge(state, action.payload, { arrayMerge: arrayMergeOverwrite });
		});
});

export default searchReducer;