import { ERROR_MESSAGE_TYPE_DEFAULT } from "../../components/ErrorMessages/ErrorMessages";
import commonApiRequest from "../../utils/commonApiRequest";
import { ps } from "../../components/Application/Application";
import { setData as setDataCommon } from "../actions/common";
import { setData as setDataSearch } from "../actions/search";
import store from "..";
import {
	API_NSP_ACCOUNT_INTERACTION,
	API_NSP_OPERATION_BLOCKS,
	API_NSP_OPERATION_BLOCKS_SCOPE,
	API_NSP_OPERATION_EVENTS,
	API_NSP_OPERATION_IDENTIFIERS,
	API_NSP_OPERATION_IDENTIFIERS_SYNC_ACCT,
	API_NSP_OPERATION_PROFILE,
	API_NSP_OPERATION_PROFILE_EMAILS_CONTACT,
	API_NSP_OPERATION_PROFILE_NOTIFICATION,
	API_NSP_OPERATION_SEARCH,
	API_NSP_OPERATION_SECURITY_CONTACT_ID,
	API_NSP_OPERATION_SECURITY_LOCK,
	API_NSP_OPERATION_VALIDATE,
	API_NSP_PROFILE_ADMIN,
	getApiUrl
} from "../../config/api";
import {
	BLOCK_EVENT_BLOCK,
	BLOCK_SCOPE_ACCOUNT,
	BLOCK_SCOPE_PAYMENT,
	CONFIRMATION_EVENT_ID_UPDATE_COMPLETE,
	INTERFACE_ERROR_DNU,
	INTERFACE_ERROR_SSN,
	PROFILE_STATUS_ACTIVE,
	PROFILE_STATUS_BLOCKED,
	PS_EVENT_SESSION_EXTEND,
	RECENT_UPDATE_KEY_CONTACT_EMAIL,
	RECENT_UPDATE_KEY_DOB,
	RECENT_UPDATE_KEY_NAME,
	RECENT_UPDATE_KEY_SSN,
	RECENT_UPDATE_KEY_USER_ID,
	SYSTEM_ERROR_CNX,
	SYSTEM_ERROR_INV,
	SYSTEM_ERROR_RSP
} from "../../config/common";
import {
	REQUEST_METHOD,
	format,
	isArray,
	isArrayOfLength,
	isNumber,
	isObject,
	isStringOfLength
} from "@pheaa/channels-component-library";
import {
	clearAccountInteractionId,
	resetData,
	setData
} from "../actions/profile";

let isBusy = false;

const handleAccountProfileResponseBody = (body, dispatch) => {
	const { accountInteractionId = null } = body.metadata;
	dispatch(setData({
		accountInteractionId,
		accountProfile: body,
		accountHasActiveWebProfile: (
			isStringOfLength(body.userId) &&
			isStringOfLength(body.status) &&
			[PROFILE_STATUS_ACTIVE, PROFILE_STATUS_BLOCKED].includes(body.status)
		)
	}));
};

const handleProfileErrors = ({ body, headers, status }, key, additionalData = {}) => {
	if (isNumber(status)) {
		store.dispatch(setData({ [key]: [{ code: status, type: ERROR_MESSAGE_TYPE_DEFAULT }], ...additionalData }));
	} else {
		store.dispatch(setData({ [key]: [{ code: SYSTEM_ERROR_INV, type: ERROR_MESSAGE_TYPE_DEFAULT }], ...additionalData }));
		throw new Error("Expected HTTP Error Response Code Not Received.");
	}
};

const handleProfileFailures = (error, key, additionalData = {}) => {
	if (isObject(error) && isStringOfLength(error.message) && error.message.toLowerCase() === "failed to fetch") {
		store.dispatch(setData({ [key]: [{ code: SYSTEM_ERROR_CNX, type: ERROR_MESSAGE_TYPE_DEFAULT }], ...additionalData }));
	} else {
		store.dispatch(setData({ [key]: [{ code: SYSTEM_ERROR_INV, type: ERROR_MESSAGE_TYPE_DEFAULT }], ...additionalData }));
	}
};

export const getAccountBlockHistory = personRefId => (dispatch, getState) => {
	const url = getApiUrl(API_NSP_PROFILE_ADMIN, API_NSP_OPERATION_BLOCKS);

	return commonApiRequest(url, {
		alwaysCallback: () => { dispatch(setData({ accountBlockHistoryIsLoading: false })); },
		beforeCallback: () => { dispatch(setData({ accountBlockHistoryErrors: [], accountBlockHistoryIsLoading: true })); },
		errorCallback: ({ body, headers, status }) => {
			try {
				handleProfileErrors({ body, headers, status }, "accountBlockHistoryErrors");
			} catch (e) {
				console.error(e);
			}
		},
		extendSessionOnSuccess: false, // This is false because the bulk handler getAccountData handles;
		failCallback: error => { handleProfileFailures(error, "accountBlockHistoryErrors"); },
		includeAII: true,
		personRefId,
		successCallback: ({ body }) => {

			if (isObject(body) && isArray(body.blocks)) {
				dispatch(setData({ accountBlockHistory: body.blocks }));
			} else {
				dispatch(setData({ accountBlockHistoryErrors: [{ code: SYSTEM_ERROR_RSP, type: ERROR_MESSAGE_TYPE_DEFAULT }] }));
			}
		}
	});
};

export const getAccountProfile = personRefId => (dispatch, getState) => {
	const url = getApiUrl(API_NSP_PROFILE_ADMIN, API_NSP_OPERATION_PROFILE);

	return commonApiRequest(url, {
		alwaysCallback: () => { dispatch(setData({ accountProfileIsLoading: false })); },
		beforeCallback: () => { dispatch(setData({ accountProfileErrors: [], accountProfileIsLoading: true })); },
		errorCallback: ({ body, headers, status }) => {
			try {
				handleProfileErrors({ body, headers, status }, "accountProfileErrors");
			} catch (e) {
				console.error(e);
			}
		},
		extendSessionOnSuccess: false, // This is false because the bulk handler getAccountData handles;
		failCallback: error => { handleProfileFailures(error, "accountProfileErrors"); },
		includeAII: true,
		personRefId,
		successCallback: ({ body }) => {
			if (isObject(body)) {
				handleAccountProfileResponseBody(body, dispatch);
			} else {
				dispatch(setData({ accountProfileErrors: [{ code: SYSTEM_ERROR_RSP, type: ERROR_MESSAGE_TYPE_DEFAULT }] }));
			}
		}
	});
};

export const getAccountInteractions = personRefId => (dispatch, getState) => {

	const url = getApiUrl(API_NSP_ACCOUNT_INTERACTION, API_NSP_OPERATION_EVENTS);

	return commonApiRequest(url, {
		alwaysCallback: () => { dispatch(setData({ accountInteractionsIsLoading: false })); },
		beforeCallback: () => { dispatch(setData({ accountInteractionsErrors: [], accountInteractionsIsLoading: true })); },
		errorCallback: ({ body, headers, status }) => {
			try {
				handleProfileErrors({ body, headers, status }, "accountInteractionsErrors");
			} catch (e) {
				console.error(e);
			}
		},
		extendSessionOnSuccess: false, // This is false because the bulk handler getAccountData handles;
		failCallback: error => { handleProfileFailures(error, "accountInteractionsErrors"); },
		includeAII: true,
		personRefId,
		successCallback: ({ body }) => {

			if (isObject(body) && isArray(body.events)) {
				// CCPA-7510, filter to include only Web Business Events;
				let filteredEvents = body.events.filter(ai => {
					return /^web/i.test(ai.channel) || /^WB/i.test(ai.code);
				});
				dispatch(setData({ accountInteractions: filteredEvents }));
			} else {
				dispatch(setData({ accountInteractionsErrors: [{ code: SYSTEM_ERROR_RSP, type: ERROR_MESSAGE_TYPE_DEFAULT }] }));
			}
		}
	});
};

export const getAccountData = (personRefId, force = false) => (dispatch, getState) => {
	const { accountProfile } = getState().profile;
	let isProfileAvailable = false;

	if (isObject(accountProfile) && isStringOfLength(accountProfile.id)) {
		isProfileAvailable = (accountProfile.id === personRefId);
	}

	if (!isProfileAvailable) {
		dispatch(clearAccountInteractionId());
	}

	if ((force && !isBusy) || (!isProfileAvailable && !isBusy)) {
		dispatch(resetData({}));

		isBusy = true;

		Promise.all([
			dispatch(getAccountBlockHistory(personRefId)),
			dispatch(getAccountInteractions(personRefId)),
			dispatch(getAccountProfile(personRefId))
		]).then(() => {
			ps.publish(PS_EVENT_SESSION_EXTEND);
			isBusy = false;
		});
	}
};

export const createBlockEvent = (personRefId, scope, data) => (dispatch, getState) => {
	const { accountProfile } = getState().profile;
	const url = getApiUrl(API_NSP_PROFILE_ADMIN, API_NSP_OPERATION_BLOCKS_SCOPE);

	commonApiRequest(url, {
		alwaysCallback: () => { dispatch(setData({ accountProfileIsUpdatingBlocks: false })); },
		beforeCallback: () => { dispatch(setData({ accountProfileErrorsUpdateBlocks: [], accountProfileIsUpdatingBlocks: true })); },
		data,
		errorCallback: ({ body, headers, status }) => {
			try {
				handleProfileErrors({ body, headers, status }, "accountProfileErrorsUpdateBlocks");
			} catch (e) {
				console.error(e);
			}
		},
		extendSessionOnSuccess: true,
		failCallback: error => { handleProfileFailures(error, "accountProfileErrorsUpdateBlocks"); },
		includeAII: true,
		method: REQUEST_METHOD.PUT,
		personRefId,
		scope,
		successCallback: ({ body }) => {
			// If response body is an object;
			if (isObject(body)) {
				const { name } = accountProfile;
				const formattedName = format.asProperName(name);
				let isEventBlock = data.event === BLOCK_EVENT_BLOCK;
				let message = isStringOfLength(formattedName) ? ` for ${formattedName}` : "";
				// Set message based on scope and availability of proper name;
				if (scope === BLOCK_SCOPE_ACCOUNT) {
					message = isEventBlock ? `Web access blocked${message}.` : `Web access unblocked${message}.`;
				} else if (scope === BLOCK_SCOPE_PAYMENT) {
					message = isEventBlock ? `Payments (Web/IVR) blocked${message}.` : `Payments (Web/IVR) unblocked${message}.`;
				}
				// Set common data to define targetPathname, forcing navigation;
				dispatch(setDataCommon({ targetPathname: `/profiles/${personRefId}` }));
				// Forcibly reload profile dependencies;
				dispatch(getAccountData(personRefId, true));
				// Dispatch action to set confirmationEvent in store;
				dispatch(setData({ confirmationEvent: { id: CONFIRMATION_EVENT_ID_UPDATE_COMPLETE, message } }));
			} else {
				dispatch(setData({ accountProfileErrorsUpdateBlocks: [{ code: SYSTEM_ERROR_RSP, type: ERROR_MESSAGE_TYPE_DEFAULT }] }));
			}
		}
	});
};

export const createRecoveryNotification = (personRefId, data) => (dispatch, getState) => {
	const url = getApiUrl(API_NSP_PROFILE_ADMIN, API_NSP_OPERATION_PROFILE_NOTIFICATION);

	commonApiRequest(url, {
		alwaysCallback: () => { dispatch(setData({ accountProfileIsUpdatingRecoveryNotification: false })); },
		beforeCallback: () => { dispatch(setData({ accountProfileErrorsUpdateRecoveryNotification: [], accountProfileIsUpdatingRecoveryNotification: true })); },
		data,
		errorCallback: ({ body, headers, status }) => {
			try {
				handleProfileErrors({ body, headers, status }, "accountProfileErrorsUpdateRecoveryNotification");
			} catch (e) {
				console.error(e);
			}
		},
		extendSessionOnSuccess: true,
		failCallback: error => { handleProfileFailures(error, "accountProfileErrorsUpdateRecoveryNotification"); },
		includeAII: true,
		method: REQUEST_METHOD.POST,
		personRefId,
		successCallback: ({ body }) => {
			if (isObject(body)) {
				// Define message;
				let message = `Recovery email sent to ${data.target}.`;
				// Set common data to define targetPathname, forcing navigation;
				dispatch(setDataCommon({ targetPathname: `/profiles/${personRefId}` }));
				// Reload account interactions dependency;
				dispatch(getAccountInteractions(personRefId));
				// Dispatch action to set confirmationEvent in store;
				dispatch(setData({ confirmationEvent: { id: CONFIRMATION_EVENT_ID_UPDATE_COMPLETE, message } }));
			} else {
				dispatch(setData({ accountProfileErrorsUpdateRecoveryNotification: [{ code: SYSTEM_ERROR_RSP, type: ERROR_MESSAGE_TYPE_DEFAULT }] }));
			}
		}
	});
};

export const deleteOnlineAccount = personRefId => (dispatch, getState) => {
	const currentState = getState();
	const url = getApiUrl(API_NSP_PROFILE_ADMIN, API_NSP_OPERATION_PROFILE);
	const { searchResults } = currentState.search;
	const { accountProfile } = currentState.profile;

	return commonApiRequest(url, {
		alwaysCallback: () => { dispatch(setData({ accountProfileIsDeleting: false })); },
		beforeCallback: () => { dispatch(setData({ accountProfileErrorsDelete: [], accountProfileIsDeleting: true })); },
		errorCallback: ({ body, headers, status }) => {
			try {
				handleProfileErrors({ body, headers, status }, "accountProfileErrorsDelete");
			} catch (e) {
				console.error(e);
			}
		},
		extendSessionOnSuccess: true,
		failCallback: error => { handleProfileFailures(error, "accountProfileErrorsDelete"); },
		includeAII: true,
		method: REQUEST_METHOD.DELETE,
		personRefId,
		successCallback: ({ status }) => {
			if (status === 204 || status === 205) {
				// Define confirmation message;
				let message = "Web account deleted.";
				// If profile exists and has name object;
				if (isObject(accountProfile) && isObject(accountProfile.name)) {
					// Define formatted name;
					let formattedName = format.asProperName(accountProfile.name);
					// Update message to include formatted name when available;
					isStringOfLength(formattedName) && (message = `Web account deleted for ${formattedName}.`);
				}
				// Default status;
				if (status === 204) {
					// Set common data to define targetPathname, forcing navigation;
					dispatch(setDataCommon({ targetPathname: `/profiles/${personRefId}` }));
					// Forcibly reload profile dependencies;
					dispatch(getAccountData(personRefId, true));
					// 205 response indicates web account deleted, no core system match, records cleaned up (ie. deleting web-only account);
				} else if (status === 205) {
					// Set common data to define targetPathname, forcing navigation;
					dispatch(setDataCommon({ targetPathname: "/profiles" }));
					// If there are search results;
					if (isObject(searchResults) && isArrayOfLength(searchResults.users)) {
						// Destructure user count and users from search results;
						let { userCount, users } = searchResults;
						// Filter out user whose profile was just deleted;
						users = users.filter(user => user.personRefId !== personRefId);
						// Update user count;
						userCount = users.length;
						// Dispatch action to update search results data;
						dispatch(setDataSearch({ searchResults: { ...searchResults, userCount, users } }));
					}
				}
				// Dispatch action to set confirmationEvent in store;
				dispatch(setData({ confirmationEvent: { id: CONFIRMATION_EVENT_ID_UPDATE_COMPLETE, message } }));
			} else {
				dispatch(setData({ accountProfileErrorsDelete: [{ code: SYSTEM_ERROR_RSP, type: ERROR_MESSAGE_TYPE_DEFAULT }] }));
			}
		}
	});
};

export const deleteRecoveryMethodByContactId = (personRefId, contactId) => (dispatch, getState) => {
	const { accountProfile } = getState().profile;
	const url = getApiUrl(API_NSP_PROFILE_ADMIN, API_NSP_OPERATION_SECURITY_CONTACT_ID);

	commonApiRequest(url, {
		alwaysCallback: () => { dispatch(setData({ accountProfileIsDeletingRecoveryMethod: false })); },
		beforeCallback: () => { dispatch(setData({ accountProfileErrorsDeleteRecoveryMethod: [], accountProfileIsDeletingRecoveryMethod: true })); },
		errorCallback: ({ body, headers, status }) => {
			try {
				handleProfileErrors({ body, headers, status }, "accountProfileErrorsDeleteRecoveryMethod");
			} catch (e) {
				console.error(e);
			}
		},
		extendSessionOnSuccess: true,
		failCallback: error => { handleProfileFailures(error, "accountProfileErrorsDeleteRecoveryMethod"); },
		contactId,
		includeAII: true,
		method: REQUEST_METHOD.DELETE,
		personRefId,
		successCallback: ({ status }) => {
			if (status === 204) {
				// Define message;
				let message = "Recovery method removed.";
				// Define removed recovery method;
				let removedRecoveryMethod = accountProfile.recovery.find(recoveryMethod => recoveryMethod.id === contactId);
				let removedRecoveryMethodType = "";
				let removedRecoveryMethodValue = "";
				// Set recovery method type and value from removedRecoveryMethod object;
				if (removedRecoveryMethod.hasOwnProperty("email")) {
					removedRecoveryMethodType = "email";
					removedRecoveryMethodValue = removedRecoveryMethod.email;
				} else if (removedRecoveryMethod.hasOwnProperty("phone")) {
					removedRecoveryMethodType = "phone number";
					removedRecoveryMethodValue = format.asPhone(removedRecoveryMethod.phone.number, { outputPattern: "###-###-####", separator: "-" });
				}
				// If type and value are strings of length;
				if (isStringOfLength(removedRecoveryMethodType) && isStringOfLength(removedRecoveryMethodValue)) {
					// Set message;
					message = `Recovery ${removedRecoveryMethodType} ${removedRecoveryMethodValue} removed.`;
				}
				// Set common data to define targetPathname, forcing navigation;
				dispatch(setDataCommon({ targetPathname: `/profiles/${personRefId}` }));
				// Forcibly reload profile dependencies;
				dispatch(getAccountData(personRefId, true));
				// Dispatch action to set confirmationEvent in store;
				dispatch(setData({ confirmationEvent: { id: CONFIRMATION_EVENT_ID_UPDATE_COMPLETE, message } }));
			} else {
				dispatch(setData({ accountProfileErrorsDeleteRecoveryMethod: [{ code: SYSTEM_ERROR_RSP, type: ERROR_MESSAGE_TYPE_DEFAULT }] }));
			}
		}
	});
};

export const deleteTemporaryLock = personRefId => (dispatch, getState) => {
	const { accountProfile } = getState().profile;
	const url = getApiUrl(API_NSP_PROFILE_ADMIN, API_NSP_OPERATION_SECURITY_LOCK);

	commonApiRequest(url, {
		alwaysCallback: () => { dispatch(setData({ accountProfileIsDeletingTempLock: false })); },
		beforeCallback: () => { dispatch(setData({ accountProfileErrorsDeleteTempLock: [], accountProfileIsDeletingTempLock: true })); },
		errorCallback: ({ body, headers, status }) => {
			try {
				handleProfileErrors({ body, headers, status }, "accountProfileErrorsDeleteTempLock");
			} catch (e) {
				console.error(e);
			}
		},
		extendSessionOnSuccess: true,
		failCallback: error => { handleProfileFailures(error, "accountProfileErrorsDeleteTempLock"); },
		includeAII: true,
		method: REQUEST_METHOD.DELETE,
		personRefId,
		successCallback: ({ status }) => {
			if (status === 204) {
				// Destructure name object from accountProfile;
				const { name } = accountProfile;
				// Set message to formatted name;
				let message = format.asProperName(name);
				// Set message depending on existing value;
				message = isStringOfLength(message) ? `Temporary Lock Removed for ${message}.` : "Temporary Lock Removed.";
				// Set common data to define targetPathname, forcing navigation;
				dispatch(setDataCommon({ targetPathname: `/profiles/${personRefId}` }));
				// Forcibly reload profile dependencies;
				dispatch(getAccountData(personRefId, true));
				// Dispatch action to set confirmationEvent in store;
				dispatch(setData({ confirmationEvent: { id: CONFIRMATION_EVENT_ID_UPDATE_COMPLETE, message } }));
			} else {
				dispatch(setData({ accountProfileErrorsDeleteTempLock: [{ code: SYSTEM_ERROR_RSP, type: ERROR_MESSAGE_TYPE_DEFAULT }] }));
			}
		}
	});
};

export const updateAccountProfile = (personRefId, data) => (dispatch, getState) => {

	let isUpdatingDOBAllowed = false;
	let isUpdatingNameAllowed = false;

	const { profile } = getState();
	const { accountProfile } = profile;
	const url = getApiUrl(API_NSP_PROFILE_ADMIN, API_NSP_OPERATION_PROFILE);
	const isUpdatingDOB = isObject(data.birth);
	const isUpdatingName = isObject(data.name);
	const isUpdatingUserId = isStringOfLength(data.userId);

	if (isObject(accountProfile)) {
		if (isObject(accountProfile.name) && isObject(accountProfile.name.metadata)) {
			isUpdatingNameAllowed = accountProfile.name.metadata.isUpdatable;
		}

		if (isObject(accountProfile.birth) & isObject(accountProfile.birth.metadata)) {
			isUpdatingDOBAllowed = accountProfile.birth.metadata.isUpdatable;
		}
	}

	if (
		(isUpdatingDOB && isUpdatingDOBAllowed) ||
		(isUpdatingName && isUpdatingNameAllowed) ||
		(isUpdatingUserId)
	) {
		commonApiRequest(url, {
			alwaysCallback: () => { dispatch(setData({ accountProfileIsUpdating: false })); },
			beforeCallback: () => { dispatch(setData({ accountProfileErrorsUpdate: [], accountProfileIsUpdating: true })); },
			data,
			errorCallback: ({ body, headers, status }) => {
				try {
					handleProfileErrors({ body, headers, status }, "accountProfileErrorsUpdate");
				} catch (e) {
					console.error(e);
				}
			},
			extendSessionOnSuccess: true,
			failCallback: error => { handleProfileFailures(error, "accountProfileErrorsUpdate"); },
			includeAII: true,
			method: REQUEST_METHOD.PATCH,
			personRefId,
			successCallback: ({ body }) => {

				if (isObject(body)) {
					let recentUpdates = [];
					let now = new Date().getTime();

					handleAccountProfileResponseBody(body, dispatch);

					isUpdatingDOB && recentUpdates.push({ id: RECENT_UPDATE_KEY_DOB, updatedAt: now });
					isUpdatingName && recentUpdates.push({ id: RECENT_UPDATE_KEY_NAME, updatedAt: now });
					isUpdatingUserId && recentUpdates.push({ id: RECENT_UPDATE_KEY_USER_ID, updatedAt: now });

					if (isObject(body.name)) {
						let message = "";

						if (isUpdatingDOB) {
							isStringOfLength(message) && (message = `${message} `);
							message = `${message}Date of Birth updated to ${format.asDate(body.birth.occurrenceDate)}.`;
						}

						if (isUpdatingName) {
							isStringOfLength(message) && (message = `${message} `);
							message = `${message}Name updated to ${format.asProperName(body.name)}.`;
						}

						if (isUpdatingUserId) {
							isStringOfLength(message) && (message = `${message} `);
							message = `${message}Username updated to ${body.userId}.`;
						}

						// Set common data to define targetPathname, forcing navigation;
						dispatch(setDataCommon({ targetPathname: `/profiles/${personRefId}` }));
						// If updating DOB or Name, user may have conflict and need fresh profile data;
						if (isUpdatingDOB || isUpdatingName) {
							// Forcibly reload profile dependencies;
							dispatch(getAccountData(personRefId, true));
						} else {
							// Reload account interactions dependency;
							dispatch(getAccountInteractions(personRefId));
						}
						// Dispatch action to set confirmationEvent in store, set recentUpdates datetimes;
						dispatch(setData({ confirmationEvent: { id: CONFIRMATION_EVENT_ID_UPDATE_COMPLETE, message }, recentUpdates }));
					} else {
						dispatch(setData({ accountProfileErrorsUpdate: [{ code: SYSTEM_ERROR_RSP, type: ERROR_MESSAGE_TYPE_DEFAULT }] }));
					}
				} else {
					dispatch(setData({ accountProfileErrorsUpdate: [{ code: SYSTEM_ERROR_RSP, type: ERROR_MESSAGE_TYPE_DEFAULT }] }));
				}
			}
		});
	} else {
		dispatch(setData({ accountProfileErrorsUpdate: [{ code: INTERFACE_ERROR_DNU, type: ERROR_MESSAGE_TYPE_DEFAULT }] }));
	}
};

export const updateAccountProfileContactEmail = (personRefId, data) => (dispatch, getState) => {
	const url = getApiUrl(API_NSP_PROFILE_ADMIN, API_NSP_OPERATION_PROFILE_EMAILS_CONTACT);

	commonApiRequest(url, {
		alwaysCallback: () => { dispatch(setData({ accountProfileIsUpdatingContactEmail: false })); },
		beforeCallback: () => { dispatch(setData({ accountProfileErrorsUpdateContactEmail: [], accountProfileIsUpdatingContactEmail: true })); },
		data,
		errorCallback: ({ body, headers, status }) => {
			try {
				handleProfileErrors({ body, headers, status }, "accountProfileErrorsUpdateContactEmail");
			} catch (e) {
				console.error(e);
			}
		},
		extendSessionOnSuccess: true,
		failCallback: error => { handleProfileFailures(error, "accountProfileErrorsUpdateContactEmail"); },
		includeAII: true,
		method: REQUEST_METHOD.PATCH,
		personRefId,
		successCallback: ({ body }) => {
			if (isObject(body) && isStringOfLength(body.email)) {
				// Define message;
				let message = `Contact email updated to ${body.email}.`;
				// Let recentUpdates array be defined;
				let recentUpdates = [{ id: RECENT_UPDATE_KEY_CONTACT_EMAIL, updatedAt: new Date().getTime() }];
				// Set common data to define targetPathname, forcing navigation;
				dispatch(setDataCommon({ targetPathname: `/profiles/${personRefId}` }));
				// Forcibly reload profile dependencies;
				dispatch(getAccountData(personRefId, true));
				// Dispatch action to set confirmationEvent in store;
				dispatch(setData({ confirmationEvent: { id: CONFIRMATION_EVENT_ID_UPDATE_COMPLETE, message }, recentUpdates }));
			} else {
				dispatch(setData({ accountProfileErrorsUpdateContactEmail: [{ code: SYSTEM_ERROR_RSP, type: ERROR_MESSAGE_TYPE_DEFAULT }] }));
			}
		}
	});
};

export const syncAccountNumber = (personRefId, data) => (dispatch, getState) => {
	const url = getApiUrl(API_NSP_PROFILE_ADMIN, API_NSP_OPERATION_IDENTIFIERS_SYNC_ACCT);

	commonApiRequest(url, {
		alwaysCallback: () => { dispatch(setData({ accountProfileIsUpdatingAccountNumber: false })); },
		beforeCallback: () => { dispatch(setData({ accountProfileErrorsUpdateAccountNumber: [], accountProfileIsUpdatingAccountNumber: true })); },
		data,
		errorCallback: ({ body, headers, status }) => {
			try {
				handleProfileErrors({ body, headers, status }, "accountProfileErrorsUpdateAccountNumber");
				// Dispatch action to set confirmationEvent in store;
				dispatch(setData({ confirmationEvent: { id: CONFIRMATION_EVENT_ID_UPDATE_COMPLETE, message: "Account Number Synchronization Failed.", severity: "error" } }));
			} catch (e) {
				console.error(e);
			}
		},
		extendSessionOnSuccess: true,
		failCallback: error => { handleProfileFailures(error, "accountProfileErrorsUpdateAccountNumber"); },
		includeAII: true,
		method: REQUEST_METHOD.PATCH,
		personRefId,
		successCallback: ({ body }) => {
			if (isObject(body) && isStringOfLength(body.ssn) && isStringOfLength(body.accountNumber)) {
				// Dispatch action to update accountProfile with SSN & Account Number from response body;
				dispatch(setData({ accountProfile: { ...getState().profile.accountProfile, ...body } }));
				// Reload account interactions dependency;
				dispatch(getAccountInteractions(personRefId));
				// Dispatch action to set confirmationEvent in store;
				dispatch(setData({ confirmationEvent: { id: CONFIRMATION_EVENT_ID_UPDATE_COMPLETE, message: "Account Number Synchronized." } }));
			} else {
				dispatch(setData({ accountProfileErrorsUpdateAccountNumber: [{ code: SYSTEM_ERROR_RSP, type: ERROR_MESSAGE_TYPE_DEFAULT }] }));
			}
		}
	});
};

export const verifySSNChange = ssn => (dispatch, getState) => {
	const { accountProfile } = getState().profile;
	const urlValidateSSN = getApiUrl(API_NSP_PROFILE_ADMIN, API_NSP_OPERATION_VALIDATE);
	const urlGetConflictingAccounts = getApiUrl(API_NSP_PROFILE_ADMIN, API_NSP_OPERATION_SEARCH);

	ssn = ssn.replace(/\D/g, "");
	// If provided SSN matches existing;
	if (ssn === accountProfile.ssn) {
		// Dispatch action to set custom interface error;
		dispatch(setData({ accountProfileErrorsValidPersonIdentifier: [{ code: INTERFACE_ERROR_SSN, type: ERROR_MESSAGE_TYPE_DEFAULT }] }));
		return;
	}

	commonApiRequest(urlValidateSSN, {
		alwaysCallback: () => { dispatch(setData({ accountProfileIsValidatingPersonIdentifier: false })); },
		beforeCallback: () => { dispatch(setData({ accountProfileValidPersonIdentifier: null, accountProfileErrorsValidPersonIdentifier: [], accountProfileIsValidatingPersonIdentifier: true })); },
		data: { ssn },
		errorCallback: ({ body, headers, status }) => {
			try {
				handleProfileErrors({ body, headers, status }, "accountProfileErrorsValidPersonIdentifier");
			} catch (e) {
				console.error(e);
			}
		},
		extendSessionOnSuccess: false, // This is false because successCallback includes additional commonApiRequest;
		failCallback: error => { handleProfileFailures(error, "accountProfileErrorsValidPersonIdentifier"); },
		includeAII: true,
		method: REQUEST_METHOD.POST,
		successCallback: ({ body }) => {
			if (isObject(body)) {
				// Define identifier validity from body;
				const isIdentifierValid = body.isValid;
				// Dispatch action to update accountProfileValidPersonIdentifier;
				dispatch(setData({ accountProfileNewSSN: ssn, accountProfileValidPersonIdentifier: body }));

				commonApiRequest(urlGetConflictingAccounts, {
					alwaysCallback: () => { dispatch(setData({ accountProfileIsCheckingConflict: false })); },
					beforeCallback: () => { dispatch(setData({ accountProfileConflict: null, accountProfileErrorsConflict: [], accountProfileIsCheckingConflict: true })); },
					data: { ssn },
					errorCallback: ({ body, headers, status }) => {
						try {
							handleProfileErrors({ body, headers, status }, "accountProfileErrorsConflict");
						} catch (e) {
							console.error(e);
						}
					},
					extendSessionOnSuccess: true,
					failCallback: error => { handleProfileFailures(error, "accountProfileErrorsConflict"); },
					includeAII: true,
					method: REQUEST_METHOD.POST,
					successCallback: ({ body }) => {

						if (isObject(body)) {
							// Destructure accountProfile from profile store;
							const { accountProfile } = getState().profile;
							// Define conflicting users, excluding
							const conflictingUsers = isArrayOfLength(body.users) ? body.users.filter(user => user.accountNumber !== accountProfile.accountNumber) : [];
							// Define targetPathname from  conflictingUsers array and identifier validitity (order is important here, as accounts could conflict and not be valid);
							const targetPathname = `/profiles/${accountProfile.id}/manage-ssn/${conflictingUsers.length > 0 ? "conflict" : (isIdentifierValid ? "confirm" : "record-not-found")}`;
							// Dispatch action to update accountProfileConflict;
							dispatch(setData({ accountProfileConflict: { ...body, userCount: conflictingUsers.length, users: conflictingUsers } }));
							// Set common data to define targetPathname, forcing navigation;
							dispatch(setDataCommon({ targetPathname }));
						} else {
							dispatch(setData({ accountProfileErrorsConflict: [{ code: SYSTEM_ERROR_RSP, type: ERROR_MESSAGE_TYPE_DEFAULT }] }));
						}
					}
				});
			} else {
				dispatch(setData({ accountProfileErrorsValidPersonIdentifier: [{ code: SYSTEM_ERROR_RSP, type: ERROR_MESSAGE_TYPE_DEFAULT }] }));
			}
		}
	});
};

export const updateSocialSecurityNumber = (personRefId, ssn) => (dispatch, getState) => {
	const url = getApiUrl(API_NSP_PROFILE_ADMIN, API_NSP_OPERATION_IDENTIFIERS);

	commonApiRequest(url, {
		alwaysCallback: () => { dispatch(setData({ accountProfileIsUpdatingSSN: false })); },
		beforeCallback: () => { dispatch(setData({ accountProfileErrorsUpdateSSN: [], accountProfileIsUpdatingSSN: true })); },
		data: { ssn },
		errorCallback: ({ body, headers, status }) => {
			try {
				handleProfileErrors({ body, headers, status }, "accountProfileErrorsUpdateSSN");
			} catch (e) {
				console.error(e);
			}
		},
		extendSessionOnSuccess: true,
		failCallback: error => { handleProfileFailures(error, "accountProfileErrorsUpdateSSN"); },
		includeAII: true,
		method: REQUEST_METHOD.PATCH,
		personRefId,
		successCallback: ({ body }) => {
			if (isObject(body)) {
				// Define message;
				let message = `Social Security Number changed to ${format.asSocial(ssn)}.`;
				// Let recentUpdates array be defined;
				let recentUpdates = [{ id: RECENT_UPDATE_KEY_SSN, updatedAt: new Date().getTime() }];
				// First, dispatch action to nullify accountInteractionId as SSN has changed;
				dispatch(setData({ accountInteractionId: null }));
				// Then, set common data to define targetPathname, forcing navigation;
				dispatch(setDataCommon({ targetPathname: `/profiles/${personRefId}` }));
				// Then clean up SSN change process;
				dispatch(cleanUpSSNChange());
				// Then, forcibly reload profile dependencies (with accountInteractionId nullified);
				dispatch(getAccountData(personRefId, true));
				// Dispatch action to set confirmationEvent in store;
				dispatch(setData({ confirmationEvent: { id: CONFIRMATION_EVENT_ID_UPDATE_COMPLETE, message }, recentUpdates }));
			} else {
				dispatch(setData({ accountProfileErrorsUpdateSSN: [{ code: SYSTEM_ERROR_RSP, type: ERROR_MESSAGE_TYPE_DEFAULT }] }));
			}
		}
	});
};

export const cleanUpSSNChange = () => (dispatch, getState) => {
	dispatch(setData({
		accountProfileConflict: null,
		accountProfileErrorsConflict: [],
		accountProfileErrorsUpdateSSN: [],
		accountProfileErrorsValidPersonIdentifier: [],
		accountProfileNewSSN: null,
		accountProfileValidPersonIdentifier: null
	}));
};