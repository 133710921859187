import * as profileEvents from "../../store/events/profile";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "../Button/Button";
import CakeIcon from "@mui/icons-material/Cake";
import ErrorLoading from "../ErrorMessages/ErrorLoading";
import Grid from "@mui/material/Grid";
import React from "react";
import Skeleton from "@mui/material/Skeleton";
import { Container as StyledContainer } from "../../components/Container/Container";
import Typography from "@mui/material/Typography";
import { connect } from "react-redux";
import { styled } from "@mui/material/styles";
import { useTheme } from "@mui/material";
import { RECENT_UPDATE_KEY_CONTACT_EMAIL, RECENT_UPDATE_KEY_DOB, RECENT_UPDATE_KEY_NAME, RECENT_UPDATE_KEY_SSN, RECENT_UPDATE_KEY_USER_ID, RECENT_UPDATE_THRESHOLD, TEXT_DATA_UNAVAILABLE, currentDate } from "../../config/common";
import {
	format,
	isArray,
	isArrayOfLength,
	isNumber,
	isObject,
	isStringOfLength
} from "@pheaa/channels-component-library";
import { useNavigate, useParams } from "react-router-dom";


const GridRow = styled(Grid)(({ theme }) => ({
	borderTop: `1px solid ${theme.palette.divider}`,
	marginTop: 4,
	paddingBottom: "1.2rem",
	paddingTop: "1.4rem",
	":last-of-type": {
		borderBottom: `1px solid ${theme.palette.divider}`
	}
}));

const GridRowItem = styled(Grid)(({ theme }) => ({
	alignItems: "center",
	display: "flex",
	":last-child": {
		justifyContent: "flex-end"
	}
}));

const InlineAlert = styled(Alert)(({ theme }) => ({
	alignItems: "center",
	border: "none",
	fontSize: 12,
	margin: 0,
	padding: 0,
	".MuiAlert-icon": {
		marginRight: 8,
		padding: 0
	},
	".MuiAlert-message": {
		padding: 0
	}
}));

const GridRowItemLabel = props => {
	return <GridRowItem item xs={3} {...props} />;
};

const GridRowItemValue = props => {
	return <GridRowItem item xs={5} {...props} />;
};

const GridRowItemAction = props => {
	return <GridRowItem item xs={4} {...props} />;
};

const GridRowItemActionButton = props => {
	return <Button size="small" variant="outlined" {...props} />;
};

const getValueStyles = theme => ({
	fontWeight: theme.typography.fontWeightBold
});

const commonSkeletonStyles = { transform: "scale(1, 1)" };

const ProfileDetailsList = props => {

	let returnElem = null;

	let isAccountWebAccessBlocked = false;
	let isAccountPaymentBlocked = false;
	let isDateOfBirthUpdatable = false;
	let isLegalNameUpdatable = false;
	let isWebAccountTemporarilyLocked = false;

	let accountRecoveryElem = null;
	let blockHistoryLinkElem = null;
	let conflictingBirthDate = null;
	let conflictingName = null;
	let temporaryLockStatusText = null;
	let temporaryLockExpiresText = null;

	let accountStatusElem = <Skeleton sx={commonSkeletonStyles} width={100} />;
	let dateOfBirthElem = <Skeleton sx={commonSkeletonStyles} width={100} />;
	let emailElem = <Skeleton sx={commonSkeletonStyles} width={180} />;
	let nameElem = <Skeleton sx={commonSkeletonStyles} width={180} />;
	let paymentBlockElem = <Skeleton sx={commonSkeletonStyles} width={140} />;
	let socialSecurityNumberElem = <Skeleton sx={commonSkeletonStyles} width={140} />;
	let temporaryLockStatusElem = <Skeleton sx={commonSkeletonStyles} width={180} />;
	let usernameElem = <Skeleton sx={commonSkeletonStyles} width={140} />;
	let webAccessBlockElem = <Skeleton sx={commonSkeletonStyles} width={140} />;

	const {
		accountBlockHistory,
		accountBlockHistoryErrors,
		accountHasActiveWebProfile,
		accountProfile,
		accountProfileErrors,
		getAccountBlockHistory,
		getAccountProfile,
		privileges,
		recentUpdates
	} = props;

	const navigate = useNavigate();
	const { personRefId } = useParams();
	const theme = useTheme();

	const wasRecentlyUpdated = (key, comparisonDateTime) => {

		let recentUpdate = recentUpdates.find(update => update.id === key);
		let recentUpdateFlag = false;

		if (isObject(recentUpdate) && isNumber(recentUpdate.updatedAt)) {
			recentUpdateFlag = recentUpdate.updatedAt + RECENT_UPDATE_THRESHOLD > comparisonDateTime;
		}

		return recentUpdateFlag;
	};

	if (isArrayOfLength(accountProfileErrors) || isArrayOfLength(accountBlockHistoryErrors)) {

		returnElem = (
			<Box py={3}>
				<ErrorLoading
					alertDescription="One or more dependencies failed to load."
					alertTitle="Loading Error"
					maxWidth="xs"
					primaryActionCallback={() => {
						isArrayOfLength(accountBlockHistoryErrors) && getAccountBlockHistory(personRefId);
						isArrayOfLength(accountProfileErrors) && getAccountProfile(personRefId);
					}}
					primaryActionLabel="Retry"
				/>
			</Box>
		);

	} else {

		if (isObject(accountProfile)) {

			const now = new Date().getTime();

			if (isObject(accountProfile.conflict)) {

				if (isObject(accountProfile.conflict.name)) {
					conflictingName = format.asProperName(accountProfile.conflict.name);
				}

				if (isObject(accountProfile.conflict.birth) && isStringOfLength(accountProfile.birth.occurrenceDate)) {
					conflictingBirthDate = format.asDate(accountProfile.conflict.birth.occurrenceDate);
				}
			}

			if (isStringOfLength(accountProfile.ssn) || isNumber(accountProfile.ssn)) {
				socialSecurityNumberElem = (
					<Box>
						<Box sx={getValueStyles(theme)}>{format.asSocial(accountProfile.ssn)}</Box>
						{wasRecentlyUpdated(RECENT_UPDATE_KEY_SSN, now) && <Typography component={Box} fontSize={12}>(Updated)</Typography>}
					</Box>
				);
			}

			if (isObject(accountProfile.name)) {
				isLegalNameUpdatable = isObject(accountProfile.name.metadata) && accountProfile.name.metadata.isUpdatable;
				nameElem = (
					<Box>
						<Box sx={getValueStyles(theme)}>{format.asProperName(accountProfile.name)}</Box>
						{
							conflictingName && (
								<InlineAlert variant="outlined" severity="error">
									Conflict with {conflictingName}
								</InlineAlert>
							)
						}
						{wasRecentlyUpdated(RECENT_UPDATE_KEY_NAME, now) && <Typography component={Box} fontSize={12}>(Updated)</Typography>}
					</Box >
				);
			}

			if (isObject(accountProfile.birth)) {
				const birthOccurrenceDate = new Date(accountProfile.birth.occurrenceDate);
				const isBirthday = (`${currentDate.getMonth()}/${currentDate.getDate()}` === `${birthOccurrenceDate.getUTCMonth()}/${birthOccurrenceDate.getUTCDate()}`);
				isDateOfBirthUpdatable = isObject(accountProfile.birth.metadata) && accountProfile.birth.metadata.isUpdatable;
				dateOfBirthElem = (
					<Box sx={{ alignItems: "center", display: "flex" }}>
						<Box>
							<Box sx={getValueStyles(theme)}>{format.asDate(accountProfile.birth.occurrenceDate)}</Box>
							{
								conflictingBirthDate && (
									<InlineAlert variant="outlined" severity="error">
										Conflict with {conflictingBirthDate}
									</InlineAlert>
								)
							}
							{wasRecentlyUpdated(RECENT_UPDATE_KEY_DOB, now) && <Typography component={Box} fontSize={12}>(Updated)</Typography>}
						</Box>
						{!conflictingBirthDate && isBirthday && (<CakeIcon color="primary" sx={{ marginLeft: 1 }} />)}
					</Box>
				);
			}

			usernameElem = (
				<Box>
					<Box sx={getValueStyles(theme)}>
						{isStringOfLength(accountProfile.userId) ? accountProfile.userId : TEXT_DATA_UNAVAILABLE}
					</Box>
					{wasRecentlyUpdated(RECENT_UPDATE_KEY_USER_ID, now) && <Typography component={Box} fontSize={12}>(Updated)</Typography>}
				</Box>
			);

			if (isArrayOfLength(accountProfile.emails)) {
				let contactEmailObj = accountProfile.emails.find(email => email.id === "contact");

				if (isObject(contactEmailObj)) {
					emailElem = (
						<Box>
							<Box sx={getValueStyles(theme)}>
								{contactEmailObj.email}
							</Box>
							{
								!contactEmailObj.isValid && (
									<InlineAlert variant="outlined" severity="error">
										Invalid Contact Email
									</InlineAlert>
								)
							}
							{wasRecentlyUpdated(RECENT_UPDATE_KEY_CONTACT_EMAIL, now) && <Typography component={Box} fontSize={12}>(Updated)</Typography>}
						</Box>
					);
				} else {
					emailElem = (
						<Box>
							<Box sx={getValueStyles(theme)}>{TEXT_DATA_UNAVAILABLE}</Box>
						</Box>
					);
				}
			} else {
				emailElem = (
					<Box>
						<Box sx={getValueStyles(theme)}>{TEXT_DATA_UNAVAILABLE}</Box>
					</Box>
				);
			}

			accountStatusElem = (
				<Box>
					<Box sx={getValueStyles(theme)}>
						{accountHasActiveWebProfile ? "Active" : ""}
					</Box>
				</Box>
			);

			if (isObject(accountProfile.lock) && accountProfile.lock.has) {

				isWebAccountTemporarilyLocked = true;

				if (isNumber(accountProfile.lock.duration)) {
					temporaryLockStatusText = `Locked (${format.asHourMinSec(accountProfile.lock.duration * 60, {
						hourAbbr: "H",
						leadingZeroes: false,
						minuteAbbr: "M",
						requireMinutes: false,
						requireSeconds: false,
						secondAbbr: "S"
					}).value})`;
				}

				if (isStringOfLength(accountProfile.lock.expiresOn)) {
					temporaryLockExpiresText = `Expires: ${new Date(accountProfile.lock.expiresOn).toLocaleTimeString("en-US", {
						hour: "numeric",
						minute: "numeric",
						second: "numeric",
						timeZoneName: "short"
					})}`;
				}
			}

			temporaryLockStatusElem = (
				<Box>
					<Box sx={getValueStyles(theme)}>
						{isWebAccountTemporarilyLocked ? temporaryLockStatusText : (accountHasActiveWebProfile ? "Not Locked" : "")}
					</Box>
					{
						isWebAccountTemporarilyLocked &&
						temporaryLockExpiresText &&
						<Typography component={Box} fontSize={12}>{temporaryLockExpiresText}</Typography>
					}
				</Box>
			);

			if (isArrayOfLength(accountProfile.recovery)) {

				let recoveryEmails = accountProfile.recovery.filter(method => isStringOfLength(method.email));
				let recoveryPhones = accountProfile.recovery.filter(method => isObject(method.phone));
				let recoveryPhonesEnabled = true;

				if (isArrayOfLength(recoveryEmails) || (recoveryPhonesEnabled && isArrayOfLength(recoveryPhones))) {

					accountRecoveryElem = (
						<Box component={StyledContainer} sx={{ margin: "2rem 0", padding: "2.4rem" }}>
							<GridRow container>
								<GridRowItemLabel>Recovery Method:</GridRowItemLabel>
								<GridRowItemValue>
									<Box>
										<Box sx={getValueStyles(theme)}>
											Active
										</Box>
									</Box>
								</GridRowItemValue>
								<GridRowItemAction />
							</GridRow>
							{
								isArrayOfLength(recoveryPhones) && (
									<GridRow container>
										<GridRowItemLabel>Recovery Phone:</GridRowItemLabel>
										<GridRowItemValue>
											{
												recoveryPhones.map((method, i) => {
													return (
														<Box key={i}>
															<Box sx={getValueStyles(theme)}>
																{format.asPhone(method.phone.number, { outputPattern: "###-###-####", separator: "-" })}
															</Box>
															{
																isStringOfLength(method.verifiedOn) && <Typography component={Box} fontSize={12}>Verified {format.asDate(method.verifiedOn)}</Typography>
															}
														</Box>
													);
												})
											}
										</GridRowItemValue>
										<GridRowItemAction>
											<GridRowItemActionButton
												disabled={!accountHasActiveWebProfile || !privileges.deleteRecoveryMethod}
												onClick={() => { navigate({ pathname: `/profiles/${personRefId}/manage-recovery-phones` }); }}
											>
												Remove a Phone Number
											</GridRowItemActionButton>
										</GridRowItemAction>
									</GridRow>
								)
							}
							{
								isArrayOfLength(recoveryEmails) && (
									<GridRow container>
										<GridRowItemLabel>Recovery Email:</GridRowItemLabel>
										<GridRowItemValue sx={{ alignItems: "flex-start", flexDirection: "column" }}>
											{
												recoveryEmails.map((method, i) => {
													return (
														<Box key={i} sx={{ marginTop: (i === 0 ? 0 : 1) }}>
															<Box sx={getValueStyles(theme)}>
																{method.email}
															</Box>
															{
																isStringOfLength(method.verifiedOn) && <Typography component={Box} fontSize={12}>Verified {format.asDate(method.verifiedOn)}</Typography>
															}
														</Box>
													);
												})
											}
										</GridRowItemValue>
										<GridRowItemAction>
											<GridRowItemActionButton
												disabled={!accountHasActiveWebProfile || !privileges.deleteRecoveryMethod}
												onClick={() => { navigate({ pathname: `/profiles/${personRefId}/manage-recovery-emails` }); }}
											>
												Remove an Email
											</GridRowItemActionButton>
										</GridRowItemAction>
									</GridRow>
								)
							}
						</Box>
					);
				}
			}
		}

		if (isArray(accountBlockHistory)) {

			if (isArrayOfLength(accountBlockHistory)) {
				blockHistoryLinkElem = (
					<Box
						component="p"
						sx={{
							display: "flex",
							fontWeight: 700,
							justifyContent: "flex-end",
							marginTop: 2,
							textTransform: "uppercase"
						}}
					>
						<Button
							onClick={() => {
								navigate(`/profiles/${personRefId}/block-history`);
							}}
							variation="text"
						>
							View Block History
						</Button>
					</Box>
				);
			}

			let accountWebAccessBlockData = accountBlockHistory.filter(item => item.scope === "account");
			let accountPaymentBlockData = accountBlockHistory.filter(item => item.scope === "payment");

			if (isArrayOfLength(accountWebAccessBlockData)) {
				isAccountWebAccessBlocked = accountWebAccessBlockData[0].event === "block";
			}

			if (isArrayOfLength(accountPaymentBlockData)) {
				isAccountPaymentBlocked = accountPaymentBlockData[0].event === "block";
			}

			webAccessBlockElem = (
				<Box>
					<Box sx={getValueStyles(theme)}>
						{isAccountWebAccessBlocked ? "Blocked" : "Not Blocked"}
					</Box>
				</Box>
			);

			paymentBlockElem = (
				<Box>
					<Box sx={getValueStyles(theme)}>
						{isAccountPaymentBlocked ? "Blocked" : "Not Blocked"}
					</Box>
				</Box>
			);
		}

		returnElem = (
			<React.Fragment>
				<Box component={StyledContainer} sx={{ margin: "2rem 0", padding: "2.4rem" }}>
					<GridRow container>
						<GridRowItemLabel>Social Security Number:</GridRowItemLabel>
						<GridRowItemValue>{socialSecurityNumberElem}</GridRowItemValue>
						<GridRowItemAction>
							<GridRowItemActionButton
								disabled={!accountHasActiveWebProfile || !privileges.updateSocialSecurityNumber}
								onClick={() => { navigate({ pathname: `/profiles/${personRefId}/manage-ssn` }); }}
							>
								Edit Social Security Number
							</GridRowItemActionButton>
						</GridRowItemAction>
					</GridRow>
					<GridRow container>
						<GridRowItemLabel>Name:</GridRowItemLabel>
						<GridRowItemValue>{nameElem}</GridRowItemValue>
						<GridRowItemAction>
							<GridRowItemActionButton
								disabled={!privileges.updateLegalName || !isLegalNameUpdatable}
								onClick={() => { navigate({ pathname: `/profiles/${personRefId}/manage-legal-name` }); }}
							>
								Edit Name
							</GridRowItemActionButton>
						</GridRowItemAction>
					</GridRow>
					<GridRow container>
						<GridRowItemLabel>Date of Birth:</GridRowItemLabel>
						<GridRowItemValue>{dateOfBirthElem}</GridRowItemValue>
						<GridRowItemAction>
							<GridRowItemActionButton
								disabled={!privileges.updateDateOfBirth || !isDateOfBirthUpdatable}
								onClick={() => { navigate({ pathname: `/profiles/${personRefId}/manage-date-of-birth` }); }}
							>
								Edit Date of Birth
							</GridRowItemActionButton>
						</GridRowItemAction>
					</GridRow>
					<GridRow container>
						<GridRowItemLabel>Username:</GridRowItemLabel>
						<GridRowItemValue>{usernameElem}</GridRowItemValue>
						<GridRowItemAction>
							<GridRowItemActionButton
								disabled={!privileges.updateUsername || !accountHasActiveWebProfile}
								onClick={() => { navigate({ pathname: `/profiles/${personRefId}/manage-user-id` }); }}
							>
								Edit Username
							</GridRowItemActionButton>
						</GridRowItemAction>
					</GridRow>
					<GridRow container>
						<GridRowItemLabel>Email:</GridRowItemLabel>
						<GridRowItemValue>{emailElem}</GridRowItemValue>
						<GridRowItemAction>
							<GridRowItemActionButton
								disabled={!privileges.updateContactEmail}
								onClick={() => { navigate({ pathname: `/profiles/${personRefId}/manage-contact-email` }); }}
							>
								Edit Email
							</GridRowItemActionButton>
						</GridRowItemAction>
					</GridRow>
				</Box>
				<Box component={StyledContainer} sx={{ margin: "2rem 0", padding: "2.4rem" }}>
					<GridRow container>
						<GridRowItemLabel>Account Status:</GridRowItemLabel>
						<GridRowItemValue>{accountStatusElem}</GridRowItemValue>
						<GridRowItemAction />
					</GridRow>
					<GridRow container>
						<GridRowItemLabel>Temporary Lock Status:</GridRowItemLabel>
						<GridRowItemValue>{temporaryLockStatusElem}</GridRowItemValue>
						<GridRowItemAction>
							<GridRowItemActionButton
								disabled={!privileges.deleteTemporaryLock || !isWebAccountTemporarilyLocked}
								onClick={() => { navigate({ pathname: `/profiles/${personRefId}/manage-lock` }); }}
							>
								Remove Temporary Lock
							</GridRowItemActionButton>
						</GridRowItemAction>
					</GridRow>
					<GridRow container>
						<GridRowItemLabel>Web Access:</GridRowItemLabel>
						<GridRowItemValue>{webAccessBlockElem}</GridRowItemValue>
						<GridRowItemAction>
							<GridRowItemActionButton
								disabled={!accountHasActiveWebProfile || !privileges.updateWebAccessBlock}
								onClick={() => { navigate({ pathname: `/profiles/${personRefId}/${isAccountWebAccessBlocked ? "remove" : "apply"}-web-access-block` }); }}
							>
								Change Web Access
							</GridRowItemActionButton>
						</GridRowItemAction>
					</GridRow>
					<GridRow container>
						<GridRowItemLabel>Payment Access (Web/IVR):</GridRowItemLabel>
						<GridRowItemValue>{paymentBlockElem}</GridRowItemValue>
						<GridRowItemAction>
							<GridRowItemActionButton
								disabled={!accountHasActiveWebProfile || !privileges.updatePaymentBlock}
								onClick={() => { navigate({ pathname: `/profiles/${personRefId}/${isAccountPaymentBlocked ? "remove" : "apply"}-payment-block` }); }}
							>
								Change Payment Access
							</GridRowItemActionButton>
						</GridRowItemAction>
					</GridRow>
					{blockHistoryLinkElem}
				</Box>
				{accountRecoveryElem}
			</React.Fragment>
		);
	}

	return (
		<React.Fragment>
			{
				isObject(accountProfile) && !accountHasActiveWebProfile && (
					<Box
						sx={{
							backgroundColor: theme.palette.primary.main,
							color: theme.palette.primary.contrastText,
							fontWeight: 700,
							padding: "0.7rem",
							textAlign: "center",
							textTransform: "uppercase"
						}}
					>
						No Online Account
					</Box>
				)
			}
			{returnElem}
		</React.Fragment>
	);
};

const mapStateToProps = state => {
	return {
		accountBlockHistory: state.profile.accountBlockHistory,
		accountBlockHistoryErrors: state.profile.accountBlockHistoryErrors,
		accountHasActiveWebProfile: state.profile.accountHasActiveWebProfile,
		accountProfile: state.profile.accountProfile,
		accountProfileErrors: state.profile.accountProfileErrors,
		privileges: state.auth.privileges,
		recentUpdates: state.profile.recentUpdates
	};
};

const mapDispatchToProps = dispatch => {
	return {
		getAccountBlockHistory: data => dispatch(profileEvents.getAccountBlockHistory(data)),
		getAccountProfile: data => dispatch(profileEvents.getAccountProfile(data))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileDetailsList);