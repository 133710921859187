import * as authEvents from "../../store/events/auth";
import { connect } from "react-redux";
import { getAccessDeniedLocation } from "../../config/auth";
import { isLocalEnv } from "../../config/environment";
import { isStringOfLength } from "@pheaa/channels-component-library";
import { persistor } from "../../store";
import { useEffect } from "react";
import {
	API_NSP_AUTH,
	API_NSP_OPERATION_LOGIN,
	API_NSP_OPERATION_LOGOUT,
	API_NSP_OPERATION_PROFILE,
	getApiUrl
} from "../../config/api";
import {
	PS_EVENT_ACCESS_DENIED,
	PS_EVENT_ACCESS_GRANTED,
	PS_EVENT_LOGOUT_INVALID_TOKEN,
	PS_EVENT_SESSION_END,
	PS_EVENT_SESSION_EXPIRED,
	PS_EVENT_SESSION_EXTEND,
	PS_EVENT_SESSION_PROFILE
} from "../../config/common";
import { useNavigate, useSearchParams } from "react-router-dom";

let appSubscriptionsApplied = false;

const SubscriptionManager = ({ ps, ...rest }) => {

	const {
		extendSession,
		taskComplete
	} = rest;

	const navigate = useNavigate();
	const [searchParams] = useSearchParams();

	useEffect(() => {

		const createAppSubscriptions = () => {

			const handleAccessDenied = ({ nextEvent } = {}) => {
				// console.log("Access Denied...", nextEvent);
				const accessDeniedLocation = getAccessDeniedLocation();
				const url = new URL(accessDeniedLocation, window.location.origin);

				if (isStringOfLength(nextEvent) && nextEvent !== accessDeniedLocation) {
					url.searchParams.set("nextEvent", nextEvent);
				}

				navigate({ pathname: url.pathname, search: url.search });
			};

			const handleAccessGranted = () => {
				// console.log("Access Granted.");
				if (window.location.pathname === getAccessDeniedLocation()) {
					const nextEvent = searchParams.get("nextEvent");
					if (isStringOfLength(nextEvent)) {
						navigate(nextEvent);
					} else {
						navigate("/");
					}
				}
			};

			const handleLogoutInvalidToken = ({ returnEvent } = {}) => {
				// console.log("Token Invalid. Logging out...", returnEvent);
				persistor.purge();
				const url = getApiUrl(API_NSP_AUTH, API_NSP_OPERATION_LOGOUT);
				window.location = url;
			};

			const handleSessionEnd = () => {
				persistor.purge();
				const url = getApiUrl(API_NSP_AUTH, API_NSP_OPERATION_LOGIN);
				window.location = url;
			};

			const handleSessionExpired = ({ returnEvent } = {}) => {
				// console.log("Session Expired.", returnEvent);
				persistor.purge();
				const url = getApiUrl(API_NSP_AUTH, API_NSP_OPERATION_LOGOUT);
				window.location = url;
			};

			const handleSessionExtend = () => {
				// console.log("Session Extending. Invoking API to Extend.");
				extendSession();
			};

			const handleSessionProfile = () => {
				persistor.purge();
				const url = getApiUrl(API_NSP_AUTH, API_NSP_OPERATION_PROFILE);
				window.location = url;
			};

			!isLocalEnv && ps.subscribe(PS_EVENT_ACCESS_DENIED, handleAccessDenied);
			ps.subscribe(PS_EVENT_ACCESS_GRANTED, handleAccessGranted);
			ps.subscribe(PS_EVENT_LOGOUT_INVALID_TOKEN, handleLogoutInvalidToken);
			ps.subscribe(PS_EVENT_SESSION_END, handleSessionEnd);
			ps.subscribe(PS_EVENT_SESSION_EXPIRED, handleSessionExpired);
			ps.subscribe(PS_EVENT_SESSION_EXTEND, handleSessionExtend);
			ps.subscribe(PS_EVENT_SESSION_PROFILE, handleSessionProfile);
		};

		if (!appSubscriptionsApplied) {
			createAppSubscriptions();
			appSubscriptionsApplied = true;
		}
	}, [
		extendSession,
		navigate,
		ps,
		searchParams,
		taskComplete
	]);

	return null;
};

const mapStateToProps = state => {
	return {};
};

const mapDispatchToProps = dispatch => {
	return {
		extendSession: data => dispatch(authEvents.extendSession(data))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionManager);