import * as profileEvents from "../../store/events/profile";
import Box from "@mui/material/Box";
import Button from "../../components/Button/Button";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import DefaultTemplate from "../templates/DefaultTemplate";
import IconButton from "@mui/material/IconButton";
import PageTitle from "../../components/PageTitle/PageTitle";
import ProfileAccountInteractionList from "../../components/Profile/ProfileAccountInteractionList";
import ProfileDetailsList from "../../components/Profile/ProfileDetailsList";
import ProfileDetailsOverview from "../../components/Profile/ProfileDetailsOverview";
import RefreshIcon from "@mui/icons-material/Refresh";
import UpdateConfirmationSnackbar from "../../components/Profile/UpdateConfirmationSnackbar";
import { connect } from "react-redux";
import { useTheme } from "@mui/material";
import React, { useEffect } from "react";
import { format, isObject } from "@pheaa/channels-component-library";
import { useNavigate, useParams } from "react-router-dom";

const ProfileView = ({ title, ...props }) => {

	let titleElem = null;

	const {
		accountHasActiveWebProfile,
		accountProfile,
		getAccountData,
		privileges
	} = props;

	const navigate = useNavigate();
	const { personRefId } = useParams();
	const theme = useTheme();

	useEffect(() => {
		getAccountData(personRefId);
	}, [
		getAccountData,
		personRefId
	]);

	titleElem = (
		<Box
			alignItems="flex-end"
			sx={{ display: "flex" }}
		>
			<Box sx={{ flex: "1 1 auto" }}>
				{isObject(accountProfile) ? format.asProperName(accountProfile.name) : title}
			</Box>
			<Button
				disabled={!accountHasActiveWebProfile || (accountHasActiveWebProfile && !privileges.updateAccountRecovery)}
				onClick={() => { navigate(`/profiles/${personRefId}/account-recovery`); }}
				sx={{ flex: "0 0 auto", marginLeft: "12px" }}
				variant="outlined"
			>
				Account Recovery
			</Button>
			<Button
				disabled={!accountHasActiveWebProfile || (accountHasActiveWebProfile && !privileges.deleteWebAccount)}
				onClick={() => { navigate(`/profiles/${personRefId}/delete-online-account`); }}
				sx={{ flex: "0 0 auto", marginLeft: "12px" }}
				variant="outlined"
			>
				Delete Online Account
			</Button>
			<IconButton
				color="primary"
				onClick={() => {
					getAccountData(personRefId, true);
				}}
				variant="outlined"
			>
				<RefreshIcon /> <span className="a11y-hidden">Reload Profile Data</span>
			</IconButton>
		</Box>
	);

	return (
		<DefaultTemplate>
			<IconButton
				color="primary"
				onClick={() => {
					navigate({ pathname: "/" });
				}}
				sx={{ ...theme.typography.button, paddingLeft: 0 }}
			>
				<ChevronLeftIcon /> Back to Search
			</IconButton>
			<UpdateConfirmationSnackbar />
			<PageTitle title={titleElem} />
			<ProfileDetailsOverview />
			<ProfileDetailsList />
			<ProfileAccountInteractionList />
		</DefaultTemplate >
	);
};

const mapStateToProps = state => {
	return {
		accountHasActiveWebProfile: state.profile.accountHasActiveWebProfile,
		accountProfile: state.profile.accountProfile,
		privileges: state.auth.privileges
	};
};

const mapDispatchToProps = dispatch => {
	return {
		getAccountData: (data, forceReload) => dispatch(profileEvents.getAccountData(data, forceReload)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileView);