import * as profileEvents from "../../store/events/profile";
import Button from "../../components/Button/Button";
import DefaultTemplate from "../templates/DefaultTemplate";
import ErrorMessages from "../../components/ErrorMessages/ErrorMessages";
import FormActionContainer from "../../components/Form/FormActionContainer";
import FormTextField from "../../components/Form/FormTextField";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import React from "react";
import TaskHeader from "../../components/Task/TaskHeader";
import Typography from "@mui/material/Typography";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { useTheme } from "@mui/material";
import withProfileAndAccess from "../../utils/withProfileAndAccess";
import {
	Form,
	format,
	isObject,
	isStringOfLength
} from "@pheaa/channels-component-library";
import {
	HTML_ATTR_PATTERN_NAME_FIRST,
	HTML_ATTR_PATTERN_NAME_LAST,
	TEXT_DATA_UNAVAILABLE,
	getCommonProfileDataListStyles,
	getErrorMessageForCode
} from "../../config/common";

const ManageLegalNameView = props => {

	let formattedName = null;

	const {
		accountProfile,
		accountProfileErrorsUpdate,
		accountProfileIsUpdating,
		updateAccountProfile
	} = props;

	const { personRefId } = useParams();
	const theme = useTheme();

	const handleSubmit = (e, formData) => {
		updateAccountProfile(personRefId, { name: { first: formData.first, last: formData.last } });
	};

	if (isObject(accountProfile) && isObject(accountProfile.name)) {
		formattedName = format.asProperName(accountProfile.name);
		!isStringOfLength(formattedName) && (formattedName = TEXT_DATA_UNAVAILABLE);
	} else {
		formattedName = TEXT_DATA_UNAVAILABLE;
	}

	return (
		<DefaultTemplate isTask={true}>
			<TaskHeader
				closeTaskDestination={`/profiles/${personRefId}`}
				title="Edit Name"
			/>
			<Form id="defaultForm" onSubmit={handleSubmit}>
				<Typography align="center" gutterBottom={true}>
					Enter new name in fields below.
				</Typography>
				<ErrorMessages
					errorMessages={accountProfileErrorsUpdate.map(error => {
						return { ...error, message: getErrorMessageForCode(error.code) };
					})}
				/>
				<List sx={getCommonProfileDataListStyles(theme)}>
					<ListItem>
						<ListItemText primary="Current Name:" />
						<ListItemText primary={formattedName} />
					</ListItem>
				</List>
				<FormTextField
					name="first"
					pattern={HTML_ATTR_PATTERN_NAME_FIRST}
					required={true}
					title="First Name"
				/>
				<FormTextField
					name="last"
					pattern={HTML_ATTR_PATTERN_NAME_LAST}
					required={true}
					title="Last Name"
				/>
				<FormActionContainer>
					<Button
						disabled={accountProfileIsUpdating}
						processing={accountProfileIsUpdating}
						type="submit"
						variant="contained"
					>
						Save
					</Button>
				</FormActionContainer>
			</Form>
		</DefaultTemplate >
	);
};

const mapStateToProps = state => {
	return {
		accountProfile: state.profile.accountProfile,
		accountProfileErrorsUpdate: state.profile.accountProfileErrorsUpdate,
		accountProfileIsUpdating: state.profile.accountProfileIsUpdating
	};
};

const mapDispatchToProps = dispatch => {
	return {
		updateAccountProfile: (personRefId, data) => dispatch(profileEvents.updateAccountProfile(personRefId, data))
	};
};

export default withProfileAndAccess(connect(mapStateToProps, mapDispatchToProps)(ManageLegalNameView), ["updateLegalName"]);