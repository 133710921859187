import * as profileEvents from "../../store/events/profile";
import Box from "@mui/material/Box";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import DefaultTemplate from "../templates/DefaultTemplate";
import ErrorLoading from "../../components/ErrorMessages/ErrorLoading";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import PageTitle from "../../components/PageTitle/PageTitle";
import React from "react";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Container as StyledContainer } from "../../components/Container/Container";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { connect } from "react-redux";
import { useTheme } from "@mui/material";
import withProfileAndAccess from "../../utils/withProfileAndAccess";
import {
	BLOCK_EVENT_BLOCK,
	BLOCK_SCOPE_ACCOUNT,
	BLOCK_SCOPE_PAYMENT,
	TEXT_DATA_UNAVAILABLE
} from "../../config/common";
import { format, isArray, isArrayOfLength, isObject, isStringOfLength } from "@pheaa/channels-component-library";
import { useNavigate, useParams } from "react-router-dom";

const getBlockHistoryListStyles = theme => ({
	padding: 0,

	".MuiListItem-root": {
		padding: 0,

		".MuiListItemText-root": {
			margin: "5px 0",
			textAlign: "left",

			"&:first-of-type": {
				flexGrow: 0,
				width: 200
			},

			"&:last-of-type": {
				paddingLeft: 2
			}
		}
	}
});

const BlockHistoryView = ({ title, ...props }) => {

	let nameElem = null;
	let returnElem = null;
	let ssnElem = null;
	let titleElem = null;

	const {
		accountBlockHistory,
		accountBlockHistoryErrors,
		accountBlockHistoryIsLoading,
		accountProfile,
		getAccountBlockHistory
	} = props;

	const navigate = useNavigate();
	const { personRefId } = useParams();
	const theme = useTheme();

	const getReasonText = (sc, ev, rc) => {
		let reasonText = "";

		if (sc === BLOCK_SCOPE_PAYMENT) {
			reasonText = "Financial Management";
		} else if (sc === BLOCK_SCOPE_ACCOUNT) {
			if (ev === BLOCK_EVENT_BLOCK) {
				if (rc === "01") {
					reasonText = "User Requested Block";
				} else if (rc === "02") {
					reasonText = "Unauthorized Access";
				}
			}
		}

		return reasonText;
	};

	titleElem = (
		<Box
			alignItems="flex-end"
			sx={{ display: "flex" }}
		>
			<Box sx={{ flex: "1 1 auto" }}>
				{title}
			</Box>
			<IconButton
				color="primary"
				onClick={() => {
					getAccountBlockHistory(personRefId, true);
				}}
				variant="outlined"
			>
				<RefreshIcon /> <span className="a11y-hidden">Reload Block History Data</span>
			</IconButton>
		</Box>
	);

	if (isObject(accountProfile)) {

		if (isObject(accountProfile.name)) {
			nameElem = format.asProperName(accountProfile.name);
			!isStringOfLength(nameElem) && (nameElem = TEXT_DATA_UNAVAILABLE);
		}

		if (isStringOfLength(accountProfile.ssn)) {
			ssnElem = format.asSocial(accountProfile.ssn);
		}
	}

	if (accountBlockHistoryIsLoading) {
		returnElem = (
			<TableRow>
				<TableCell colSpan={2}>
					<Box py={3}>
						Loading Block History Data...
					</Box>
				</TableCell>
			</TableRow>
		);
	} else if (isArrayOfLength(accountBlockHistoryErrors)) {
		returnElem = (
			<TableRow>
				<TableCell colSpan={2}>
					<Box py={3}>
						<ErrorLoading
							alertDescription="There was an error fetching Block History data."
							alertTitle="Loading Error"
							maxWidth="xs"
							primaryActionCallback={() => {
								getAccountBlockHistory(personRefId);
							}}
							primaryActionLabel="Retry"
						/>
					</Box>
				</TableCell>
			</TableRow>
		);
	} else if (isArray(accountBlockHistory)) {

		if (isArrayOfLength(accountBlockHistory)) {

			returnElem = accountBlockHistory.map((blockEvent, i) => (
				<TableRow key={i}>
					<TableCell colSpan={2}>
						<List sx={getBlockHistoryListStyles(theme)}>
							<ListItem>
								<ListItemText primary="Action:" />
								<ListItemText primary={`${format.asTitleCase(blockEvent.scope)} - ${format.asTitleCase(blockEvent.event)}`} />
							</ListItem>
							<ListItem>
								<ListItemText primary="Date/Time:" />
								<ListItemText primary={`${format.asDate(blockEvent.createdOn)} ${new Date(blockEvent.createdOn).toLocaleTimeString()}`} />
							</ListItem>
							<ListItem>
								<ListItemText primary="Action taken by::" />
								<ListItemText primary={blockEvent.createdBy} />
							</ListItem>
							<ListItem>
								<ListItemText primary="Details:" />
								<ListItemText primary={getReasonText(blockEvent.scope, blockEvent.event, blockEvent.reasonCode)} />
							</ListItem>
							<ListItem>
								<ListItemText primary="Comments:" />
								<ListItemText primary={blockEvent.comments} />
							</ListItem>
						</List>
					</TableCell>
				</TableRow>
			));
		} else {
			returnElem = (
				<TableRow>
					<TableCell colSpan={2}>
						<Box py={3}>
							There are no blocks returned for this account.
						</Box>
					</TableCell>
				</TableRow>
			);
		}
	}

	return (
		<DefaultTemplate>
			<IconButton
				color="primary"
				onClick={() => {
					navigate({ pathname: `/profiles/${personRefId}` });
				}}
				sx={{ ...theme.typography.button, paddingLeft: 0 }}
			>
				<ChevronLeftIcon /> Back to User Profile
			</IconButton>
			<PageTitle title={titleElem} />
			<TableContainer component={StyledContainer}>
				<Table sx={{ minWidth: 650 }} size="small" aria-label="Search Results">
					<TableHead>
						<TableRow>
							<TableCell width={250}>{nameElem}</TableCell>
							<TableCell align="right">SSN: {ssnElem}</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{returnElem}
					</TableBody>
				</Table>
			</TableContainer>
		</DefaultTemplate >
	);
};

const mapStateToProps = state => {
	return {
		accountBlockHistory: state.profile.accountBlockHistory,
		accountBlockHistoryErrors: state.profile.accountBlockHistoryErrors,
		accountBlockHistoryIsLoading: state.profile.accountBlockHistoryIsLoading,
		accountProfile: state.profile.accountProfile
	};
};

const mapDispatchToProps = dispatch => {
	return {
		getAccountBlockHistory: personRefId => dispatch(profileEvents.getAccountBlockHistory(personRefId)),
	};
};

export default withProfileAndAccess(connect(mapStateToProps, mapDispatchToProps)(BlockHistoryView), []);