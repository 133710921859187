import * as profileEvents from "../../store/events/profile";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Button from "../../components/Button/Button";
import DefaultTemplate from "../templates/DefaultTemplate";
import FormActionContainer from "../../components/Form/FormActionContainer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import React from "react";
import TaskHeader from "../../components/Task/TaskHeader";
import Typography from "@mui/material/Typography";
import { connect } from "react-redux";
import { useTheme } from "@mui/material";
import withProfileAndAccess from "../../utils/withProfileAndAccess";
import {
	Form,
	format,
	isArrayOfLength,
	isObject,
	isStringOfLength
} from "@pheaa/channels-component-library";
import { TEXT_DATA_UNAVAILABLE, getCommonProfileDataListStyles } from "../../config/common";
import { useNavigate, useParams } from "react-router-dom";

const ManageSSNConflictView = props => {

	let conflictingAccount = {
		accountNumber: TEXT_DATA_UNAVAILABLE,
		name: TEXT_DATA_UNAVAILABLE,
		ssn: TEXT_DATA_UNAVAILABLE,
		userId: TEXT_DATA_UNAVAILABLE
	};

	let nameElem = TEXT_DATA_UNAVAILABLE;
	let userIdElem = TEXT_DATA_UNAVAILABLE;

	const {
		accountProfileConflict,
		accountProfileValidPersonIdentifier,
		cleanUpSSNChange
	} = props;

	const navigate = useNavigate();
	const { personRefId } = useParams();
	const theme = useTheme();

	const handleSubmit = (e, formData) => {
		navigate(`/profiles/${personRefId}/manage-ssn`);
	};

	if (isObject(accountProfileConflict) && isArrayOfLength(accountProfileConflict.users)) {
		conflictingAccount = accountProfileConflict.users[0];

		if (isObject(conflictingAccount.name)) {
			nameElem = format.asProperName(conflictingAccount.name);
			!isStringOfLength(nameElem) && (nameElem = TEXT_DATA_UNAVAILABLE);
		}

		if (isStringOfLength(conflictingAccount.userId)) {
			userIdElem = conflictingAccount.userId;
		} else {
			userIdElem = "";
		}
	}

	return (
		<DefaultTemplate isTask={true}>
			<TaskHeader
				closeCallback={() => { cleanUpSSNChange(); }}
				closeTaskDestination={`/profiles/${personRefId}`}
				title="Edit Social Security Number"
			/>
			<Form id="defaultForm" onSubmit={handleSubmit}>
				<Alert severity="warning" sx={{ marginBottom: 3 }}>
					<AlertTitle>Match Found</AlertTitle>
					The social security number you entered already exists.
				</Alert>
				<Typography align="center" gutterBottom={true}>
					The SSN you entered is currently associated with this account:
				</Typography>
				<List sx={getCommonProfileDataListStyles(theme)}>
					<ListItem>
						<ListItemText primary="Social Security Number:" />
						<ListItemText primary={format.asSocial(conflictingAccount.ssn)} />
					</ListItem>
					<ListItem>
						<ListItemText primary="Account Number:" />
						<ListItemText primary={conflictingAccount.accountNumber} />
					</ListItem>
					<ListItem>
						<ListItemText primary="Name:" />
						<ListItemText primary={nameElem} />
					</ListItem>
					<ListItem>
						<ListItemText primary="Username:" />
						<ListItemText primary={userIdElem} />
					</ListItem>
				</List>
				{
					isObject(accountProfileValidPersonIdentifier) && !accountProfileValidPersonIdentifier.isValid && (
						<Alert severity="warning" sx={{ marginTop: 3 }}>
							<AlertTitle>Match Not Found</AlertTitle>
							The Social Security Number you entered does not match a core system account.
						</Alert>
					)
				}
				<FormActionContainer>
					<Button
						type="submit"
						variant="contained"
					>
						Return
					</Button>
				</FormActionContainer>
			</Form>
		</DefaultTemplate >
	);
};

const mapStateToProps = state => {
	return {
		accountProfileConflict: state.profile.accountProfileConflict,
		accountProfileValidPersonIdentifier: state.profile.accountProfileValidPersonIdentifier
	};
};

const mapDispatchToProps = dispatch => {
	return {
		cleanUpSSNChange: () => dispatch(profileEvents.cleanUpSSNChange())
	};
};

export default withProfileAndAccess(connect(mapStateToProps, mapDispatchToProps)(ManageSSNConflictView), ["updateSocialSecurityNumber"]);