import { ERROR_MESSAGE_TYPE_DEFAULT } from "../../components/ErrorMessages/ErrorMessages";
import commonApiRequest from "../../utils/commonApiRequest";
import { setData } from "../actions/search";
import { setData as setDataCommon } from "../actions/common";
import store from "..";
import {
	API_NSP_OPERATION_SEARCH,
	API_NSP_PROFILE_ADMIN,
	getApiUrl
} from "../../config/api";
import {
	HTML_ATTR_ID_SEARCH_RESULTS,
	INTERFACE_ERROR_NSC,
	SYSTEM_ERROR_CNX,
	SYSTEM_ERROR_INV
} from "../../config/common";
import {
	REQUEST_METHOD,
	isArray,
	isNumber,
	isObject,
	isStringOfLength
} from "@pheaa/channels-component-library";


const handleAuthErrors = ({ body, headers, status }) => {
	if (isNumber(status)) {
		store.dispatch(setData({ systemErrors: [{ code: status, type: ERROR_MESSAGE_TYPE_DEFAULT }] }));
	} else {
		store.dispatch(setData({ systemErrors: [{ code: SYSTEM_ERROR_INV, type: ERROR_MESSAGE_TYPE_DEFAULT }] }));
		throw new Error("Expected HTTP Error Response Code Not Received.");
	}
};

const handleAuthFailures = (error, additionalData = {}) => {
	if (isObject(error) && isStringOfLength(error.message) && error.message.toLowerCase() === "failed to fetch") {
		store.dispatch(setData({ systemErrors: [{ code: SYSTEM_ERROR_CNX, type: ERROR_MESSAGE_TYPE_DEFAULT }], ...additionalData }));
	} else {
		store.dispatch(setData({ systemErrors: [{ code: SYSTEM_ERROR_INV, type: ERROR_MESSAGE_TYPE_DEFAULT }], ...additionalData }));
	}
};

export const searchForUserAccounts = data => (dispatch, getState) => {
	if (Object.keys(data).length > 0) {
		const url = getApiUrl(API_NSP_PROFILE_ADMIN, API_NSP_OPERATION_SEARCH);

		commonApiRequest(url, {
			alwaysCallback: () => { dispatch(setData({ isSearchingForAccounts: false })); },
			beforeCallback: () => { dispatch(setData({ interfaceErrors: [], systemErrors: [], isSearchingForAccounts: true, searchResults: null })); },
			errorCallback: ({ body, headers, status }) => {
				try {
					handleAuthErrors({ body, headers, status });
				} catch (e) {
					console.error(e);
				}
			},
			data,
			extendSessionOnSuccess: true,
			failCallback: error => {
				handleAuthFailures(error);
			},
			method: REQUEST_METHOD.POST,
			successCallback: ({ body }) => {
				dispatch(setData({ searchResults: body }));
				// If body includes users array with only one user and a valid personRefId on that user;
				if (isArray(body.users) && body.users.length === 1 && isStringOfLength(body.users[0].personRefId)) {
					// Destructure personRefId from user[0];
					const { personRefId } = body.users[0];
					// Set common data to define targetPathname, forcing navigation;
					dispatch(setDataCommon({ targetPathname: `/profiles/${personRefId}` }));
				} else {
					// Define search results elem;
					const searchResultsElem = document.getElementById(HTML_ATTR_ID_SEARCH_RESULTS);

					setTimeout(() => {
						// Scroll to results;
						searchResultsElem && searchResultsElem.scrollIntoView({ behavior: "smooth" });
					}, 100);
				}
			}
		});
	} else {
		dispatch(setData({
			interfaceErrors: [{ code: INTERFACE_ERROR_NSC, type: ERROR_MESSAGE_TYPE_DEFAULT }],
			systemErrors: []
		}));
	}
};