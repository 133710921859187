
import { isStringOfLength } from "@pheaa/channels-component-library";
import { setData } from "../store/actions/common";
import store from "../store";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import React, { useEffect } from "react";

export const withRouteListener = (WrappedComponent) => props => {

	const { common } = useSelector(state => state);
	const navigate = useNavigate();

	useEffect(() => {
		if (isStringOfLength(common.targetPathname)) {
			const { targetPathname } = common;
			store.dispatch(setData({ targetPathname: null }));
			navigate(targetPathname);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [common.targetPathname]);

	return (
		<WrappedComponent {...props} />
	);
};