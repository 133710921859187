import authReducer from "./auth";
import { combineReducers } from "@reduxjs/toolkit";
import commonReducer from "./common";
import { persistReducer } from "redux-persist";
import profileReducer from "./profile";
import searchReducer from "./search";
import storageSession from "redux-persist/lib/storage/session";

const persistConfigCommon = {
	key: "common",
	storage: storageSession,
	whitelist: []
};

const persistConfigProfile = {
	key: "profile",
	storage: storageSession,
	whitelist: [
		"accountBlockHistory",
		"accountHasActiveWebProfile",
		"accountInteractionId",
		"accountInteractions",
		"accountProfile",
		"accountProfileConflict",
		"accountProfileNewSSN",
		"accountProfileValidPersonIdentifier"
	]
};

const persistConfigSearch = {
	key: "search",
	storage: storageSession,
	whitelist: ["searchCategoryIndex", "searchCriteria", "searchResults"]
};

const rootReducer = combineReducers({
	auth: authReducer,
	common: persistReducer(persistConfigCommon, commonReducer),
	profile: persistReducer(persistConfigProfile, profileReducer),
	search: persistReducer(persistConfigSearch, searchReducer)
});

export default rootReducer;