import React from "react";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material";

const PageTitle = props => {

	const theme = useTheme();

	return (
		<Typography
			component="h1"
			sx={{
				borderBottom: `3px solid ${theme.palette.primary.main}`,
				margin: "10px 0",
				padding: "14px 0 6px"
			}}
			variant="h1"
		>
			{props.title}
		</Typography>
	);
};

export default PageTitle;