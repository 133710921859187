import Box from "@mui/material/Box";
import packageJson from "../../../package.json";

const { name, version } = packageJson;

const footerStyles = {
	marginTop: "auto",
	paddingTop: 4
};

const versionStyles = {
	fontSize: "1rem",
	opacity: 0.4,
	paddingBottom: 1,
	paddingTop: 1,
	textAlign: "center"
};

const Footer = props => {
	return (
		<Box component="footer" id="footer" sx={footerStyles}>
			<Box sx={versionStyles}>{`${name}-${version}`}</Box>
		</Box>
	);
};

export default Footer;