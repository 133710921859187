import Box from "@mui/material/Box";
import React from "react";
import { useTheme } from "@mui/material";

const FormActionContainer = ({ children }) => {

	const theme = useTheme();

	return (
		<Box
			sx={{
				borderTop: `0.1rem solid ${theme.palette.neutral.main}`,
				display: "flex",
				justifyContent: "flex-end",
				margin: `${theme.spacing(8)} 0 ${theme.spacing(4)}`,
				padding: `${theme.spacing(2)} 0`
			}}
		>
			{children}
		</Box>
	);
};

export default FormActionContainer;