import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Box from "@mui/material/Box";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Container from "@mui/material/Container";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { HTML_ATTR_ID_SEARCH_RESULTS } from "../../config/common";
import IconButton from "@mui/material/IconButton";
import Popover from "@mui/material/Popover";
import React from "react";
import { Container as StyledContainer } from "../../components/Container/Container";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
	format,
	isArray,
	isArrayOfLength,
	isFunction,
	isNumber,
	isObject,
	isStringOfLength
} from "@pheaa/channels-component-library";

const rowCategoryStyles = {
	backgroundColor: "#f4f5f8",
	fontSize: "1.2rem",
	fontWeight: "bold",
	position: "sticky",
	textTransform: "uppercase"
};

const SEARCH_RESULT_CATEGORY_ID_ACTIVE_ACCT = "active-account";
const SEARCH_RESULT_CATEGORY_ID_INDETERMINATE_ACCT = "indeterminate-account";
const SEARCH_RESULT_CATEGORY_ID_NO_ACCT = "no-account";

const userFieldTextMap = {
	accountNumber: "Account Number",
	birthDate: "Date of Birth",
	name: "Name",
	personRefId: "Person Reference Id",
	userId: "User Id"
};

const SearchResults = props => {

	let hasSearchResultsContainingException = false;
	let noSearchResultsElem = null;
	let partialSearchResultsElem = null;
	let returnElem = null;
	let searchResultsExceedLimitElem = null;

	const {
		searchResults
	} = props;

	const [anchorElem, setAnchorElem] = React.useState(null);
	const [searchResultAlertMessages, setSearchResultAlertMessages] = React.useState({});
	const navigate = useNavigate();

	const handleNavigateToAccount = personRefId => {
		navigate(`/profiles/${personRefId}`);
	};

	if (isObject(searchResults)) {

		let hasSearchException = isObject(searchResults.exception);
		let hasSearchExceptionTooManyRecords = hasSearchException && searchResults.exception.code === "tmr";
		let hasSearchResults = isArray(searchResults.users);
		let hasSearchResultsExceedingMax =
			isArrayOfLength(searchResults.users) &&
			isNumber(searchResults.userCount) &&
			isNumber(searchResults.userMaxLimit) &&
			searchResults.userCount >= searchResults.userMaxLimit;

		let suppressedRecordsCount = 0;

		let searchResultCategories = {
			[SEARCH_RESULT_CATEGORY_ID_ACTIVE_ACCT]: {
				title: "Active Online Account",
				users: []
			},
			[SEARCH_RESULT_CATEGORY_ID_NO_ACCT]: {
				title: "No Online Account",
				users: []
			},
			[SEARCH_RESULT_CATEGORY_ID_INDETERMINATE_ACCT]: {
				title: "Undetermined Account",
				users: []
			}
		};

		if (hasSearchException) {

		} else if (hasSearchResults) {

			searchResults.users.forEach(record => {
				let recordHasException = isObject(record.exception);
				let recordHasExceptionOnUserId = recordHasException && isArray(record.exception.affectedFields) && record.exception.affectedFields.includes("userId");
				let recordHasUserId = isStringOfLength(record.userId);

				if (recordHasException) {
					hasSearchResultsContainingException = true;
				}

				if (recordHasUserId) {
					searchResultCategories[SEARCH_RESULT_CATEGORY_ID_ACTIVE_ACCT].users.push(record);
				} else {
					if (recordHasExceptionOnUserId) {
						searchResultCategories[SEARCH_RESULT_CATEGORY_ID_INDETERMINATE_ACCT].users.push(record);
					} else {
						searchResultCategories[SEARCH_RESULT_CATEGORY_ID_NO_ACCT].users.push(record);
					}
				}

			});

			if (!isArrayOfLength(searchResults.users)) {
				noSearchResultsElem = (
					<Container maxWidth="sm" sx={{ marginBottom: 2, marginTop: 2 }}>
						<Box>
							<Alert severity="error">
								<AlertTitle>No Consumer Account Found</AlertTitle>
								No consumer account could be found matching the provided search criteria.
							</Alert>
						</Box>
					</Container>
				);
			} else {
				// Set suppressedRecordsCount to length of users array for later use;
				suppressedRecordsCount = searchResults.users;
			}


		}

		if (hasSearchExceptionTooManyRecords || hasSearchResultsExceedingMax) {
			searchResultsExceedLimitElem = (
				<Container maxWidth="sm" sx={{ marginBottom: 2, marginTop: 2 }}>
					<Box>
						<Alert severity="error">
							<AlertTitle>Search results exceed the allowable results to display.</AlertTitle>
							Search results cannot exceed {hasSearchExceptionTooManyRecords ? 100 : searchResults.userMaxLimit} returned accounts. Please refine your search criteria to return fewer results.
						</Alert>
					</Box>
				</Container>
			);
		}

		Object.keys(searchResultCategories).forEach(key => {
			if (suppressedRecordsCount > 0) {
				suppressedRecordsCount = Math.max(suppressedRecordsCount - searchResultCategories[key].users.length, 0);
			}
		});

		if (suppressedRecordsCount > 0) {
			partialSearchResultsElem = "Some results cannot be displayed";
		}

		returnElem = Object.keys(searchResultCategories).map((key, i) => {

			const { users, title } = searchResultCategories[key];
			// If there are no indeterminate accounts, do not show category at all;
			if (key === SEARCH_RESULT_CATEGORY_ID_INDETERMINATE_ACCT && !isArrayOfLength(users)) {
				return null;
			}

			return (
				<React.Fragment key={i}>
					<TableRow>
						<TableCell
							colSpan={hasSearchResultsContainingException ? 7 : 6}
							sx={rowCategoryStyles}
						>
							{title}
						</TableCell>
					</TableRow>
					{
						users.map((user, i) => {
							const alertId = `searchResultAlert-${i}`;

							return (
								<TableRow
									key={i}
									sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
								>
									{hasSearchResultsContainingException && (
										<TableCell width={40}>
											<IconButton
												onClick={e => {
													setAnchorElem(e.currentTarget);
													setSearchResultAlertMessages(current => {
														return { ...current, [alertId]: !current[alertId] };
													});
												}}
											>
												<ErrorOutlineIcon color="error" />
											</IconButton>
											<Popover
												anchorEl={anchorElem}
												anchorOrigin={{
													vertical: "bottom",
													horizontal: "left",
												}}
												id={`searchResultAlert-${i}`}
												onClose={() => {
													setAnchorElem(null);
													setSearchResultAlertMessages(current => {
														return { ...current, [alertId]: !current[alertId] };
													});
												}}
												open={searchResultAlertMessages[alertId] || false}
											>
												<Box sx={{ p: 2 }}>
													<Typography component="h1" gutterBottom={true} variant="h3">
														Alert
													</Typography>
													<Typography gutterBottom={false} paragraph={true}>
														Some information could not be retreived.
													</Typography>
													{
														isObject(user.exception) && isArrayOfLength(user.exception.affectedFields) && (
															<Typography component="ul">
																{
																	user.exception.affectedFields.map((affectedField, j) => {
																		return (
																			<Typography component="li" key={j}>
																				{userFieldTextMap[affectedField] || affectedField}
																			</Typography>
																		);
																	})
																}
															</Typography>
														)
													}
												</Box>
											</Popover>
										</TableCell>
									)
									}
									<TableCell component="th" scope="row">
										{user.ssn}
									</TableCell>
									<TableCell>{user.accountNumber}</TableCell>
									<TableCell>{user.userId}</TableCell>
									<TableCell sx={{ textTransform: "uppercase" }}>
										{isObject(user.name) ? format.asLastSuffixFirstMiddle(user.name) : "Unavailable"}
									</TableCell>
									<TableCell>{format.asDate(user.birthDate, { outputPattern: "mm/dd/yyyy" })}</TableCell>
									<TableCell align="right" height={53}>
										{
											isStringOfLength(user.personRefId) && (
												<IconButton
													onClick={e => {
														isFunction(e.stopPropagation) && e.stopPropagation();
														handleNavigateToAccount(user.personRefId);
													}}
												>
													<ChevronRightIcon />
												</IconButton>
											)
										}
									</TableCell>
								</TableRow>
							);
						})
					}
				</React.Fragment >
			);

		});
	}

	return (
		<Box id={HTML_ATTR_ID_SEARCH_RESULTS}>
			{
				isObject(searchResults) && (
					<React.Fragment>
						<TableContainer component={StyledContainer}>
							<Table sx={{ minWidth: 650 }} size="small" aria-label="Search Results">
								<TableHead>
									<TableRow>
										{hasSearchResultsContainingException && (<TableCell><span className="a11y-hidden">Alert</span></TableCell>)}
										<TableCell>SSN</TableCell>
										<TableCell>Account #</TableCell>
										<TableCell>User ID</TableCell>
										<TableCell>Name</TableCell>
										<TableCell>DOB</TableCell>
										<TableCell align="right"><span className="a11y-hidden">Actions</span></TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{partialSearchResultsElem}
									{returnElem}
								</TableBody>
							</Table>
						</TableContainer>
						{noSearchResultsElem}
						{searchResultsExceedLimitElem}
					</React.Fragment>
				)
			}
		</Box>
	);
};

const mapStateToProps = state => {
	return {
		searchResults: state.search.searchResults
	};
};

export default connect(mapStateToProps)(SearchResults);