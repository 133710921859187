import * as profileActions from "../../store/actions/profile";
import * as profileEvents from "../../store/events/profile";
import Button from "../../components/Button/Button";
import DefaultTemplate from "../templates/DefaultTemplate";
import ErrorMessages from "../../components/ErrorMessages/ErrorMessages";
import FormActionContainer from "../../components/Form/FormActionContainer";
import FormTextField from "../../components/Form/FormTextField";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import React from "react";
import TaskHeader from "../../components/Task/TaskHeader";
import Typography from "@mui/material/Typography";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { useTheme } from "@mui/material";
import withProfileAndAccess from "../../utils/withProfileAndAccess";
import {
	Form,
	format,
	isNumber,
	isObject,
	isStringOfLength
} from "@pheaa/channels-component-library";
import {
	HTML_ATTR_PATTERN_SSN,
	TEXT_DATA_UNAVAILABLE,
	getCommonProfileDataListStyles,
	getErrorMessageForCode
} from "../../config/common";

const ManageSSNIdentifyView = props => {

	let forceDisabled = false;
	let ssnElem = null;

	const {
		accountProfile,
		accountProfileErrorsConflict,
		accountProfileErrorsValidPersonIdentifier,
		accountProfileIsCheckingConflict,
		accountProfileIsValidatingPersonIdentifier,
		cleanUpSSNChange,
		setData,
		verifySSNChange
	} = props;

	const { personRefId } = useParams();
	const theme = useTheme();

	const handleInputChange = e => {
		const { value } = e.target;
		setData({ accountProfileNewSSN: value });
	};

	const handleSubmit = (e, formData) => {
		verifySSNChange(formData.ssn);
	};

	if (isObject(accountProfile) && (isStringOfLength(accountProfile.ssn) || isNumber(accountProfile.ssn))) {
		ssnElem = format.asSocial(accountProfile.ssn);
	} else {
		forceDisabled = true;
		ssnElem = TEXT_DATA_UNAVAILABLE;
	}

	return (
		<DefaultTemplate isTask={true}>
			<TaskHeader
				closeCallback={() => { cleanUpSSNChange(); }}
				closeTaskDestination={`/profiles/${personRefId}`}
				title="Edit Social Security Number"
			/>
			<Form id="defaultForm" onSubmit={handleSubmit}>
				<Typography align="center" gutterBottom={true}>
					Provide the new Social Security Number to review available change options.
				</Typography>
				<ErrorMessages
					errorMessages={[...accountProfileErrorsConflict, ...accountProfileErrorsValidPersonIdentifier].map(error => {
						return { ...error, message: getErrorMessageForCode(error.code) };
					})}
				/>
				<List sx={getCommonProfileDataListStyles(theme)}>
					<ListItem>
						<ListItemText primary="Current Social Security Number:" />
						<ListItemText primary={ssnElem} />
					</ListItem>
				</List>
				<FormTextField
					name="ssn"
					onChange={handleInputChange}
					pattern={HTML_ATTR_PATTERN_SSN}
					required={true}
					title="New Social Security Number"
				/>
				<FormActionContainer>
					<Button
						disabled={forceDisabled || accountProfileIsCheckingConflict || accountProfileIsValidatingPersonIdentifier}
						processing={accountProfileIsCheckingConflict || accountProfileIsValidatingPersonIdentifier}
						type="submit"
						variant="contained"
					>
						Continue
					</Button>
				</FormActionContainer>
			</Form>
		</DefaultTemplate >
	);
};

const mapStateToProps = state => {
	return {
		accountProfile: state.profile.accountProfile,
		accountProfileErrorsConflict: state.profile.accountProfileErrorsConflict,
		accountProfileErrorsValidPersonIdentifier: state.profile.accountProfileErrorsValidPersonIdentifier,
		accountProfileIsCheckingConflict: state.profile.accountProfileIsCheckingConflict,
		accountProfileIsValidatingPersonIdentifier: state.profile.accountProfileIsValidatingPersonIdentifier
	};
};

const mapDispatchToProps = dispatch => {
	return {
		cleanUpSSNChange: () => dispatch(profileEvents.cleanUpSSNChange()),
		setData: data => dispatch(profileActions.setData(data)),
		verifySSNChange: ssn => dispatch(profileEvents.verifySSNChange(ssn))
	};
};

export default withProfileAndAccess(connect(mapStateToProps, mapDispatchToProps)(ManageSSNIdentifyView), ["updateSocialSecurityNumber"]);