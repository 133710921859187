import * as profileActions from "../../store/actions/profile";
import MuiAlert from "@mui/material/Alert";
import React from "react";
import Snackbar from "@mui/material/Snackbar";
import { connect } from "react-redux";
import { isObject } from "@pheaa/channels-component-library";

const Alert = React.forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const UpdateConfirmationSnackbar = props => {

	const {
		confirmationEvent,
		setData
	} = props;

	const [open, setOpen] = React.useState(false);
	const [message, setMessage] = React.useState("");
	const [severity, setSeverity] = React.useState("success");

	React.useEffect(() => {
		if (isObject(confirmationEvent)) {
			setMessage(confirmationEvent.message);
			setSeverity(confirmationEvent.severity || "success");
			setOpen(true);
		} else {
			setMessage("");
		}
	}, [confirmationEvent]);

	const handleClose = (e, reason) => {
		if (reason === "clickaway") {
			return;
		}

		setOpen(false);
		setTimeout(() => { setData({ confirmationEvent: null }); }, 500);
	};

	return (
		<div>
			<Snackbar
				anchorOrigin={{ horizontal: "center", vertical: "top" }}
				autoHideDuration={6000}
				onClose={handleClose}
				open={open}
				sx={{ top: 10 }}
			>
				<Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
					{message}
				</Alert>
			</Snackbar>
		</div>
	);
};

const mapStateToProps = state => {
	return {
		confirmationEvent: state.profile.confirmationEvent
	};
};

const mapDispatchToProps = dispatch => {
	return {
		setData: data => dispatch(profileActions.setData(data))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateConfirmationSnackbar);