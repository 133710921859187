import Box from "@mui/material/Box";
import Logo from "./Logo.js";
import NotificationPopover from "./NotificationPopover.js";
import ProfilePopover from "./ProfilePopover.js";
import React from "react";
import { connect } from "react-redux";
import { useTheme } from "@mui/material";
import { format, isObject } from "@pheaa/channels-component-library";

const Header = props => {

	let formattedName = null;

	const {
		name
	} = props;

	const theme = useTheme();
	const backgroundColor = (theme.palette.header.light);

	if (isObject(name)) {
		formattedName = format.asProperName(name);
	}

	return (
		<Box
			className="cmp-header"
			component="header"
			id="header"
			sx={{
				backgroundColor,
				boxShadow: 3
			}}
		>
			<Box className="cmp-header__inner">
				<Logo />
				<h1 className="cmp-header__heading">Consumer Profile Maintenance</h1>
				<Box
					sx={{
						alignItems: "center",
						color: theme.palette.primary.contrastText,
						display: "flex",
						flex: "1 0 auto",
						fontSize: 16,
						fontWeight: theme.typography.fontWeightBold,
						justifyContent: "flex-end",
						paddingRight: 3
					}}
				>
					<Box sx={{ paddingRight: 1 }}>{formattedName}</Box>
					<Box>
						<ProfilePopover />
					</Box>
					<Box>
						<NotificationPopover />
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

const mapStateToProps = state => {
	return {
		name: state.auth.name
	};
};

export default connect(mapStateToProps)(Header);