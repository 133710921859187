import { isArrayOfLength } from "@pheaa/channels-component-library";

const defaultPrivilegeMap = {
	deleteRecoveryMethod: false,
	deleteTemporaryLock: false,
	deleteWebAccount: false,
	readApplication: false,
	readAllFields: false,
	updateAccountRecovery: false,
	updateContactEmail: false,
	updateDateOfBirth: false,
	updateLegalName: false,
	updatePaymentBlock: false,
	updateSocialSecurityNumber: false,
	updateUsername: false,
	updateWebAccessBlock: false
};

export const createPrivilegesMap = (privilegesArray, roles) => {

	try {
		let updatedPrivilegeMap = { ...defaultPrivilegeMap };
		// If user has any length to roles array, they have read access to application;
		updatedPrivilegeMap.readApplication = isArrayOfLength(roles);
		// If user has any length to privileges array, determine which privileges are allowed;
		isArrayOfLength(privilegesArray) && privilegesArray.forEach(privilege => {
			switch (privilege.resource) {
				case "/persons/{personRefId}/blocks/account":
					updatedPrivilegeMap.updateWebAccessBlock = privilege.methods.includes("put");
					break;
				case "/persons/{personRefId}/blocks/payment":
					updatedPrivilegeMap.updatePaymentBlock = privilege.methods.includes("put");
					break;
				case "/persons/{personRefId}/identifiers":
					updatedPrivilegeMap.updateSocialSecurityNumber = privilege.methods.includes("patch");
					break;
				case "/persons/{personRefId}/profile":
					updatedPrivilegeMap.readAllFields = privilege.methods.includes("get");
					updatedPrivilegeMap.deleteWebAccount = privilege.methods.includes("delete");
					updatedPrivilegeMap.updateLegalName = privilege.methods.includes("patch");
					updatedPrivilegeMap.updateDateOfBirth = privilege.methods.includes("patch");
					updatedPrivilegeMap.updateUsername = privilege.methods.includes("patch");
					break;
				case "/persons/{personRefId}/profile/emails/contact":
					updatedPrivilegeMap.updateContactEmail = privilege.methods.includes("patch");
					break;
				case "/persons/{personRefId}/profile/notification":
					updatedPrivilegeMap.updateAccountRecovery = privilege.methods.includes("post");
					break;
				case "/persons/{personRefId}/security/lock":
					updatedPrivilegeMap.deleteTemporaryLock = privilege.methods.includes("delete");
					break;
				case "/persons/{personRefId}/security/contacts/{contactId}":
					updatedPrivilegeMap.deleteRecoveryMethod = privilege.methods.includes("delete");
					break;
				default:
					break;
			}
		});

		return updatedPrivilegeMap;
	} catch (e) {
		console.error("Error creating permissions map.", e);
		return defaultPrivilegeMap;
	}
};

export { defaultPrivilegeMap };