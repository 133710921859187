import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import React from "react";

const logoStyles = {
	backgroundImage: `url('${process.env.PUBLIC_URL}/assets/logos/main.svg')`,
	backgroundRepeat: "no-repeat",
	a: {
		backgroundImage: `url('${process.env.PUBLIC_URL}/assets/logos/arrows.svg')`
	}
};

const Logo = props => {
	return (
		<Box className="cmp-logo" sx={{ ...logoStyles }}>
			<Link to="/">
				<span>Search for a Consumer Account</span>
			</Link>
		</Box>
	);
};

export default Logo;