import { setData as setDataCommon } from "../store/actions/common";
import store from "../store";
import { verifyAccess } from "../store/events/auth";
import { CONFIG_FILENAME, currentDate } from "../config/common";
import { isArrayOfLength, isDate, isObject } from "@pheaa/channels-component-library";

const getConfig = () => {
	return fetch(`${process.env.PUBLIC_URL}/${CONFIG_FILENAME}`);
};

const initializeApplication = async () => {
	const initializationPromises = [];

	initializationPromises.push(getConfig());
	initializationPromises.push(store.dispatch(verifyAccess()));

	if (isArrayOfLength(initializationPromises)) {
		return Promise.all(initializationPromises).then(async ([configResponse]) => {
			let commonData = { isAppInitialized: true };

			try {
				const body = await configResponse.json();
				const configResponseDate = new Date(configResponse.headers.get("date"));
				isDate(configResponseDate) && currentDate.setCurrentDate(configResponseDate);
				isObject(body) && (commonData.appConfig = body);
			} catch (e) {
				console.warn(`Error parsing application configuration from ${CONFIG_FILENAME}.`, e);
			}

			store.dispatch(setDataCommon(commonData));
		});
	}

	return Promise.resolve();
};

export default initializeApplication;