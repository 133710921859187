import IconButton from "@mui/material/IconButton";
import Popover from "@mui/material/Popover";
import React from "react";
import SvgIcon from "@mui/material/SvgIcon";
import Typography from "@mui/material/Typography";

const NotificationIcon = props => {
	return (
		<SvgIcon {...props} height="26px" viewBox="0 0 26 28" width="28px">
			<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g transform="translate(0.000000, 0.141667)">
					<circle fill="#6E7278" cx="13" cy="13.8583333" r="13"></circle>
					<path d="M19,15.8583333 C19.7145312,15.8583333 20.3747852,15.4771355 20.7320508,14.8583333 C21.0893164,14.2395312 21.0893164,13.4771355 20.7320508,12.8583333 C20.3747852,12.2395312 19.7145312,11.8583333 19,11.8583333 L19,15.8583333 Z" fill="#434D5F" fillRule="nonzero"></path>
					<path d="M11.7498812,22.8583333 C11.7180981,22.8583333 11.6866115,22.8509991 11.6571352,22.8367333 C9,21.5614333 9,19.0165333 9,17.1583333 C9,16.9926479 9.11192398,16.8583333 9.2499892,16.8583333 C9.38805442,16.8583333 9.4999784,16.9926479 9.4999784,17.1583333 C9.4999784,18.9391333 9.4999784,21.1552333 11.8431272,22.2799333 C11.9542981,22.333496 12.0181788,22.4741716 11.9954244,22.6153162 C11.9726699,22.7564607 11.8696774,22.8583937 11.7498812,22.8583333 Z" fill="#DBDBE9" fillRule="nonzero"></path>
					<path d="M5.66666667,10.8583333 C4.74619208,10.8583333 4,11.6642208 4,12.6583333 L4,15.0583333 C4,16.0524459 4.74619208,16.8583333 5.66666667,16.8583333 L9,16.8583333 L9,10.8583333 L5.66666667,10.8583333 Z" fill="#434D5F" fillRule="nonzero"></path>
					<path d="M10.8571429,10.8583333 L9.14285714,10.8583333 L9.14285714,16.8583333 L10.8571429,16.8583333 C13.9467119,16.8675706 16.9516378,17.9192946 19.4285714,19.8583333 L19.4285714,7.85833333 C16.9128956,9.71815673 13.9324398,10.7613163 10.8571429,10.8583333 L10.8571429,10.8583333 Z" fill="#FFFFFF" fillRule="nonzero"></path>
					<path d="M4,13.8583333 L4,15.0583333 C4,16.0524459 4.76111593,16.8583333 5.7,16.8583333 L10.8,16.8583333 C13.8638226,16.8675706 16.8437075,17.9192946 19.3,19.8583333 L19.3,15.6583333 C20.2388841,15.6583333 21,14.8524459 21,13.8583333 L4,13.8583333 Z" fill="#000064" fillRule="nonzero" opacity="0.14"></path>
				</g>
			</g>
		</SvgIcon>
	);
};

const NotificationPopover = props => {
	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleClick = (event) => {
		// setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const id = open ? "simple-popover" : undefined;

	return (
		<div>
			<IconButton
				aria-describedby={id}
				onClick={handleClick}
			>
				<NotificationIcon />
			</IconButton>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
			>
				<Typography sx={{ p: 2 }}>Notifications</Typography>
			</Popover>
		</div>
	);
};

export default NotificationPopover;