import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import React from "react";
import Typography from "@mui/material/Typography";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import {
	isFunction,
	isStringOfLength
} from "@pheaa/channels-component-library";

const TaskHeader = props => {

	let closeElem = null;

	const {
		className,
		closeCallback,
		closeTaskDestination,
		closeTaskDisabled = false,
		closeTaskLabel = "Close",
		title = "Task"
	} = props;

	const isClose = (isStringOfLength(closeTaskDestination) || isFunction(closeCallback)) && isStringOfLength(closeTaskLabel);
	const navigate = useNavigate();

	const cmpClassName = classNames({
		"cmp-task-header": true,
		[className]: isStringOfLength(className)
	});

	const handleClose = e => {
		isFunction(closeCallback) && closeCallback();

		if (isStringOfLength(closeTaskDestination)) {
			navigate(closeTaskDestination);
		}
	};

	if (isClose) {
		closeElem = (
			<IconButton
				className="cmp-task-header__close"
				color="primary"
				disabled={closeTaskDisabled}
				onClick={handleClose}
				sx={{
					justifySelf: "end",
					padding: 0
				}}
			>
				<CloseIcon fontSize="large" />
				<span className="cmp-task-header__close-label">{closeTaskLabel}</span>
			</IconButton>
		);
	}

	return (
		<div className={cmpClassName} id="taskHeader">
			<Typography
				className="cmp-task-header__label"
				component="h1"
				my={2.25}
				variant="h2"
			>
				{title}
			</Typography>
			{closeElem}
		</div>
	);
};

export default TaskHeader;