import * as profileEvents from "../../store/events/profile";
import Button from "../../components/Button/Button";
import DefaultTemplate from "../templates/DefaultTemplate";
import ErrorMessages from "../../components/ErrorMessages/ErrorMessages";
import FormActionContainer from "../../components/Form/FormActionContainer";
import React from "react";
import TaskHeader from "../../components/Task/TaskHeader";
import Typography from "@mui/material/Typography";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import withProfileAndAccess from "../../utils/withProfileAndAccess";
import {
	Form,
	format,
	isObject,
	isStringOfLength
} from "@pheaa/channels-component-library";
import {
	TEXT_DATA_UNAVAILABLE,
	getErrorMessageForCode
} from "../../config/common";

const RemoveTemporaryLockView = props => {

	let formattedName = null;
	let lockSummaryElem = null;

	const {
		accountProfile,
		accountProfileErrorsDeleteTempLock,
		accountProfileIsDeletingTempLock,
		deleteTemporaryLock
	} = props;

	const { personRefId } = useParams();

	const handleSubmit = (e, formData) => {
		deleteTemporaryLock(personRefId);
	};

	if (isObject(accountProfile)) {

		if (isObject(accountProfile.name)) {
			formattedName = format.asProperName(accountProfile.name);
			if (isStringOfLength(formattedName)) {
				formattedName = (
					<React.Fragment>
						&nbsp;for <b>{formattedName}</b>
					</React.Fragment>
				);
			}
		}

		if (isObject(accountProfile.lock) && accountProfile.lock.has) {
			let durationSummary = format.asHourMinSec(parseInt(accountProfile.lock.duration, 10) * 60, {
				hourAbbr: "hour",
				minuteAbbr: "minute",
				secondAbbr: "second",
				leadingZeroes: false,
				requireMinutes: true,
				requireSeconds: false,
				separator: ":"
			}).value;

			let expirationSummary = format.asFormalDate(accountProfile.lock.expiresOn);

			lockSummaryElem = `${durationSummary} lock - Expires: ${expirationSummary}`;
		} else {
			lockSummaryElem = `Temporary Lock Summary: ${TEXT_DATA_UNAVAILABLE}`;
		}
	}

	return (
		<DefaultTemplate isTask={true}>
			<TaskHeader
				closeTaskDestination={`/profiles/${personRefId}`}
				title="Remove Temporary Lock"
			/>
			<Form id="defaultForm" onSubmit={handleSubmit}>
				<Typography align="center" gutterBottom={true}>
					Removing Temporary Lock will allow user to create an account, access an account, or take any online action.
				</Typography>
				<Typography align="center" gutterBottom={true}>
					<b>{lockSummaryElem}</b>
				</Typography>
				<ErrorMessages
					errorMessages={accountProfileErrorsDeleteTempLock.map(error => {
						return { ...error, message: getErrorMessageForCode(error.code) };
					})}
				/>
				<Typography align="center">
					Are you sure you want to <b>Remove Temporary Lock</b>{formattedName}?
				</Typography>
				<FormActionContainer>
					<Button
						disabled={accountProfileIsDeletingTempLock}
						processing={accountProfileIsDeletingTempLock}
						type="submit"
						variant="contained"
					>
						Submit
					</Button>
				</FormActionContainer>
			</Form>
		</DefaultTemplate >
	);
};

const mapStateToProps = state => {
	return {
		accountProfile: state.profile.accountProfile,
		accountProfileErrorsDeleteTempLock: state.profile.accountProfileErrorsDeleteTempLock,
		accountProfileIsDeletingTempLock: state.profile.accountProfileIsDeletingTempLock
	};
};

const mapDispatchToProps = dispatch => {
	return {
		deleteTemporaryLock: personRefId => dispatch(profileEvents.deleteTemporaryLock(personRefId))
	};
};

export default withProfileAndAccess(connect(mapStateToProps, mapDispatchToProps)(RemoveTemporaryLockView), ["deleteTemporaryLock"]);