import { createReducer } from "@reduxjs/toolkit";
import merge from "deepmerge";
import { currentClient, currentClientId, currentClientName } from "../../config/clients";
import { resetData, setData } from "../actions/common";

export const arrayMergeOverwrite = (destinationArray, sourceArray, options) => sourceArray;

const initialState = {
	appConfig: {},
	client: currentClient,
	clientId: currentClientId,
	clientName: currentClientName,
	isAppInitialized: false,
	isAppLoading: true,
	targetPathname: null
};

const commonReducer = createReducer(initialState, (builder) => {
	builder
		.addCase(resetData, (state, action) => {
			return { ...initialState, ...action.payload, returnKey: state.returnKey };
		})
		.addCase(setData, (state, action) => {
			return merge(state, action.payload, { arrayMerge: arrayMergeOverwrite });
		});
});

export default commonReducer;